import { Typography } from 'components/common/Typography/Typography';
import { Avatar } from 'components/general/Avatar/Avatar';
import { ChatUsersListObject } from 'types/socket/types';

type ChatHeaderTypes = {
  userData: ChatUsersListObject;
};

export function ChatHeader({ userData }: ChatHeaderTypes) {
  return (
    <div className="border-b border-black p-3">
      <div className="flex items-center">
        <Avatar
          imgUrl={userData.profilePicture || ''}
          userName={userData.patientName || ''}
          sizeClass="w-10 h-10 mr-3"
        />
        <Typography variant="subtitle1">{userData.patientName}</Typography>
      </div>
    </div>
  );
}
