import { useContext, useEffect, useState } from 'react';

import { EPrescribeUtilContext } from 'components/patient/e-prescribeV2/HormoneListing';
import { femaleHormoneRxTypes } from 'constants/hormone-details-new';
import { useDTD } from 'hooks/hormones/useDTD';
import { useSIG } from 'hooks/hormones/useSIG';
import { CurrentDoseType } from 'types/patient/prescription';

import { useMaximumDoseHandler } from '../useMaximumDoseHandler';
import { useSigGenerator } from '../useSigGenerator';

export const useTestoDHEAController = () => {
  const TestoDHEADetails = femaleHormoneRxTypes?.['testosterone/dhea'];
  const [strength, setStrength] = useState('8/160');

  const [dose, setDose] = useState(0);
  const [refills, setRefills] = useState(0);
  const { dtd, setDTD, DTDValues } = useDTD(18);
  const { sig, setSig } = useSIG('');
  const [doseValues, setDoseValues] = useState([...TestoDHEADetails.dose]);
  const [note, setNote] = useState('');
  const [currentDose, setCurrentDose] = useState<CurrentDoseType>();
  const { renderModal } = useContext(EPrescribeUtilContext);

  // the maximum daily dose slider should automatically be 0.2 higher than the dose slider
  const marginValue = 4;
  const {
    maximumDose,
    setMaximumDose,
    maxDoseOptions,
    setMaxDosePreValue,
    maxDosePreValue,
  } = useMaximumDoseHandler({
    dose,
    doses: TestoDHEADetails.dose,
    incrementalValue: marginValue,
  });

  const { generatedSIG, setGeneratedSIG } = useSigGenerator({
    dose,
    maximumDose,
    doseOptions: TestoDHEADetails?.dose,
    marginValue,
  });

  const strengthOption = ['8/160', '24/160'];

  const notIncludedDoseInLowerStrength = [0.1, 0.15];

  useEffect(() => {
    if (doseValues[dose + maximumDose] > 0.65) {
      setDTD(60);

      return;
    }
    if (doseValues[dose + maximumDose] > 0.3) {
      setDTD(30);
    } else {
      setDTD(18);
    }
  }, [maximumDose, dose, doseValues, setDTD]);

  return {
    TestoDHEADetails,

    sig,
    setSig,
    setDTD,
    DTDValues,

    dtd,
    strength,
    setStrength,
    maximumDose,
    setMaximumDose,
    generatedSIG,
    setGeneratedSIG,

    dose,
    setDose,
    refills,
    setRefills,
    doseValues,

    note,
    setNote,
    setDoseValues,
    notIncludedDoseInLowerStrength,
    currentDose,
    setCurrentDose,
    renderModal,
    strengthOption,
    marginValue,
    maxDoseOptions,
    setMaxDosePreValue,
    maxDosePreValue,
  };
};
