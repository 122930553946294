import React, { useEffect, useMemo, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { useOutletContext } from 'react-router-dom';

import { useTestoDHEAController } from 'hooks/e-prescribe/hormone-v2-hooks/useTestoDHEAControllerController';
import { useCalculateDifference } from 'hooks/e-prescribe/useCalculateDifference';
import { useCheckPrescription } from 'hooks/e-prescribe/useCheckPrescription';
import { useCurrentActivePrescription } from 'hooks/e-prescribe/useCurrentPrescription';
import { useHormoneStandardizedName } from 'hooks/e-prescribe/useHormoneStandardizedName';
import { useMedicationCompliance } from 'hooks/e-prescribe/useMedicationCompliance';
import { PatientPrescriptionService } from 'services/prescription';
import {
  HormoneSelectionProps,
  PatientDetailsContextType,
} from 'types/patient';
import {
  HormoneNames,
  HormoneValidations,
  LetterOfExplanations,
  PrescribedObjectType,
  PrescriptionCheckValuesForReview,
  PrescriptionDifferenceType,
  PrescriptionDisplayTypes,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';
import { capitalizedString } from 'utils/common';

import { colorObjForCheckPrescription, colorObjForSupply } from '../common';
import { HormoneDetailsUIV2 } from '../HormoneDetailsUIV2';

export function TestoDHEA({
  addNewPrescription,
  RxDetails,
  onBack,
}: HormoneSelectionProps) {
  const { hasDeaForShippingState } =
    useOutletContext<PatientDetailsContextType>();
  const [loe, setLoe] = useState<LetterOfExplanations>();
  const [loeDetails, setloeDetails] = useState({
    loeRequired: false,
    agreedToSelectedLoe: false,
  });

  const {
    TestoDHEADetails,

    dtd,
    dose,
    setDose,
    refills,
    setRefills,
    strength,
    setStrength,
    doseValues,
    setDTD,

    currentDose,
    setCurrentDose,

    strengthOption,
    generatedSIG,
    maximumDose,
    setMaximumDose,
    setGeneratedSIG,
    renderModal,
    marginValue,
    maxDoseOptions,
    setMaxDosePreValue,
    maxDosePreValue,
  } = useTestoDHEAController();

  const { checkMedicationComplianceQuery } = useMedicationCompliance({
    medicationId: RxDetails?.id ?? '',
    onSuccess(c) {
      setloeDetails({
        agreedToSelectedLoe: false,
        loeRequired: !!c.allowWithLoe,
      });
    },
  });

  const getCurrentPrescriptionCheckMutation = useMutation(
    (id: string) => PatientPrescriptionService.getPrescriptionCheckValue(id),
    {
      onSuccess() {
        // console.log(data, '******');
      },
    }
  );

  const {
    currentActivePrescriptions,
    isLoading: currentPrescriptionFetchLoading,
  } = useCurrentActivePrescription({
    medicationId: RxDetails?.id ?? '',
    onSuccess(prescription) {
      getCurrentPrescriptionCheckMutation.mutateAsync(prescription?.id ?? '');
    },
  });

  const { calculatePercentageDifference, getDifferenceUI } =
    useCalculateDifference({
      hormoneName: HormoneNames.TESTO_PER_DHEA,
    });

  const prescriptionObject: PrescribedObjectType = useMemo(
    () => ({
      id: RxDetails?.id,
      displayName: TestoDHEADetails.displayName,
      name: HormoneNames.TESTO_PER_DHEA,
      strength: `${strength}`,
      strengthUnit: TestoDHEADetails.strengthUnit,

      ratioUnit: TestoDHEADetails.ratioUnit,
      dose: doseValues[maximumDose + dose + marginValue],
      minimumDose: doseValues[dose],
      doseUnit: TestoDHEADetails.doseUnit,
      refills,
      dtd,
      sig: generatedSIG,
      deliveryMethod: TestoDHEADetails.deliveryMethod,
      family: TestoDHEADetails.family,
      coveredUnderSubscription:
        RxDetails?.coveredUnderSubscription?.toString() ?? 'false',
      dtdUnit: TestoDHEADetails.dtdUnit,
      currentDose,
    }),
    [
      TestoDHEADetails,
      RxDetails,
      dose,
      dtd,
      generatedSIG,
      maximumDose,
      refills,
      strength,
      currentDose,
      doseValues,
      marginValue,
    ]
  );

  const { checkPrescriptionValueQuery: maxDosePrescriptionValues } =
    useCheckPrescription({
      prescription: {
        ...prescriptionObject,
        dose: doseValues[dose + maximumDose + marginValue],
      },
      onSuccess() {
        //
      },
    });

  const { checkPrescriptionValueQuery: minDosePrescriptionValues } =
    useCheckPrescription({
      prescription: {
        ...prescriptionObject,
        dose: doseValues[dose],
      },
      onSuccess: () => {
        //
      },
    });

  const { getStandardizedNameQuery } = useHormoneStandardizedName({
    prescription: { ...prescriptionObject, dose: TestoDHEADetails.dose[dose] },
  });

  const prescriptionCheckValues: PrescriptionCheckValuesForReview = useMemo(
    () => ({
      checkValues: {
        withMaxDose: maxDosePrescriptionValues.data?.data.value,
        withMinDose: minDosePrescriptionValues.data?.data.value,
        unit: maxDosePrescriptionValues.data?.data.unit ?? '',
      },
      daysOfSupply: {
        withMaxDose:
          maxDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
        withMinDose:
          minDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
      },
      isHighPotencyRx: false,
      standardizedName:
        getStandardizedNameQuery.data?.data.standardRepresentation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      maxDosePrescriptionValues,
      minDosePrescriptionValues,
      getStandardizedNameQuery.data?.data.standardRepresentation,
    ]
  );

  function getComparisonValueForMinimumDose() {
    if (
      currentActivePrescriptions?.id &&
      !!getCurrentPrescriptionCheckMutation.data?.data?.minDoseQuality?.value
    ) {
      const comparableValue =
        getCurrentPrescriptionCheckMutation.data?.data?.currentDoseQuality
          ?.value ??
        getCurrentPrescriptionCheckMutation.data?.data?.minDoseQuality?.value;

      return getDifferenceUI(
        calculatePercentageDifference(
          comparableValue,
          minDosePrescriptionValues.data?.data.value
        ),
        `${comparableValue} ${getCurrentPrescriptionCheckMutation.data?.data?.unit}`
      );
    }

    return '';
  }

  const maxDoseDiff = useMemo(
    () =>
      currentActivePrescriptions?.id &&
      !!getCurrentPrescriptionCheckMutation.data?.data?.value
        ? calculatePercentageDifference(
            getCurrentPrescriptionCheckMutation.data?.data?.value,
            maxDosePrescriptionValues.data?.data.value
          )
        : 0,
    [
      getCurrentPrescriptionCheckMutation.data?.data,
      maxDosePrescriptionValues.data?.data,
      calculatePercentageDifference,
      currentActivePrescriptions?.id,
    ]
  );

  const prescriptionDifferenceValues: PrescriptionDifferenceType = useMemo(
    () => ({
      maxDoseDiffValue: maxDoseDiff,
    }),
    [maxDoseDiff]
  );

  // useEffect(() => {
  //   if (strength === '8/160') {
  //     // eslint-disable-next-line

  //     if (doseValues.includes(0.1)) {
  //       setDoseValues((pre) => {
  //         if (pre.includes(0.1)) {
  //           return pre.slice(2, doseValues.length);
  //         }

  //         return pre;
  //       });
  //       setDose(0);
  //     }
  //   } else {
  //     setDoseValues((pre) => {
  //       if (pre.includes(0.1)) {
  //         return pre;
  //       }

  //       pre.splice(0, 0, ...notIncludedDoseInLowerStrength);

  //       return pre;
  //     });
  //     setDose(2);
  //   }

  //   // eslint-disable-next-line
  // }, [strength]);

  useEffect(() => {
    if (doseValues[dose + maximumDose + marginValue] > 0.65) {
      setDTD(60);

      return;
    }
    if (doseValues[dose + maximumDose + marginValue] > 0.3) {
      setDTD(30);
    } else {
      setDTD(18);
    }
  }, [maximumDose, dose, doseValues, setDTD, marginValue]);

  useEffect(() => {
    if (currentActivePrescriptions?.id) {
      getCurrentPrescriptionCheckMutation.mutateAsync(
        currentActivePrescriptions?.id ?? ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePrescriptions?.id]);

  useEffect(() => {
    if (
      currentActivePrescriptions?.id &&
      !currentActivePrescriptions?.titrationType
    ) {
      if (
        Number(
          currentActivePrescriptions?.deliveryMethodAndSig?.minDailyDose?.value
        ) <= 0.8
      ) {
        setDose(
          TestoDHEADetails.dose.findIndex(
            (d: number) =>
              d ===
              Number(
                currentActivePrescriptions?.deliveryMethodAndSig?.minDailyDose
                  ?.value
              )
          )
        );
      }

      setMaxDosePreValue(
        Number(currentActivePrescriptions?.deliveryMethodAndSig.dose.value)
      );
      setStrength(currentActivePrescriptions?.strength?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePrescriptions]);

  useEffect(() => {
    if (
      !currentActivePrescriptions?.id &&
      !!currentActivePrescriptions?.titrationType
    ) {
      return;
    }

    if (
      Number(
        currentActivePrescriptions?.deliveryMethodAndSig?.minDailyDose?.value
      ) !==
        Number(currentActivePrescriptions?.deliveryMethodAndSig.dose.value) &&
      maxDosePreValue ===
        Number(currentActivePrescriptions?.deliveryMethodAndSig.dose.value)
    ) {
      const tempMaxDoseIndex = maxDoseOptions.findIndex(
        (d: number) =>
          d ===
          Number(currentActivePrescriptions?.deliveryMethodAndSig.dose.value)
      );
      setMaximumDose(tempMaxDoseIndex === 0 ? 0 : tempMaxDoseIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePrescriptions, maxDosePreValue]);

  return (
    <HormoneDetailsUIV2
      onAdd={() =>
        addNewPrescription({
          ...prescriptionObject,
          ...prescriptionCheckValues,
          loe,
        })
      }
      prescriptionObject={{
        ...prescriptionObject,
        ...prescriptionCheckValues,
        loe,
        ...prescriptionDifferenceValues,
      }}
      validations={{
        [HormoneValidations.Loading]: {
          isFailed:
            maxDosePrescriptionValues.isLoading ||
            minDosePrescriptionValues.isLoading ||
            getStandardizedNameQuery.isLoading ||
            checkMedicationComplianceQuery.isLoading,
        },
        [HormoneValidations.MissingDEA]: {
          isFailed:
            !hasDeaForShippingState && !!RxDetails?.isControlledSubstance,
        },
        [HormoneValidations.DaysOfSupply]: {
          isFailed: !maxDosePrescriptionValues.data?.data.validDaysOfSupply,
        },
        [HormoneValidations.SIGExceed140Chars]: {
          isFailed: Number(generatedSIG.length) > 140,
        },
        [HormoneValidations.LOENotSelected]: {
          isFailed:
            (!!checkMedicationComplianceQuery?.data?.data?.allowWithLoe &&
              !loe) ||
            (!!checkMedicationComplianceQuery?.data?.data?.allowWithLoe &&
              !loeDetails.agreedToSelectedLoe),
        },
        [HormoneValidations.EarlyRefillAlreadyOrdered]: {
          isFailed:
            !!checkMedicationComplianceQuery?.data?.data?.isAlreadyOrdered,
        },
      }}
      onBack={onBack}
      title={capitalizedString(RxDetails?.name)}
      hormoneInputs={[
        {
          containerClassName: 'flex flex-col gap-2',
          children: [
            {
              type: PrescriptionDisplayTypes.WarningMessage,
              hide: !checkMedicationComplianceQuery?.data?.data
                ?.isAlreadyOrdered,
              text: 'You have already ordered this prescription.',
              className: 'mb-4',
            },

            {
              type: PrescriptionInputTypes.LOESelector,
              wrapperClassName: 'col-span-9',
              hide: checkMedicationComplianceQuery.isLoading
                ? false
                : !checkMedicationComplianceQuery?.data?.data?.allowWithLoe,
              onCheck: (v) => {
                setloeDetails({
                  ...loeDetails,
                  agreedToSelectedLoe: v,
                });
              },
              onLOEselect(val) {
                setLoe(val);
              },
              value: loe,
              loading:
                !!checkMedicationComplianceQuery.isLoading ||
                !!checkMedicationComplianceQuery.isRefetching,
            },
          ],
        },
        {
          containerClassName: classNames(' flex justify-between', {
            'mt-6': !!checkMedicationComplianceQuery?.data?.data?.allowWithLoe,
          }),
          title: '',
          children: [
            {
              type: PrescriptionInputTypes.SelectorCheckbox,
              options: strengthOption,
              onChange(s) {
                setStrength(s as string);
                setDose(0);
                setMaximumDose(0);
                renderModal?.({
                  oldValue: strength.toString(),
                  newValue: s.toString(),
                  checkPrescriptionUnit: 'mg/day',
                  changeValueUnit: TestoDHEADetails.strengthUnit,
                });
              },
              label: 'Strength',
              value: strength,
              unit: TestoDHEADetails.strengthUnit,
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: `${dtd.toString()} ml`,
              label: 'DTD',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: TestoDHEADetails.deliveryMethod,
              label: 'Delivery Method',
            },
            {
              label: 'Days of Supply',
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!maxDosePrescriptionValues.isLoading ||
                !!minDosePrescriptionValues.isLoading,
              value:
                maxDosePrescriptionValues.data?.data.daysOfSupply ===
                minDosePrescriptionValues.data?.data?.daysOfSupply
                  ? `${maxDosePrescriptionValues.data?.data.daysOfSupply} Days`
                  : `${maxDosePrescriptionValues.data?.data.daysOfSupply}-${minDosePrescriptionValues.data?.data?.daysOfSupply} Days`,

              colorClass: `${
                maxDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForSupply[
                      maxDosePrescriptionValues.data?.data.validDaysOfSupply.toString()
                    ]
                  : ''
              }`,
            },
          ],
        },

        {
          title: 'Dose',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-6 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${`${doseValues[val]} ${TestoDHEADetails.doseUnit}`}`,
              onChange: (d) => {
                setDose(d);
              },
              options: [
                ...doseValues.slice(
                  0,
                  TestoDHEADetails.dose.length - marginValue
                ),
              ],
              value: dose,
              wrapperClassName: 'col-span-9',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!minDosePrescriptionValues.isLoading ||
                getCurrentPrescriptionCheckMutation.isLoading ||
                currentPrescriptionFetchLoading,
              label: `Minimum ${minDosePrescriptionValues?.data?.data.unit}`,
              value: minDosePrescriptionValues?.data?.data.value
                ? `${minDosePrescriptionValues?.data?.data.value} ${minDosePrescriptionValues?.data?.data.unit}`
                : '',
              wrapperClassName: 'col-span-3 !h-[7rem] mt-6  ',
              colorClass: `${
                minDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForCheckPrescription[
                      minDosePrescriptionValues.data?.data.rxQuality
                    ]
                  : ''
              }`,
              helperText: getComparisonValueForMinimumDose(),
            },
            {
              type: PrescriptionInputTypes.DoseSplitter,
              onChange: (cd) => {
                setCurrentDose({
                  morning: {
                    value: cd.morning,
                    unit: TestoDHEADetails.doseUnit,
                  },
                  evening: {
                    value: cd.evening,
                    unit: TestoDHEADetails.doseUnit,
                  },
                });
              },
              hormoneName: HormoneNames.TESTO_PER_DHEA,
              value: TestoDHEADetails.dose[dose],
              wrapperClassName: 'col-span-9 pl-4 mt-16 ',
            },
          ],
        },
        {
          title: 'Maximum Dose',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-6 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${`${maxDoseOptions[val]?.toFixed(2)} ${
                  TestoDHEADetails.doseUnit
                }`}`,
              onChange: (d) => {
                setMaxDosePreValue(maxDoseOptions[d]);
                setMaximumDose(d);
              },
              options: maxDoseOptions,
              value: maximumDose,
              wrapperClassName: 'col-span-9',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!maxDosePrescriptionValues.isLoading ||
                getCurrentPrescriptionCheckMutation.isLoading ||
                currentPrescriptionFetchLoading,
              label: `Maximum ${maxDosePrescriptionValues?.data?.data.unit}`,
              value: maxDosePrescriptionValues?.data?.data.value
                ? `${maxDosePrescriptionValues?.data?.data.value} ${maxDosePrescriptionValues?.data?.data.unit}`
                : '',
              wrapperClassName: 'col-span-3 !h-[7rem] mt-6  ',
              colorClass: `${
                maxDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForCheckPrescription[
                      maxDosePrescriptionValues.data?.data.rxQuality
                    ]
                  : ''
              }`,
              helperText:
                currentActivePrescriptions?.id &&
                !!getCurrentPrescriptionCheckMutation.data?.data?.value
                  ? getDifferenceUI(
                      calculatePercentageDifference(
                        getCurrentPrescriptionCheckMutation.data?.data?.value,
                        maxDosePrescriptionValues.data?.data.value
                      ),
                      `${getCurrentPrescriptionCheckMutation.data?.data?.value} ${getCurrentPrescriptionCheckMutation.data?.data?.unit}`
                    )
                  : '',
            },
          ],
        },
        {
          title: 'Refills',
          containerClassName:
            'grid grid-cols-12 p-10 pb-16 pt-20 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) => `${TestoDHEADetails.refills[val]}  refills`,
              onChange: (d) => {
                setRefills(d);
              },
              options: TestoDHEADetails.refills,
              value: refills,
              wrapperClassName: 'col-span-9',
            },
          ],
        },
        {
          containerClassName: '',
          title: 'SIG',
          children: [
            {
              type: PrescriptionInputTypes.SIG,
              wrapperClassName: 'col-span-9',
              onChange: (s) => {
                setGeneratedSIG(s);
              },
              value: generatedSIG,
            },
          ],
        },
      ]}
    />
  );
}
