import React from 'react';

import { CheckBox } from 'components/common/CheckBox/CheckBox';
import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { LetterOfExplanations, LOESelectorType } from 'types/prescriptionTypes';

export function LOESelector({
  onCheck,
  onLOEselect,
  value,
  loading,
}: LOESelectorType) {
  return (
    <SkeletonContent
      layout={[
        {
          className: 'flex h-[16.3rem] bg-background-main  ',
        },
      ]}
      isLoading={loading}>
      {() => (
        <div className="flex   w-full flex-col gap-4 rounded bg-background-main p-6">
          <Typography variant="subtitle1">
            Please choose the most appropriate reason for requesting an early
            fill of this prescription:
          </Typography>
          <div className="mt-2 flex gap-10 pl-2 ">
            <RadioButton
              checked={value === LetterOfExplanations.IncreasedDosage}
              id={LetterOfExplanations.IncreasedDosage}
              label={LetterOfExplanations.IncreasedDosage}
              onChange={() => {
                onLOEselect(LetterOfExplanations.IncreasedDosage);
              }}
              className="w-60"
              square
            />{' '}
            <RadioButton
              checked={value === LetterOfExplanations.DamagedRx}
              id={LetterOfExplanations.DamagedRx}
              label={LetterOfExplanations.DamagedRx}
              onChange={() => {
                onLOEselect(LetterOfExplanations.DamagedRx);
              }}
              className="w-60"
              square
            />{' '}
          </div>
          <div className=" flex gap-10 pl-2 ">
            {' '}
            <RadioButton
              checked={value === LetterOfExplanations.VacationOverride}
              id={LetterOfExplanations.VacationOverride}
              label={LetterOfExplanations.VacationOverride}
              onChange={() => {
                onLOEselect(LetterOfExplanations.VacationOverride);
              }}
              className="w-60"
              square
            />{' '}
            <RadioButton
              checked={value === LetterOfExplanations.PatientDosingError}
              id={LetterOfExplanations.PatientDosingError}
              label={LetterOfExplanations.PatientDosingError}
              onChange={() => {
                onLOEselect(LetterOfExplanations.PatientDosingError);
              }}
              className="w-60"
              square
            />
          </div>
          <div className="mt-4 flex items-center gap-1  ">
            <CheckBox
              bgColor="accent-error-main"
              data-tooltip-id="loeAgreeCheckbox"
              id=""
              label=""
              onChange={(c) => {
                if (value) {
                  onCheck(c);
                }
              }}
              {...(!value && { checked: false })}
            />
            {!value && (
              <Tooltip theme="light" id="loeAgreeCheckbox">
                Please select LOE first
              </Tooltip>
            )}
            <Typography color="error" variant="subtitle1">
              I have reviewed and verified all the details for this refill, and
              they are accurate.
            </Typography>
          </div>
        </div>
      )}
    </SkeletonContent>
  );
}
