// eslint-disable-next-line import/extensions
import WhiteLogo from 'assets/images/White Logo.png';
import { Typography } from 'components/common/Typography/Typography';

export function NoUserSelectedChatScreen() {
  return (
    <div className="flex flex-col items-center justify-center">
      <img
        src={WhiteLogo}
        width={200}
        alt="Brite Logo"
        className="-mt-12 -mb-8"
      />

      <Typography variant="subtitle2">
        Please select the patient to correspond with.
      </Typography>
    </div>
  );
}
