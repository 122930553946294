import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { Typography } from 'components/common/Typography/Typography';
import { useDoseSplitter } from 'hooks/e-prescribe/useDoseSplitter';
import {
  DoseSplitterType,
  DoseSplittingMethods,
  HormoneNames,
} from 'types/prescriptionTypes';

export function DoseSplitter({
  value,
  hormoneName,
  onChange,
  loading,
  defaultValue,
}: DoseSplitterType) {
  const [splittedType, setSplittedType] = useState<DoseSplittingMethods>();
  const { splittedDose } = useDoseSplitter({
    dose: value,
    type: splittedType,
    hormoneName,
  });

  function setDefaultSplittedType() {
    switch (hormoneName) {
      case HormoneNames.BI_EST:
        return DoseSplittingMethods.MorningAndEvening;

      case HormoneNames.PROGESTERONE:
        return DoseSplittingMethods.Evening;

      case HormoneNames.TESTOSTERONE_WOMEN:
      case HormoneNames.TESTO_PER_DHEA:
      case HormoneNames.DHEA:
        return DoseSplittingMethods.Morning;

      default:
        return undefined;
    }
  }

  function setSplittedTypeAsPerDefaultValue() {
    if (
      Number(defaultValue?.morning) > 0 &&
      Number(defaultValue?.evening) > 0
    ) {
      setSplittedType(DoseSplittingMethods.MorningAndEvening);
    } else if (Number(defaultValue?.morning) > 0) {
      setSplittedType(DoseSplittingMethods.Morning);
    } else if (Number(defaultValue?.evening) > 0) {
      setSplittedType(DoseSplittingMethods.Evening);
    }
  }

  useEffect(() => {
    if (splittedType) {
      onChange(splittedDose, splittedType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splittedType, value]);

  useEffect(() => {
    if (defaultValue?.morning || defaultValue?.evening) {
      setSplittedTypeAsPerDefaultValue();
    } else {
      setSplittedType(setDefaultSplittedType());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="col-span-9 flex flex-col gap-4">
      <Typography variant="subtitle1">
        How do you want to split the dose?
      </Typography>
      <div className="mt-4 flex h-7">
        <RadioButton
          loading={
            loading && splittedType === DoseSplittingMethods.MorningAndEvening
          }
          onChange={() => {
            setSplittedType(DoseSplittingMethods.MorningAndEvening);
          }}
          checked={splittedType === DoseSplittingMethods.MorningAndEvening}
          id={DoseSplittingMethods.MorningAndEvening}
          label={DoseSplittingMethods.MorningAndEvening}
        />{' '}
        <RadioButton
          loading={loading && splittedType === DoseSplittingMethods.Morning}
          onChange={() => {
            setSplittedType(DoseSplittingMethods.Morning);
          }}
          checked={splittedType === DoseSplittingMethods.Morning}
          id={DoseSplittingMethods.Morning}
          label={DoseSplittingMethods.Morning}
        />{' '}
        <RadioButton
          loading={loading && splittedType === DoseSplittingMethods.Evening}
          onChange={() => {
            setSplittedType(DoseSplittingMethods.Evening);
          }}
          checked={splittedType === DoseSplittingMethods.Evening}
          id={DoseSplittingMethods.Evening}
          label={DoseSplittingMethods.Evening}
        />
      </div>
      <div className={classNames('mt-4 flex gap-20', {})}>
        <div>
          <Typography className=" whitespace-nowrap" variant="h5" align="left">
            Morning Dose
          </Typography>
          <div className="mt-1 min-w-[10rem] rounded bg-background-light !py-2 !px-4 text-center  text-sm font-semibold text-background-contrastText">
            {splittedDose?.morning}
          </div>
        </div>{' '}
        <div>
          <Typography className=" whitespace-nowrap" variant="h5" align="left">
            Evening Dose
          </Typography>{' '}
          <div className="mt-1 min-w-[10rem] rounded bg-background-light !py-2 !px-4 text-center  text-sm font-semibold text-background-contrastText">
            {splittedDose?.evening}
          </div>
        </div>
      </div>
    </div>
  );
}
