class QureyKeysGenerator<FilterKeys extends string[] = ['']> {
  identifier: string;

  constructor(identifier: string) {
    this.identifier = identifier;
  }

  all() {
    return [this.identifier];
  }
  listings() {
    return [...this.all(), 'LISTING'];
  }

  listing(filters?: { [x in FilterKeys[number]]?: string | number }) {
    if (filters && Object.values(filters).some((v) => v !== undefined)) {
      return [...this.listings(), filters];
    }

    return this.listings();
  }
  items() {
    return [...this.all(), 'ITEM'];
  }
  item(id: number | string) {
    return [...this.items(), id];
  }
}

export const QueryKeys = Object.freeze({
  UserProfile: 'USER_PROFILE',
  PatientsChatList: 'PatientsChatList',
  Patients: new QureyKeysGenerator<
    ['page', 'search', 'archived', 'isTodoCompleted', 'size']
  >('PATIENTS'),
  InvitedPatients: new QureyKeysGenerator<['page', 'size']>('INVITED_PATIENTS'),
  PatientDetails: new QureyKeysGenerator('PATIENT'),
  ProfileDetails: 'PROFILE_DETAILS',
  Hormones: 'HORMONES',
  PatientHars: new QureyKeysGenerator<['patientId']>('PATIENT_HARS_STATS'),

  ProviderSpecialties: 'PROVIDER_SPECIALTIES',
  ProviderTypes: 'PROVIDER_TYPES',
  ProviderDEALicenses: 'ProviderDEALicenses',
  PatientNotes: new QureyKeysGenerator('PATIENT_NOTES'),
  PatientPrescriptions: new QureyKeysGenerator<['category', 'id']>(
    'PATIENT_PRESCRIPTION'
  ),
  PatientQuestionnaire: new QureyKeysGenerator<['gender', 'id']>(
    'PATIENT_QUESTIONNAIRE'
  ),
  PatientFollowUpQuestionnaire: new QureyKeysGenerator<['gender', 'id']>(
    'PATIENT_FOLLOWUP_QUESTIONNAIRE'
  ),
  ProviderPracticeDetails: 'PROVIDER_PRACTICE_DETAILS',
  TitrationConfig: new QureyKeysGenerator<['gender', 'titrationType']>(
    'TITRATION_CONFIG'
  ),
  TitrationRxConfig: new QureyKeysGenerator<['titrationType']>(
    'TITRATION_RX_CONFIG'
  ),
  DiagnosisCode: new QureyKeysGenerator<['gender', 'type']>('DIAGNOSIS_CODE'),
  PatientDiagnosisCode: new QureyKeysGenerator<['id']>('DIAGNOSIS_CODE'),
  PatientAllergiesMedication: new QureyKeysGenerator<['id']>(
    'ALLERGIES_MEDICATION'
  ),

  States: 'STATES',
  PatientOrders: new QureyKeysGenerator<['page', 'id', 'type']>(
    'PATIENT_ORDERS'
  ),
  Allergies: new QureyKeysGenerator<['search']>('ALLERGIES'),
  OtherMedication: new QureyKeysGenerator<['search']>('OTHER_MEDICATION'),

  BillingPlans: 'BILLING_PLANS',
  ProviderPractices: new QureyKeysGenerator<
    ['providerId', 'page', 'size', 'search']
  >('PROVIDER_PRACTICES'),
  ProviderPracticeAddresses: new QureyKeysGenerator<['id']>(
    'PROVIDER_PRACTICE_ADDRESSES'
  ),
  ProviderSuffixes: 'PROVIDER_SUFFIXES',
  PharmacyMessages: new QureyKeysGenerator<
    ['page', 'size', 'search', 'noteType']
  >('PHARMACY_MESSAGES'),
  PatientPrescriptionRefills: new QureyKeysGenerator<['id']>(
    'PATIENT_PRESCRIPTIONS_REFILLS'
  ),
  PatientTodoDetails: new QureyKeysGenerator<['id']>('PATIENT_TO_DETAILS'),
  PatientHormonalEffect: new QureyKeysGenerator<['id']>(
    'PATIENT_HORMONAL_EFFECT'
  ),
  PatientCurrentHormonalState: new QureyKeysGenerator<['id']>(
    'PATIENT_CURRENT_HORMONAL_STATE'
  ),
  PrescriptionDosageHistory: new QureyKeysGenerator<
    ['patientId', 'prescriptionId', 'doseIndex']
  >('PATIENT_DOSAGE_HORMONAL'),
});
