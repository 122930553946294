import { useCallback, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export function useThrottle(callback: Function, delay: number) {
  const lastCall = useRef<number>(0);

  const throttledFunction = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...args: any[]) => {
      const now = new Date().getTime();
      if (now - lastCall.current >= delay) {
        lastCall.current = now;
        callback(...args);
      }
    },
    [callback, delay]
  );

  return throttledFunction;
}
