import React, { useMemo } from 'react';

import { useCheckPrescription } from 'hooks/e-prescribe/useCheckPrescription';
import { useHormoneStandardizedName } from 'hooks/e-prescribe/useHormoneStandardizedName';
import { useMaximumDoseHandler } from 'hooks/e-prescribe/useMaximumDoseHandler';
import { useSigGenerator } from 'hooks/e-prescribe/useSigGenerator';
import { useDanazolController } from 'hooks/hormones-new/useDanazolController';
import { HormoneSelectionProps } from 'types/patient';
import {
  HormoneNames,
  HormoneValidations,
  PrescribedObjectType,
  PrescriptionCheckValuesForReview,
  PrescriptionDisplayTypes,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';
import { capitalizedString, formatDeliveryUnit } from 'utils/common';

import { colorObjForSupply } from '../common';
import { HormoneDetailsUIV2 } from '../HormoneDetailsUIV2';

export function Danazol({
  addNewPrescription,
  RxDetails,
  onBack,
}: HormoneSelectionProps) {
  const {
    dtd,
    dose,
    setDose,
    refills,
    setRefills,
    strength,
    setStrength,
    DanazolDetails,
  } = useDanazolController();

  const { maximumDose, setMaximumDose, maxDoseOptions, setMaxDosePreValue } =
    useMaximumDoseHandler({
      dose,
      doses: DanazolDetails.dose,
    });

  const { generatedSIG, setGeneratedSIG } = useSigGenerator({
    dose,
    maximumDose,
    doseOptions: DanazolDetails?.dose,
    unit: DanazolDetails.doseUnit,
  });

  const prescriptionObject: PrescribedObjectType = useMemo(
    () => ({
      id: RxDetails?.id,
      displayName: DanazolDetails.displayName,
      name: HormoneNames.Danazol,
      strength: `${strength}`,
      strengthUnit: DanazolDetails.strengthUnit,
      dose: DanazolDetails.dose[maximumDose + dose],
      minimumDose: DanazolDetails.dose[dose],
      doseUnit: DanazolDetails.doseUnit,
      refills,
      dtd,
      sig: generatedSIG,
      deliveryMethod: DanazolDetails.deliveryMethod,
      family: DanazolDetails.family,
      coveredUnderSubscription:
        RxDetails?.coveredUnderSubscription?.toString() ?? 'false',
      dtdUnit: DanazolDetails.dtdUnit,
    }),
    [
      DanazolDetails,
      RxDetails,
      dose,
      dtd,
      generatedSIG,
      maximumDose,
      refills,
      strength,
    ]
  );

  const { checkPrescriptionValueQuery: maxDosePrescriptionValues } =
    useCheckPrescription({
      prescription: {
        ...prescriptionObject,
        dose: DanazolDetails.dose[dose + maximumDose],
      },
      onSuccess: () => {
        // if (DanazolDetails.ratio[ratio] === '80:20') {
        //   if (Number(data?.value) > 4.05) {
        //     if (strength !== 60) {
        //       // renderModal(strength.toString(), '60', 'mgeeq/day');
        //       setDose(4);
        //       setMaximumDose(0);
        //     }
        //     setStrength(60);
        //   } else {
        //     setStrength(30);
        //     if (strength !== 30) {
        //       // renderModal(strength.toString(), '30', 'mgeeq/day');
        //     }
        //   }
        // }
      },
    });

  const { checkPrescriptionValueQuery: minDosePrescriptionValues } =
    useCheckPrescription({
      prescription: {
        ...prescriptionObject,
        dose: DanazolDetails.dose[dose],
      },
      onSuccess() {
        // console.log(data);
      },
    });

  const { getStandardizedNameQuery } = useHormoneStandardizedName({
    prescription: { ...prescriptionObject, dose: DanazolDetails.dose[dose] },
  });

  const prescriptionCheckValues: PrescriptionCheckValuesForReview = useMemo(
    () => ({
      daysOfSupply: {
        withMaxDose:
          maxDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
        withMinDose:
          minDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
      },
      isHighPotencyRx: false,
      standardizedName:
        getStandardizedNameQuery.data?.data.standardRepresentation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      maxDosePrescriptionValues,
      minDosePrescriptionValues,
      getStandardizedNameQuery.data?.data.standardRepresentation,
    ]
  );

  return (
    <HormoneDetailsUIV2
      onAdd={() =>
        addNewPrescription({
          ...prescriptionObject,
          ...prescriptionCheckValues,
        })
      }
      prescriptionObject={{ ...prescriptionObject, ...prescriptionCheckValues }}
      validations={{
        [HormoneValidations.Loading]: {
          isFailed:
            maxDosePrescriptionValues.isLoading ||
            minDosePrescriptionValues.isLoading ||
            getStandardizedNameQuery.isLoading,
        },
        // [HormoneValidations.MissingDEA]: {
        //   isFailed:
        //     !hasDeaForShippingState && !!RxDetails?.isControlledSubstance,
        // },
        [HormoneValidations.DaysOfSupply]: {
          isFailed: !maxDosePrescriptionValues.data?.data.validDaysOfSupply,
        },
        [HormoneValidations.SIGExceed140Chars]: {
          isFailed: Number(generatedSIG.length) > 140,
        },
      }}
      onBack={onBack}
      title={capitalizedString(RxDetails?.name)}
      hormoneInputs={[
        {
          containerClassName: 'flex justify-between',
          title: '',
          children: [
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: `${dtd.toString()} ${DanazolDetails.dtdUnit} `,
              label: 'DTD',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: DanazolDetails.deliveryMethod,
              label: 'Delivery Method',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!maxDosePrescriptionValues.isLoading ||
                !!minDosePrescriptionValues.isLoading,
              value:
                maxDosePrescriptionValues.data?.data.daysOfSupply ===
                minDosePrescriptionValues.data?.data?.daysOfSupply
                  ? `${maxDosePrescriptionValues.data?.data.daysOfSupply} Days`
                  : `${maxDosePrescriptionValues.data?.data.daysOfSupply}-${minDosePrescriptionValues.data?.data?.daysOfSupply} Days`,
              label: 'Days of Supply',
              colorClass: `${
                maxDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForSupply[
                      maxDosePrescriptionValues.data?.data.validDaysOfSupply.toString()
                    ]
                  : ''
              }`,
            },
          ],
        },
        {
          title: 'Strength',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-10 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.DTD,
              label: 'Strength',
              value: strength,
              unit: DanazolDetails.strengthUnit,
              onChange(val) {
                setStrength(val);
              },
              DTDValues: DanazolDetails.strength,
            },
          ],
        },
        {
          title: 'Dose',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-16 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${`${DanazolDetails.dose[val]} ${formatDeliveryUnit(
                  DanazolDetails.dose[val],
                  DanazolDetails.doseUnit
                )}`}`,
              onChange: (d) => {
                setDose(d);
              },
              options: DanazolDetails.dose,
              value: dose,
              wrapperClassName: 'col-span-9',
            },
          ],
        },

        {
          title: 'Maximum Dose',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-16 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${`${maxDoseOptions[val]} ${formatDeliveryUnit(
                  maxDoseOptions[val],
                  DanazolDetails.doseUnit
                )}`}`,
              onChange: (d) => {
                setMaxDosePreValue(maxDoseOptions[d]);
                setMaximumDose(d);
              },
              options: maxDoseOptions,
              value: maximumDose,
              wrapperClassName: 'col-span-9',
            },
          ],
        },
        {
          title: 'Refills',
          containerClassName:
            'grid grid-cols-12 p-10 pb-16 pt-20 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) => `${DanazolDetails.refills[val]}  refills`,
              onChange: (d) => {
                setRefills(d);
              },
              options: DanazolDetails.refills,
              value: refills,
              wrapperClassName: 'col-span-9',
            },
          ],
        },
        {
          containerClassName: '',
          title: 'SIG',
          children: [
            {
              type: PrescriptionInputTypes.SIG,
              wrapperClassName: 'col-span-9',
              onChange: (s) => {
                setGeneratedSIG(s);
              },
              value: generatedSIG,
            },
          ],
        },
      ]}
    />
  );
}
