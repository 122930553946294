import React, { useEffect, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Controller } from 'react-hook-form';

import { Button } from 'components/common/Button/Button';
import { DayPickerWithInput } from 'components/common/DatePicker/DatePicker';
import { DropDown } from 'components/common/DropDown/DropDown';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { Typography } from 'components/common/Typography/Typography';
import { providerTitlesNotRequiringSupervisorDoctor } from 'constants/provider-titles';
import { QueryKeys } from 'constants/query-keys';
import {
  addDeaAndLicenseSchema,
  supervisingDoctorSchema,
  deaSchema,
} from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { useProfile } from 'hooks/useProfile';
import { extractValidationErrors } from 'lib/general/extractors';
import { UserService } from 'services/user';
import { ThemeName } from 'theme/types';
import { DeaLicense, GetDeaLicenses } from 'types/provider';

import { SupervisingProviderConfirmationModal } from './SupervisingProviderConfirmationModal/SupervisingProviderConfirmationModal';
import { SupervisingProviderDetails } from './SupervisingProviderDetails/SupervisingProviderDetails';

type UpdateProps =
  | {
      isUpdate?: true;
      deaLicenseData: GetDeaLicenses | undefined;
    }
  | {
      isUpdate?: false;
      deaLicenseData?: never;
    };

type AddLicenseDEAProps = {
  showAddModal: boolean;
  handleClose: (state: boolean) => void;
  patientStateId?: string;
  isSuperVisingProviderRequiredOnCheckout?: boolean;
} & UpdateProps;
type AddFormType = {
  dea: string;
  license: string;
  stateId: string;
  licenseExpiry: Date;
  deaExpiry: Date;
  supervisingDea: string;
  firstName: string;
  middleName: string;
  lastName: string;
  npi: string;
  licenseNumber: string;
  suffix: string;
  licenseState: string;
};
export function AddLicenseDeaModal({
  showAddModal,
  handleClose,
  isUpdate,
  deaLicenseData,
  patientStateId,
  isSuperVisingProviderRequiredOnCheckout = false,
}: AddLicenseDEAProps) {
  const [repeatError, setRepeatError] = useState(false);
  const queryClient = useQueryClient();
  const { profile } = useProfile();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDEAExpiryDateSelector, setShowDEAExpiryDateSelector] =
    useState(false);
  const [hasDEANumber, setHasDEANumber] = useState(true);
  const [hasSupervisingProvider, setHasSupervisingProvider] = useState(false);
  const [selectedDEAExpiryDate, setSelectedDEAExpiryDate] = useState<Date>();
  const [showLicensexpiryDateSelector, setShowLicenseExpiryDateSelector] =
    useState(false);
  const [selectedLicenseExpiryDate, setSelectedLicenseExpiryDate] =
    useState<Date>();
  const [reqBody, setReqBody] = useState<DeaLicense>();

  const getStatesQuery = useQuery(
    [QueryKeys.States],
    () => UserService.getStates(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const addDEALicenseMutation = useMutationWithToast(
    UserService.addProviderDeaAndLicense,
    {
      onSuccess() {
        onClose();
      },
    }
  );
  const updateDEALicenseMutation = useMutationWithToast(
    UserService.updateProviderDeaAndLicense,
    {
      onSuccess() {
        onClose();
      },
    }
  );

  let validationSchema = addDeaAndLicenseSchema();

  // Conditionally concatenate the DEA schema
  if (hasDEANumber) {
    validationSchema = validationSchema.concat(deaSchema());
  }

  // Conditionally concatenate the Supervising Doctor schema
  if (hasSupervisingProvider) {
    validationSchema = validationSchema.concat(supervisingDoctorSchema);
  }
  const isSuperVisingProviderRequired =
    !providerTitlesNotRequiringSupervisorDoctor.includes(
      profile?.providerDetails.providerSuffix || ''
    );
  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    errors,
    getValues,
    setValue,
    watch,
  } = useFormWithErrors<AddFormType>({
    mutation: addDEALicenseMutation,
    schema: validationSchema,
  });

  useEffect(() => {
    const firstErrorKey = Object.keys(errors).find(
      (key) => errors[key as keyof AddFormType]
    );

    if (firstErrorKey) {
      const input =
        firstErrorKey === 'deaExpiry' || firstErrorKey === 'licenseExpiry'
          ? 'license'
          : firstErrorKey;
      (
        document.querySelector(
          `input[name="${input}"]`
        ) as HTMLInputElement | null
      )?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errors]);

  const onSubmit = handleSubmit((data) => {
    const requestBody = {
      stateId: data.stateId,
      dea: hasDEANumber ? data.dea : null,
      deaExpiry: hasDEANumber
        ? format(new Date(selectedDEAExpiryDate ?? ''), 'yyyy-MM-dd')
        : null,
      licenseExpiry: format(
        new Date(selectedLicenseExpiryDate ?? ''),
        'yyyy-MM-dd'
      ),
      license: data.license,
      supervisingProvider: hasSupervisingProvider
        ? {
            dea: data.supervisingDea,
            licenseNumber: data.licenseNumber,
            npi: data.npi.toString(),
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            suffix: data.suffix,
            licenseState: data.stateId,
          }
        : null,
    };

    if (hasSupervisingProvider || !isSuperVisingProviderRequired) {
      handleConfirmation(requestBody);
    } else {
      setReqBody(requestBody);
      setShowConfirmationModal(true);
    }
  });

  useEffect(() => {
    if (patientStateId) {
      reset({
        stateId: patientStateId,
        licenseState: patientStateId,
      });
    }
  }, [patientStateId, reset]);

  useEffect(() => {
    if (isUpdate && deaLicenseData) {
      if (
        deaLicenseData.supervisingProvider &&
        deaLicenseData.supervisingProvider.licenseNumber
      ) {
        setHasSupervisingProvider(true);
      }
      if (deaLicenseData.deaExpiry) {
        setSelectedDEAExpiryDate(new Date(deaLicenseData.deaExpiry));
      }
      if (deaLicenseData.licenseExpiry) {
        setSelectedLicenseExpiryDate(new Date(deaLicenseData.licenseExpiry));
      }
      if (deaLicenseData.dea && deaLicenseData.deaExpiry) {
        setHasDEANumber(true);
      }
      reset({
        stateId: deaLicenseData.stateId,
        dea: deaLicenseData.dea || '',
        deaExpiry: deaLicenseData.deaExpiry
          ? new Date(deaLicenseData.deaExpiry)
          : undefined,
        licenseExpiry: deaLicenseData.licenseExpiry
          ? new Date(deaLicenseData.licenseExpiry)
          : undefined,
        license: deaLicenseData.license,
        firstName:
          deaLicenseData?.supervisingProvider?.licensedPrescriberFirstName ||
          '',
        lastName:
          deaLicenseData?.supervisingProvider?.licensedPrescriberLastName || '',
        middleName:
          deaLicenseData?.supervisingProvider?.licensedPrescriberMiddleName ||
          '',
        licenseState: deaLicenseData.stateId,
        licenseNumber: deaLicenseData?.supervisingProvider?.licenseNumber,
        supervisingDea: deaLicenseData?.supervisingProvider?.dea,
        npi: deaLicenseData?.supervisingProvider?.npi,
        suffix: deaLicenseData?.supervisingProvider?.licensedPrescriberSuffix,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, deaLicenseData, showAddModal]);

  const onClose = () => {
    queryClient.refetchQueries([QueryKeys.UserProfile]);
    setRepeatError(false);
    setHasSupervisingProvider(false);

    setSelectedDEAExpiryDate(undefined);
    setSelectedLicenseExpiryDate(undefined);
    setHasDEANumber(true);

    reset();
    clearErrors();
    handleClose(false);
  };

  const getValidationErrors = (fieldName: string) =>
    extractValidationErrors(fieldName, addDEALicenseMutation, errors);

  const states = (getStatesQuery.data?.data || []).map((item) => ({
    title: item.name,
    value: item.id,
  }));
  const stateId = watch('stateId');

  useEffect(() => {
    if (stateId) {
      setValue('licenseState', stateId, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [stateId, setValue]);

  useEffect(() => {
    setHasSupervisingProvider(isSuperVisingProviderRequiredOnCheckout);
  }, [isSuperVisingProviderRequiredOnCheckout]);

  const handleConfirmation = (req: DeaLicense) => {
    setShowConfirmationModal(false);
    if (req) {
      (isUpdate ? updateDEALicenseMutation : addDEALicenseMutation).mutate(
        req,
        {
          onSuccess: () => {
            queryClient.refetchQueries([QueryKeys.ProviderDEALicenses]);
            onClose();
          },
        }
      );
    }
  };

  return (
    <Modal
      className="w-[55%] overflow-visible"
      open={showAddModal}
      closeModal={() => {
        //
      }}>
      <div className="flex flex-col">
        <div className="w-100 mb-3 flex h-10 items-center rounded bg-primary-main p-3">
          <Typography color="white" variant="body1">
            Please provide your DEA & License Details
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-4">
          <DropDown
            theme={ThemeName.Light}
            searchable
            label="Select State"
            name="stateId"
            required
            fullWidth
            disabled={isUpdate || !!patientStateId}
            defaultValue={states.find((s) => s.value === getValues('stateId'))}
            control={control}
            placeholder="Select State"
            options={states || []}
          />
        </div>
        <div className="flex gap-4">
          <TextInput
            name="license"
            control={control}
            fullWidth
            // classname="mr-5"
            required
            label="License"
            placeholder="Enter License number"
          />
          <div className="w-full">
            <Controller
              control={control}
              name="licenseExpiry"
              render={({ field: { onChange }, fieldState: { error } }) => (
                <DayPickerWithInput
                  position="ltr"
                  toggleDayPicker={(t) => setShowLicenseExpiryDateSelector(t)}
                  placeholder="Select Date"
                  label="License Expiration"
                  withInput
                  helperText={error?.message}
                  error={!!error?.message}
                  selectedDate={selectedLicenseExpiryDate}
                  open={showLicensexpiryDateSelector}
                  theme={ThemeName.Dark}
                  onDateSelect={(d) => {
                    onChange({
                      target: { value: d },
                    });

                    setSelectedLicenseExpiryDate(d);
                    setShowLicenseExpiryDateSelector(false);
                  }}
                />
              )}
            />
          </div>
        </div>

        {stateId ? (
          <div className="col-span-9  mt-4 flex flex-col justify-center text-start ">
            <Typography variant="h5">
              Do you hold a DEA license in the state of{' '}
              {states.find((item) => item.value === stateId)?.title}?
            </Typography>

            <div className="my-3 flex w-8 items-center">
              <RadioButton
                label="Yes"
                checked={hasDEANumber}
                id="hasDEA"
                onChange={() => {
                  setHasDEANumber(true);
                }}
              />
              <span className="-mx-12" />
              <RadioButton
                label="No"
                checked={!hasDEANumber}
                id="hasDEA"
                onChange={() => setHasDEANumber(false)}
              />
            </div>
          </div>
        ) : null}

        {stateId && hasDEANumber ? (
          <div className="mt-4 flex  gap-4">
            <TextInput
              name="dea"
              control={control}
              required
              fullWidth
              label="DEA"
              placeholder="Enter DEA number"
            />
            <div className="w-full">
              <Controller
                control={control}
                name="deaExpiry"
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <DayPickerWithInput
                    position="ltr"
                    toggleDayPicker={(t) => setShowDEAExpiryDateSelector(t)}
                    placeholder="Select Date"
                    label="DEA Expiration"
                    withInput
                    fullWidth
                    helperText={error?.message}
                    error={!!error?.message}
                    selectedDate={selectedDEAExpiryDate}
                    open={showDEAExpiryDateSelector}
                    theme={ThemeName.Dark}
                    onDateSelect={(d) => {
                      onChange({
                        target: { value: d },
                      });

                      setSelectedDEAExpiryDate(d);
                      setShowDEAExpiryDateSelector(false);
                    }}
                  />
                )}
              />
            </div>
          </div>
        ) : null}
        {isSuperVisingProviderRequired && stateId ? (
          <div className="col-span-9  mt-4 flex flex-col justify-center text-start ">
            <Typography variant="h5">
              Do you need a supervising provider for the state of{' '}
              {states.find((item) => item.value === stateId)?.title}?
            </Typography>

            <div className="my-3 flex w-8 items-center">
              <RadioButton
                label="Yes"
                checked={hasSupervisingProvider}
                id="supervisingProvider"
                onChange={() => {
                  setHasSupervisingProvider(true);
                }}
              />
              <span className="-mx-12" />
              <RadioButton
                label="No"
                checked={!hasSupervisingProvider}
                id="supervisingProvider"
                onChange={() => setHasSupervisingProvider(false)}
              />
            </div>
          </div>
        ) : null}

        {hasSupervisingProvider ? (
          <SupervisingProviderDetails
            control={control}
            getValidationErrors={getValidationErrors}
            getValues={getValues}
            states={states}
          />
        ) : null}
        <Typography
          color="error"
          className={classNames('invisible mt-1', {
            '!visible': repeatError,
          })}
          variant="subtitle3">
          * This details or state already exists
        </Typography>
        <div className="mt-4 flex justify-end">
          <Button color="secondary" variant="contained" onClick={onClose}>
            Close
          </Button>{' '}
          <span className="mx-2" />
          <Button
            loading={
              isUpdate
                ? updateDEALicenseMutation.isLoading
                : addDEALicenseMutation.isLoading
            }
            onClick={onSubmit}>
            {isUpdate ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>

      <SupervisingProviderConfirmationModal
        showConfirmationMoal={showConfirmationModal}
        state={states.find((item) => item.value === stateId)?.title || ''}
        onCancel={() => {
          setShowConfirmationModal(false);
        }}
        onConfirm={() => handleConfirmation(reqBody as DeaLicense)}
      />
    </Modal>
  );
}
