import { useState } from 'react';

import { PencilSquareIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import { Button } from 'components/common/Button/Button';
import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { Avatar } from 'components/general/Avatar/Avatar';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { GMTDate } from 'lib/general/GMT-date';
import { BillingService } from 'services/billing';
import { PatientService } from 'services/patient';
import { LicenseNumber } from 'types/common';
import { Option } from 'types/inputTypes';
import { AddressType } from 'types/onboard';
import { AllergyType, PatientDiagnosisCodes } from 'types/patient';
import { formatPhoneNumber } from 'utils/common';

import { AllergiesMedicationsModal } from '../AllergiesMedicationsModal/AllergiesMedicationsModal';

type PatientInfoProps = {
  details: {
    id: string;
    firstName: string;
    lastName: string;
    middleName: string;
    email: string;
    phone: string;
    gender: string;
    dob: string;
    completedVisits: number;
    allergies: string;
    picture?: string;
    diagnosisHormone?: PatientDiagnosisCodes;
    diagnosisThyroid?: PatientDiagnosisCodes;
    patientKnownAllergiesByPatient: string;
    patientOtherMedicationsByPatient: string;
    patientKnownAllergiesByProvider?: AllergyType[];
    patientOtherMedicationsByProvider?: Option[];
    patientLicense?: LicenseNumber;
    isActive?: boolean;
    patientSsn?: {
      id: string;
      ssnNumber: string;
    };
    isArchivedPatient: boolean;
    address?: AddressType & { id: string };
    patientSubscriptionMeta?: boolean;
    providerDetails: {
      firstName: string;
      lastName: string;
      middleName: string;
      providerSuffix?: string;
    };
    practiceDetails: {
      name: string;
      practiceAddress?: AddressType;
    };
    invitedBy?: {
      name: string;
      email: string;
      role: {
        name: string;
      };
    };
  };
  checkout?: boolean;
  onRefetch?: () => void;
};
export function PatientInfo({
  details,
  checkout = false,
  onRefetch,
}: PatientInfoProps) {
  // const queryClient = useQueryClient();
  // const navigate = useNavigate();

  const [type, setType] = useState('');
  const [archivePatient, setArchivePatient] = useState({
    state: false,
    id: '',
    hasSubscription: false,
  });
  const [allergiesMedicationModal, setAllergiesMedicationModal] =
    useState(false);
  const closeModal = () => {
    setAllergiesMedicationModal(false);
  };

  const openModal = (modalType: string) => {
    setType(modalType);
    setAllergiesMedicationModal(true);
  };

  const getDiagnosticDetails = (code?: PatientDiagnosisCodes) =>
    code?.diagnosisCode
      ? `${code?.diagnosisCode.code} - ${code?.diagnosisCode.description}`
      : '';

  const cancelSubscriptionMutation = useMutationWithToast(
    BillingService.cancelSubscription
  );
  const activatePatientMutation = useMutationWithToast(
    PatientService.switchUserActive
  );

  function refetchAndClose() {
    onRefetch?.();
    setArchivePatient({ state: false, id: '', hasSubscription: false });
  }

  const handleArchivePatient = () => {
    if (archivePatient.hasSubscription) {
      cancelSubscriptionMutation.mutate(archivePatient.id, {
        onSuccess() {
          refetchAndClose();
        },
      });
    } else {
      activatePatientMutation.mutate(
        { id: archivePatient.id, isActive: false },
        {
          onSuccess() {
            refetchAndClose();
          },
        }
      );
    }
  };

  const handleActivatePatient = () => {
    activatePatientMutation.mutate(
      { id: archivePatient.id, isActive: true },
      {
        onSuccess() {
          refetchAndClose();
        },
      }
    );
  };

  return (
    <div className="shadow-s flex flex-col gap-6 rounded-t bg-background-main p-6">
      <div className="flex">
        <div className="flex gap-6">
          <Avatar
            imgUrl={details?.picture ?? undefined}
            userName={details?.firstName}
            sizeClass="h-32 w-32"
          />
          <div className="flex flex-col gap-2">
            <Typography variant="subtitle1" className="capitalize">
              {details.id}
            </Typography>
            <Typography variant="subtitle1" className="capitalize">
              {details.firstName} {details?.middleName ?? ''} {details.lastName}{' '}
              , {details?.gender},{' '}
              {details?.dob !== 'NA'
                ? new GMTDate(details?.dob).getReadableDateInGMT()
                : 'NA'}
            </Typography>
            <Typography data-tooltip-id="patient_email" variant="subtitle1">
              <span className="inline-block max-w-[17rem] overflow-hidden text-ellipsis whitespace-nowrap">
                {details.email || 'NA'}
              </span>
              <Tooltip
                className={classNames({ ' hidden ': !details.email })}
                id="patient_email">
                {details.email}
              </Tooltip>
            </Typography>
            <Typography variant="subtitle1">
              {formatPhoneNumber(details.phone) || 'NA'}
            </Typography>
          </div>
          <span className=" h-32 w-[2px] bg-background-contrastText " />
          <div className="flex flex-col gap-2">
            <Typography
              color="white"
              variant="subtitle1"
              className="capitalize">
              {details?.providerDetails?.firstName ?? ''}{' '}
              {details?.providerDetails?.middleName ?? ''}{' '}
              {details?.providerDetails?.lastName ?? ''},{' '}
              {details?.providerDetails?.providerSuffix ?? ''}
            </Typography>
            <Typography
              color="white"
              variant="subtitle1"
              className="capitalize">
              {details?.practiceDetails?.name}
            </Typography>
            <Typography
              variant="subtitle1"
              className=" min-w-[16rem] max-w-[16rem] break-words">
              {details.practiceDetails?.practiceAddress?.id
                ? `${`${
                    details.practiceDetails?.practiceAddress?.addressLine1
                  }, ${`${
                    details.practiceDetails?.practiceAddress?.addressLine2
                      ? `${details.practiceDetails?.practiceAddress?.addressLine2},`
                      : ''
                  }`} ${details.practiceDetails?.practiceAddress?.city}, ${
                    details.practiceDetails?.practiceAddress?.state?.name
                  } ${details.practiceDetails?.practiceAddress?.zipCode}, ${
                    details.practiceDetails?.practiceAddress?.country
                  }.`}`
                : 'NA'}
            </Typography>
          </div>
          <span className=" h-32 w-[2px] bg-background-contrastText " />
          <div className="flex flex-col gap-2">
            <Typography
              color="white"
              variant="subtitle1"
              className="capitalize">
              Diagnosis Code
            </Typography>
            {!getDiagnosticDetails(details?.diagnosisHormone) &&
              !getDiagnosticDetails(details?.diagnosisThyroid) && (
                <Typography
                  variant="subtitle1"
                  className=" max-w-[15rem] break-words">
                  Not Available
                </Typography>
              )}
            <Typography
              variant="subtitle1"
              className=" max-w-[15rem] break-words">
              {getDiagnosticDetails(details?.diagnosisHormone) || ''}
            </Typography>
            <Typography
              variant="subtitle1"
              className=" max-w-[15rem] break-words">
              {getDiagnosticDetails(details?.diagnosisThyroid) || ''}
            </Typography>
          </div>
        </div>
      </div>
      {details?.invitedBy?.name && (
        <div className="-mb-3 mt-2 flex flex-col">
          <div>
            <Typography variant="h5">
              <span className="min-w-[9rem]">Inviter Details </span>
            </Typography>
            <div className="mt-1 flex items-start">
              <Typography className="min-w-[11rem]" variant="subtitle1">
                Name
              </Typography>
              <Typography className="min-w-[1rem]" variant="subtitle1">
                :
              </Typography>
              <Typography variant="subtitle1">
                {details?.invitedBy?.name}
              </Typography>
            </div>
            <div className="flex items-start">
              <Typography className="min-w-[11rem]" variant="subtitle1">
                Email
              </Typography>
              <Typography className="min-w-[1rem]" variant="subtitle1">
                :
              </Typography>
              <Typography
                data-tooltip-id="allergiesByProvider"
                variant="subtitle1">
                {details?.invitedBy?.email}
              </Typography>
            </div>{' '}
          </div>
        </div>
      )}

      {!checkout && (
        <>
          <div className=" flex flex-col space-y-3">
            <div>
              <Typography variant="h5">
                <span className="min-w-[9rem]">Known Allergies </span>
                {!details.isArchivedPatient && (
                  <PencilSquareIcon
                    onClick={() => openModal('Allergies')}
                    className="ml-1 h-6 cursor-pointer text-zinc-400"
                  />
                )}
              </Typography>
              <div className="mt-1 flex items-start">
                <Typography className="min-w-[11rem]" variant="subtitle1">
                  Given by Patient
                </Typography>
                <Typography className="min-w-[1rem]" variant="subtitle1">
                  :
                </Typography>
                <Typography
                  data-tooltip-id="allergiesByPatient"
                  variant="subtitle1">
                  <span className="inline-block max-w-[30rem] overflow-hidden text-ellipsis whitespace-nowrap">
                    {details?.patientKnownAllergiesByPatient || 'None'}
                  </span>
                  <Tooltip id="allergiesByPatient">
                    {details?.patientKnownAllergiesByPatient || 'None'}
                  </Tooltip>
                </Typography>
              </div>
              <div className="flex items-start">
                <Typography className="min-w-[11rem]" variant="subtitle1">
                  Given by Provider
                </Typography>
                <Typography className="min-w-[1rem]" variant="subtitle1">
                  :
                </Typography>
                <Typography
                  data-tooltip-id="allergiesByProvider"
                  variant="subtitle1">
                  <span className="inline-block max-w-[30rem] overflow-hidden text-ellipsis whitespace-nowrap">
                    {details?.patientKnownAllergiesByProvider?.reduce(
                      (acc, option) =>
                        `${acc ? `${acc},` : ''} ${option.title ?? option}`,
                      ''
                    ) || 'None'}
                  </span>
                  <Tooltip id="allergiesByProvider">
                    {details?.patientKnownAllergiesByProvider?.reduce(
                      (acc, option) =>
                        `${acc ? `${acc},` : ''} ${option.title ?? option}`,
                      ''
                    ) || 'None'}
                  </Tooltip>
                </Typography>
              </div>{' '}
            </div>
            <div className="">
              <Typography className="min-w-[11rem]" variant="h5">
                <span className="min-w-[9rem]">Other Medications </span>
                {!details.isArchivedPatient && (
                  <PencilSquareIcon
                    onClick={() => openModal('Medication')}
                    className="ml-1 h-6 cursor-pointer text-zinc-400"
                  />
                )}
              </Typography>
              <div className="mt-1 flex items-start">
                <Typography className="min-w-[11rem]" variant="subtitle1">
                  Given by Patient
                </Typography>
                <Typography className="min-w-[1rem]" variant="subtitle1">
                  :
                </Typography>
                <Typography
                  data-tooltip-id="other_medicationsByPatient"
                  variant="subtitle1">
                  <span className="inline-block max-w-[30rem] overflow-hidden text-ellipsis whitespace-nowrap">
                    {details?.patientOtherMedicationsByPatient || 'None'}
                  </span>

                  <Tooltip id="other_medicationsByPatient">
                    {details?.patientOtherMedicationsByPatient || 'None'}
                  </Tooltip>
                </Typography>
              </div>
              <div className="flex items-start">
                <Typography className="min-w-[11rem]" variant="subtitle1">
                  Given by Provider
                </Typography>
                <Typography className="min-w-[1rem]" variant="subtitle1">
                  :
                </Typography>
                <Typography
                  data-tooltip-id="medicationByProvider"
                  variant="subtitle1">
                  <span className="inline-block max-w-[30rem] overflow-hidden text-ellipsis whitespace-nowrap">
                    {details?.patientOtherMedicationsByProvider?.reduce(
                      (acc, option) =>
                        `${acc ? `${acc},` : ''} ${option.title ?? option}`,
                      ''
                    ) || 'None'}
                  </span>
                  <Tooltip id="medicationByProvider">
                    {details?.patientOtherMedicationsByProvider?.reduce(
                      (acc, option) =>
                        `${acc ? `${acc},` : ''} ${option?.title ?? option}`,
                      ''
                    ) || 'None'}
                  </Tooltip>
                </Typography>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between rounded bg-background-light p-4">
            {' '}
            <div className="flex flex-col ">
              <Typography className="min-w-[11rem]" variant="h5">
                Shipping Address
              </Typography>{' '}
              {details.address?.id ? (
                <Typography
                  color="white"
                  className="mt-1 italic"
                  variant="subtitle1">
                  {`${`${details.address?.addressLine1}, ${`${
                    details?.address?.addressLine2
                      ? `${details?.address?.addressLine2},`
                      : ''
                  }`} ${details?.address?.city}, ${
                    details?.address?.state?.name
                  } ${details?.address?.zipCode}, ${
                    details?.address?.country
                  }.`}`}
                </Typography>
              ) : (
                <Typography variant="subtitle2" className="mt-1">
                  Shipping address is not provided.
                </Typography>
              )}
            </div>
          </div>
        </>
      )}
      <div className="flex justify-end  px-4">
        {!checkout && (
          <Button
            className="!p-0 !text-background-contrastText underline"
            variant="text"
            onClick={(e) => {
              e.stopPropagation();
              setArchivePatient({
                state: true,
                id: details?.id,
                hasSubscription: details.completedVisits > 0,
              });
            }}>
            <span className="whitespace-nowrap">
              {' '}
              {details.isActive ? 'Archive' : 'Retrieve'} Patient
            </span>
          </Button>
        )}
      </div>
      {allergiesMedicationModal && (
        <AllergiesMedicationsModal
          type={type}
          patientId={details.id}
          defaultValue={{
            patientKnownAllergiesByPatient:
              details?.patientKnownAllergiesByPatient,
            patientOtherMedicationsByPatient:
              details?.patientOtherMedicationsByPatient,
            patientKnownAllergiesByProvider:
              details?.patientKnownAllergiesByProvider,
            patientOtherMedicationsByProvider:
              details?.patientOtherMedicationsByProvider,
          }}
          onClose={closeModal}
          open={allergiesMedicationModal}
        />
      )}
      <ConfirmationModal
        confirmLoading={
          cancelSubscriptionMutation.isLoading ||
          activatePatientMutation.isLoading
        }
        handleConfirm={
          !details.isActive ? handleActivatePatient : handleArchivePatient
        }
        onCancel={() => {
          setArchivePatient({ state: false, id: '', hasSubscription: false });
        }}
        confirmText={!details.isActive ? 'Retrieve' : 'Archive'}
        semiBoldTitle
        isModalOpen={archivePatient.state}
        title={
          !details.isActive
            ? 'Are you sure you want to retrieve this patient?'
            : 'Are you sure you want to archive this patient? Archiving will move this patient from the active patient list to the archived patient list. Please note that if this patient has an active subscription, archiving them will result in the cancellation of their subscription.'
        }
        closeModal={() => {
          //
        }}
      />
    </div>
  );
}
