import React, { useEffect, useMemo, useState } from 'react';

import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { WarningText } from 'components/common/WarningText/WarningText';
import { DiagnosisOptions } from 'components/patient/checkout/DiagnosisOptions';
import { KnownAllergies } from 'components/patient/checkout/KnownAllergies';
import { OtherMedications } from 'components/patient/checkout/OtherMedications';
import { PrescriptionsBreakdown } from 'components/patient/checkout/PrescriptionsBreakdown';
import { ShippingAddressCard } from 'components/patient/checkout/ShippingAddressCard';
import { SupervisingProviderCard } from 'components/patient/checkout/SupervisingProviderCard';
import { PatientInfo } from 'components/patient/PatientInfo/PatientInfo';
import { DEALicenseListing } from 'components/profile/DEALicenseListing/DEALicenseListing';
import { providerTitlesNotRequiringSupervisorDoctor } from 'constants/provider-titles';
import { QueryKeys } from 'constants/query-keys';
import { useLicenseDeaExpirationDateChecker } from 'hooks/useLicenseDeaExpirationDateChecker';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { useProfile } from 'hooks/useProfile';
import { PatientService } from 'services/patient';
import { PatientDetailsContextType } from 'types/patient';
import { PrescribeMedicationBody } from 'types/patient/prescription';
import { PrescribedObjectType } from 'types/prescriptionTypes';

import { transformMedication } from '../prescriptions/prescribe/common';

type DiagnosisType = {
  hormone?: string;
  thyroid?: string;
};

export function Checkout() {
  // const [note, setNote] = useState('');
  const queryClient = useQueryClient();
  const patientDetails = useOutletContext<PatientDetailsContextType>();
  const { profile } = useProfile();
  const getPatientDetailsQuery = useQuery(
    QueryKeys.PatientDetails.item(patientDetails.id),
    () => PatientService.getPatientDetails(patientDetails.id || ''),
    {
      enabled: !!patientDetails.id,
    }
  );

  const [outOfStateCheckbox, setOutOfStateCheckbox] = useState(false);

  const { checkIfSelectedStateHasDEALicenseExpiry } =
    useLicenseDeaExpirationDateChecker();

  const { state } = useLocation();
  const navigate = useNavigate();

  const [diagnosis, setDiagnosis] = useState<DiagnosisType>({});
  const [diagnosisError, setDiagnosisError] = useState(false);

  const [deliveryMethod, setDeliveryMethod] = useState<string>();
  const [billingCycle, setBillingCycle] = useState<string>();
  const [supervisingDoctor, setSupervisingDoctor] = useState({
    yes: false,
    no: false,
  });
  const prescribeMedicationMutation = useMutationWithToast(
    (a: PrescribeMedicationBody) =>
      PatientService.prescribeMedication(a).then(({ data }) =>
        Promise.allSettled([
          queryClient.refetchQueries(
            QueryKeys.PatientDetails.item(patientDetails.id)
          ),
          queryClient.refetchQueries(
            QueryKeys.PatientDiagnosisCode.listing({ id: patientDetails.id })
          ),
          queryClient.refetchQueries(
            QueryKeys.PatientAllergiesMedication.item(patientDetails.id)
          ),
        ]).then(() => {
          sessionStorage.removeItem(`hormones${patientDetails?.id}`);
          navigate(
            {
              pathname: '../orders',
            },
            {
              state: {
                showNewCrop: true,
                id: data?.order?.id,
              },
            }
          );
        })
      )
  );

  const patientShippingAddress = useMemo(
    () => getPatientDetailsQuery.data?.data.patientShippingAddress,
    [getPatientDetailsQuery.data?.data]
  );

  const hasExpirationDatesAdded = checkIfSelectedStateHasDEALicenseExpiry(
    patientShippingAddress?.state?.id || ''
  );

  const getPatientDrivingLicenseMatchesShippingState = () => {
    if (getPatientDetailsQuery.data?.data?.patientDriversLicense?.id) {
      return (
        getPatientDetailsQuery.data?.data.patientDriversLicense.state.id !==
        patientShippingAddress?.state?.id
      );
    }

    return false;
  };

  // function checkProviderLicenseDea() {
  //   let isLicenseAvailable = false;

  //   if (
  //     profile?.providerDetails.providerLicence.some(
  //       (l: ProviderLicence) => l.stateId === patientShippingAddress?.state?.id
  //     )
  //   ) {
  //     isLicenseAvailable = true;
  //   }

  //   // if (
  //   //   profile?.providerDetails.providerDea.some(
  //   //     (d: ProviderDEA) => d.stateId === patientShippingAddress?.state?.id
  //   //   )
  //   // ) {
  //   //   isDeaAvailable = true;
  //   // }

  //   return isLicenseAvailable;
  // }

  const sameStatePrescription = useMemo(
    () =>
      profile?.providerDetails?.providerLicence?.some(
        (p) => p.stateId === patientShippingAddress?.state?.id
      ),
    [
      profile?.providerDetails?.providerLicence,
      patientShippingAddress?.state?.id,
    ]
  );

  const matchingSupervisingProvider = () =>
    profile?.providerDetails.supervisingProviders.find(
      (sp) => sp.licenseState === patientShippingAddress?.state?.id
    );
  const prescribeHormones = () => {
    let titration;

    if (
      (!!state.diagnosisHormoneList.hormone[0] && !diagnosis?.hormone) ||
      (!!state.diagnosisHormoneList.thyroid[0] && !diagnosis?.thyroid)
    ) {
      setDiagnosisError(true);
      const diagnosisContainer = document.querySelector(
        'div[id="diagnosisOptions"]'
      ) as HTMLDivElement;
      diagnosisContainer.scrollIntoView({ behavior: 'smooth', block: 'end' });

      return;
    }

    let excluded = state?.prescription.excludedHormones;
    if (
      !state?.prescription?.prescribedHormones.some(
        (item: PrescribedObjectType) => {
          let successful = false;

          if (item.isFromTitration) {
            successful = true;
            titration = state?.prescription?.titrationType;
          }

          return successful;
        }
      )
    ) {
      excluded = [];
    }

    const medications = state?.prescription?.prescribedHormones?.map(
      (item: PrescribedObjectType) =>
        transformMedication({ ...item, outOfState: !sameStatePrescription })
    );

    prescribeMedicationMutation.mutate(
      {
        patientId: patientDetails?.id,
        medications,
        titrationType: titration,
        excludedHormones: excluded,
        ...(!!deliveryMethod && { preferredShipping: deliveryMethod }),
        ...(!!billingCycle && { billingCycle }),
        ...(!!diagnosis?.hormone && {
          hormoneDiagnosisCodeId: diagnosis?.hormone,
        }),
        ...(!!diagnosis?.thyroid && {
          thyroidDiagnosisCodeId: diagnosis?.thyroid,
        }),
        // ...(note && {
        //   orderNote: note,
        // }),
      },
      {}
    );
  };

  const getIsPlaceOrderButtonDisabled = () => {
    // if (getPatientDrivingLicenseMatchesShippingState() && !note) {
    //   return "Please select the reason for the mismatch between the state on the patient's driver's license and the shipping state.";
    // }

    if (!sameStatePrescription && !outOfStateCheckbox) {
      return 'Please check the box to confirm that you agree to prescribe this medication for a patient in a state where you are not licensed.';
    }

    const isSupervisingProviderAlreadyAdded =
      profile?.providerDetails?.supervisingProviders.some(
        (sp) => sp.licenseState === patientShippingAddress?.state?.id
      );

    if (
      !isSupervisingProviderAlreadyAdded &&
      !supervisingDoctor.no &&
      !supervisingDoctor.yes &&
      !providerTitlesNotRequiringSupervisorDoctor.includes(
        profile?.providerDetails.providerSuffix || ''
      )
    ) {
      return `Please select whether a supervising provider is required for the state of ${patientShippingAddress?.state?.name}`;
    }

    if (
      isSupervisingProviderAlreadyAdded &&
      matchingSupervisingProvider()?.id &&
      !matchingSupervisingProvider()?.npi
    ) {
      return 'Please provide NPI for supervising provider.';
    }

    return '';
  };

  useEffect(() => {
    if (!state?.prescription || !patientDetails?.id) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-4 pb-80">
      <div className="flex items-center">
        <Button
          onClick={() =>
            navigate(
              {
                pathname: '../prescription/prescribe/e-prescribev2',
                search: `?titrationType=${state?.prescription?.titrationType}`,
              },
              {
                state: {
                  titrationExcludedHormones:
                    state?.prescription?.excludedHormones,
                },
              }
            )
          }
          className="!px-0 !pl-4"
          variant="text">
          <ArrowLeftIcon className=" clh-6 w-6 text-background-contrastText " />
        </Button>
        <Typography className="pl-3" variant="h3">
          Place order
        </Typography>
      </div>
      <SkeletonContent
        layout={[
          {
            className: 'flex h-48 ',
          },
        ]}
        isLoading={
          !patientDetails?.id ||
          !state?.prescription ||
          patientDetails?.dob === 'NA'
        }>
        {() => (
          <PatientInfo
            checkout
            details={{
              ...patientDetails,
              providerDetails: {
                firstName:
                  getPatientDetailsQuery.data?.data?.patientsProvider
                    ?.firstName ?? '',
                middleName:
                  getPatientDetailsQuery.data?.data?.patientsProvider
                    ?.middleName ?? '',
                lastName:
                  getPatientDetailsQuery.data?.data?.patientsProvider
                    ?.lastName ?? '',
                providerSuffix:
                  getPatientDetailsQuery.data?.data?.patientsProvider
                    ?.providerDetails?.providerSuffix,
              },
              practiceDetails: {
                name: getPatientDetailsQuery.data?.data?.practice?.name ?? '',
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                practiceAddress:
                  getPatientDetailsQuery.data?.data?.practiceAddress,
              },
            }}
          />
        )}
      </SkeletonContent>

      {(!!state?.diagnosisHormoneList?.hormone[0] ||
        !!state?.diagnosisHormoneList?.thyroid[0]) && (
        <DiagnosisOptions<DiagnosisType>
          patientDetails={patientDetails}
          diagnosis={diagnosis}
          setDiagnosis={setDiagnosis}
          diagnosisHormoneList={state?.diagnosisHormoneList}
          error={diagnosisError}
          setDiagnosisError={setDiagnosisError}
        />
      )}

      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6 flex flex-col gap-4">
          <OtherMedications
            patientId={patientDetails.id}
            otherMedicationByPatient={
              patientDetails?.patientOtherMedicationsByPatient
            }
            otherMedicationByProvider={
              patientDetails?.patientOtherMedicationsByProvider
            }
          />
          <KnownAllergies
            patientId={patientDetails.id}
            allergiesByPatient={patientDetails?.patientKnownAllergiesByPatient}
            allergiesByProvider={
              patientDetails?.patientKnownAllergiesByProvider
            }
          />
        </div>
        <div className="col-span-6 flex  rounded bg-background-main ">
          <PrescriptionsBreakdown
            patientId={patientDetails.id}
            isConcierge={state?.isConcierge}
            prescriptions={state?.prescription?.prescribedHormones}
            billingCycle={billingCycle}
            setBillingCycle={setBillingCycle}
            deliveryMethod={deliveryMethod}
            setDeliveryMethod={setDeliveryMethod}
          />
        </div>
      </div>
      <ShippingAddressCard
        shippingAddress={patientShippingAddress}
        patientId={patientDetails?.id}
        showNoteUI={getPatientDrivingLicenseMatchesShippingState()}
        onNoteSelect={() => {
          // setNote(n)
        }}
        outOfStateCheckbox={outOfStateCheckbox}
        setOutOfStateCheckbox={setOutOfStateCheckbox}
      />
      {!hasExpirationDatesAdded ? (
        <div className="my-2">
          <Typography variant="h4" className="my-3">
            License & DEA{' '}
          </Typography>
          <DEALicenseListing
            checkoutPage
            stateID={patientShippingAddress?.state?.id || ''}
          />
          <WarningText className="mt-4">
            Please add the expiration dates for your license and DEA.
          </WarningText>
        </div>
      ) : null}

      {!providerTitlesNotRequiringSupervisorDoctor.includes(
        profile?.providerDetails.providerSuffix || ''
      ) && (
        <SupervisingProviderCard
          shippingAddress={patientShippingAddress}
          supervisingProviders={
            profile?.providerDetails?.supervisingProviders ?? []
          }
          supervisingDoctor={supervisingDoctor}
          setSupervisingDoctor={setSupervisingDoctor}
        />
      )}

      <WarningText>
        Please double-check your order. Once a pharmacist has begun to create
        prescriptions, no modifications will be permitted.
      </WarningText>
      <WarningText>
        Please confirm that everything this patient may need is added to this
        order. Currently, any prescription(s) written will be delayed until any
        previously submitted prescription(s) have arrived at the patient&apos;s
        home. We are working to make this simpler in the near future! Thank you
        for your patience with this.
      </WarningText>
      <div className="flex justify-end">
        <Button
          data-tooltip-id="placeOrderButton"
          disabled={!!getIsPlaceOrderButtonDisabled()}
          loading={prescribeMedicationMutation.isLoading}
          onClick={() => prescribeHormones()}>
          Place Order
        </Button>
        {!!getIsPlaceOrderButtonDisabled() && (
          <Tooltip theme="light" id="placeOrderButton">
            {getIsPlaceOrderButtonDisabled()}
          </Tooltip>
        )}
      </div>
    </div>
  );
}
