/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */

import { useEffect, useState, useRef, useCallback } from 'react';

import camelcaseKeys from 'camelcase-keys';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import snakecaseKeys from 'snakecase-keys';

import { useChat } from 'context/ChatProvider';
import { useSocketProvider } from 'context/SocketProvider/SocketProvider';
import {
  ChatUsersListObject,
  MessageDeliveryType,
  MessageMetaData,
  MessageObject,
  SocketResponseMessageObject,
  UsersEmitType,
  UsersType,
} from 'types/socket/types';
import { getUsersObjectInCamelCase } from 'utils/sockets/helper';

export const useSocket = () => {
  const currentUserRef = useRef<ChatUsersListObject | null>(null);
  const [isFetchingPatients, setIsFetchingPatients] = useState(false);
  const [connection, setConnection] = useState(false);

  const location = useLocation();
  const chatAllowedPathname = '/app/chat';
  const socketInstance = useSocketProvider();
  const currentGroupID = useRef<string | null>(null);
  const socketInstanceRef = useRef(socketInstance);

  const messageQueueRef = useRef<{ message: MessageObject; groupId: string }[]>(
    []
  );

  useEffect(() => {
    socketInstanceRef.current = socketInstance;
  }, [socketInstance]);

  const {
    setPatientsList,
    selectedUser,
    updateHistoryMessages,
    updateMessages,
    setIsFetchingChats,
    setTotalMessagesCountInChat,
    removeQueuedMessages,
  } = useChat();

  const leaveGroup = useCallback(
    (groupId: string) => {
      if (socketInstanceRef.current?.socket) {
        // Emit the leave_group event
        socketInstanceRef?.current?.socket.emit('leave_group', {
          group_id: groupId,
        });
        currentGroupID.current = null;
        if (localStorage.getItem('lastGroupIdBeforePageUnload')) {
          localStorage.removeItem('lastGroupIdBeforePageUnload');
        }
      }
    },

    []
  );

  const enterGroup = useCallback((groupId: string) => {
    if (socketInstanceRef.current?.socket) {
      socketInstanceRef.current?.socket.emit('enter_group', {
        group_id: groupId,
      });
      currentGroupID.current = groupId;
    }
  }, []);

  const getPatientsList = useCallback(
    ({
      size,
      page,
      silentFetch,
      search = '',
    }: {
      size: number;
      page?: number;
      silentFetch?: boolean;
      search?: string;
    }) => {
      if (!silentFetch) {
        setIsFetchingPatients(true);
      }

      if (socketInstanceRef.current?.socket) {
        socketInstanceRef.current?.socket?.emit('users', {
          size,
          page: page || 1,
          search,
        });
      }
    },

    []
  );

  const processQueuedMessages = () => {
    if (!socketInstanceRef.current?.socket?.connected) return;

    while (messageQueueRef.current.length > 0) {
      const nextItem = messageQueueRef.current.shift();
      if (nextItem) {
        const { message, groupId } = nextItem;
        removeQueuedMessages(message);
        sendMessage(message.messages, groupId);
      }
    }
  };

  useEffect(() => {
    if (!socketInstance?.socket) return;

    if (socketInstance.socket.connected) {
      processQueuedMessages();
    }

    if (socketInstance?.socket) {
      setConnection(true);
      if (currentGroupID.current) {
        enterGroup(currentGroupID.current);
      }
    } else {
      setConnection(false);

      return;
    }

    socketInstance?.socket?.on('message', (data) => {
      if (
        location.pathname === chatAllowedPathname &&
        currentUserRef.current?.groupId === data.group_id
      ) {
        if ('message_id' in data) {
          updateMessages(
            {
              messages: camelcaseKeys(data.messages, { deep: true }),
              mid: data.message_id,
              groupId: data.group_id || '',
              isIncoming: data.provider !== data.sent_by,
              isOutgoing: data.provider === data.sent_by,
              timeStamp: data.timestamp,
            },
            MessageDeliveryType.SUCCESS
          );
        }
      } else if (location.pathname === chatAllowedPathname) {
        getPatientsList({ size: 30, silentFetch: true });
      }
    });

    socketInstance?.socket?.on('users', (data: UsersType | UsersEmitType) => {
      if ('items' in data) {
        setPatientsList(getUsersObjectInCamelCase(data.items));
        setIsFetchingPatients(false);
      }
    });

    socketInstance?.socket?.on('messages_history', (data) => {
      const convertedCamelCaseMessageObject = data.items.map(
        (item: SocketResponseMessageObject) => ({
          ...item,
          messages: item.messages.map((message: MessageMetaData) => ({
            ...message,
            metadata: camelcaseKeys(message.metadata, { deep: true }),
          })),
        })
      );

      updateHistoryMessages(convertedCamelCaseMessageObject);
      setTotalMessagesCountInChat(data.count);
      setIsFetchingChats(false);
    });

    // eslint-disable-next-line consistent-return
    return () => {
      unmountHandler();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketInstance?.socket]);

  const unmountHandler = async () => {
    if (selectedUser.groupId) {
      await leaveGroup(selectedUser.groupId);
    }
  };

  const sendMessage = useCallback(
    async (messages: MessageMetaData[], groupId: string) => {
      if (socketInstanceRef.current?.socket?.connected && messages.length) {
        const convertedMessages = messages.map((item) =>
          snakecaseKeys(item, { deep: true })
        );
        socketInstanceRef.current?.socket.emit('message', {
          group_id: groupId,
          messages: convertedMessages,
        });
        updateMessages(
          {
            messages,
            mid: Math.random().toString(),
            groupId: selectedUser.groupId || '',
            isOutgoing: true,
            isIncoming: false,
            timeStamp: new Date().toISOString(),
          },
          MessageDeliveryType.SUCCESS
        );
      } else {
        console.error('Socket is not connected or groupId is missing');
        const messageObject = {
          messages,
          mid: Math.random().toString(),
          groupId: selectedUser.groupId || '',
          isOutgoing: true,
          isIncoming: false,
          timeStamp: new Date().toISOString(),
        };
        messageQueueRef.current.push({ message: messageObject, groupId });
        updateMessages(messageObject, MessageDeliveryType.QUEUED);
        await socketInstance?.reconnectSocket();
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socketInstance]
  );

  const openChatHistory = useCallback(
    async ({
      groupId,
      size,
      silentFetch,
    }: {
      groupId: string;
      size?: number;
      silentFetch?: boolean;
    }) => {
      if (!silentFetch) {
        setIsFetchingChats(true);
      }

      if (socketInstanceRef?.current?.socket?.connected) {
        socketInstanceRef.current?.socket.emit('messages_history', {
          group_id: groupId,
          page: 1,
          size: size || 20,
        });
      } else {
        toast.error('Failed to load the message history. Please try again.');
        setIsFetchingChats(false);
        await socketInstance?.reconnectSocket();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socketInstance]
  );

  useEffect(() => {
    if (selectedUser.groupId) {
      currentUserRef.current = selectedUser;
    }
  }, [selectedUser]);

  return {
    connection,

    sendMessage,
    getPatientsList,
    enterGroup,
    openChatHistory,
    leaveGroup,
    isFetchingPatients,
    reConnectionFailed: socketInstance?.reConnectionFailed,
  };
};
