import React, { useState } from 'react';

import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useOutletContext } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { FullHeightModal } from 'components/common/FullViewOvelayModal/FullViewOvelayModal';
import { InfoTooltip } from 'components/common/InfoTooltip/InfoTooltip';
import { Table } from 'components/common/Table/Table';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { PrescriptionDetailsPage } from 'pages/app/patients/[id]/PrescriptionDetailsPage/PrescriptionDetailsPage';
import { PatientService } from 'services/patient';
import { PatientDetailsContextType } from 'types/patient';
import { PatientPrescription } from 'types/patient/prescription';

interface OverviewTableProps {
  title: string;
  category: string;
}

export function OverviewTable({ title, category }: OverviewTableProps) {
  // const navigate = useNavigate();
  const [adjustPrescription, setAdjustPrescription] =
    useState<PatientPrescription[]>();
  const patientDetails = useOutletContext<PatientDetailsContextType>();

  const [showAdjustDose, setShowAdjustDose] = useState(false);

  const getPatientPrescriptionQuery = useQuery(
    QueryKeys.PatientPrescriptions.listing({
      category,
      id: patientDetails?.id ?? '',
    }),
    () =>
      PatientService.getPatientPrescription({
        category: category ?? undefined,
        id: patientDetails?.id ?? '',
      }),
    {
      onSuccess(data) {
        // Extracting the 'id' of each item in the 'adjustPrescription' array into 'activeForAdjust'
        const activeForAdjust = adjustPrescription?.map((i) => i?.id);

        // Iterating over each element in the 'data.data' array (likely a fetched or passed dataset)
        data?.data?.forEach((p) => {
          // Checking if the current element's 'id' exists in 'activeForAdjust'
          if (activeForAdjust?.includes(p?.id)) {
            // Updating 'adjustPrescription' state by mapping through the existing array
            setAdjustPrescription((prev) =>
              prev?.map((i) => {
                // If the 'id' matches , replace the old current dose with the new one (from 'data.data') updated.
                if (i?.id === p?.id) {
                  return {
                    ...i,
                    deliveryMethodAndSig: {
                      ...i?.deliveryMethodAndSig,
                      currentDose: p?.deliveryMethodAndSig?.currentDose,
                    },
                  };
                }

                return i;
              })
            );
          }
        });
      },
      enabled: !!patientDetails?.id && patientDetails?.id !== 'NA',
      refetchOnWindowFocus: false,
    }
  );

  function showAdjustDoseButton() {
    return (
      category === 'current' &&
      getPatientPrescriptionQuery.data?.data.length !== 0
    );
  }

  function getCurrentDoseForActivePrescriptions(
    prescription: PatientPrescription
  ) {
    if (prescription?.titrationType) {
      return 'In Titration';
    }

    const unit = prescription?.deliveryMethodAndSig?.dose?.unit;

    if (prescription?.deliveryMethodAndSig?.currentDose) {
      return ` ${(
        Number(
          prescription?.deliveryMethodAndSig?.currentDose?.morning?.value
        ) +
        Number(prescription?.deliveryMethodAndSig?.currentDose?.evening?.value)
      ).toFixed(2)} ${unit}`;
    }

    if (prescription?.deliveryMethodAndSig?.minDailyDose?.value) {
      return `${prescription?.deliveryMethodAndSig?.minDailyDose?.value} ${unit}`;
    }

    return `${prescription?.deliveryMethodAndSig?.dose?.value} ${unit}`;
  }

  return (
    <div className={classNames('w-full')}>
      <div className="flex h-10 justify-between gap-2">
        <Typography variant="h4">{title}</Typography>
        {showAdjustDoseButton() && (
          <Button
            onClick={() => {
              setAdjustPrescription(getPatientPrescriptionQuery.data?.data);
              setShowAdjustDose(true);
            }}>
            Adjust Dosages
          </Button>
        )}

        {/* {category === 'current' && (
          <SkeletonContent
            isLoading={getPatientPrescriptionQuery.isLoading}
            layout={[
              {
                className: 'h-8 w-32 bg-background-light ',
              },
            ]}>
            {() => (
              <RefillOrderModal
                drivingLicenseState={
                  patientDetails.patientDriversLicense?.state
                }
                shippingAddress={patientDetails.address}
              />
            )}
          </SkeletonContent>
        )} */}
      </div>
      <div className="mt-2 h-full  ">
        {category === 'current' ? (
          <Table<PatientPrescription>
            tableClassName={classNames(' !overflow-auto block ', {
              'max-h-[14rem]':
                getPatientPrescriptionQuery.data?.data.length !== 0,
            })}
            columns={[
              {
                title: <div className="flex gap-1">Name</div>,
                render: (rowData) => (
                  <Button
                    onClick={() => {
                      setAdjustPrescription([rowData]);
                      setShowAdjustDose(true);
                    }}
                    className="!whitespace-pre-wrap !p-0 !text-background-contrastText underline"
                    variant="text">
                    {rowData?.standardRepresentation}
                  </Button>
                ),
              },
              {
                title: <div className="flex items-start">Current Dose</div>,
                render: (rowData) =>
                  getCurrentDoseForActivePrescriptions(rowData),
                classNames: 'flex justify-start',
              },
              {
                title: (
                  <div className="flex gap-1">
                    Refills{' '}
                    <InfoTooltip
                      fullWidth
                      className=""
                      id={`${category}ActiveRefillCount`}
                      label="The number of refills remaining. The number of refills originally prescribed."
                    />
                  </div>
                ),
                render: (rowData) => (
                  <span className="pl-4">
                    {rowData.refills !== 0
                      ? `${rowData.consumedRefills}/${rowData.refills}`
                      : 0}
                  </span>
                ),
              },
            ]}
            rowsData={[...(getPatientPrescriptionQuery.data?.data ?? [])] ?? []}
            noDataMessage="No prescriptions found."
            loading={getPatientPrescriptionQuery?.isLoading}
          />
        ) : (
          <Table<PatientPrescription>
            tableClassName={classNames(' !overflow-auto block ', {
              'max-h-[14rem]':
                getPatientPrescriptionQuery.data?.data.length !== 0,
            })}
            columns={[
              {
                title: <div className="flex gap-1">Name</div>,
                render: (rowData) => (
                  <Button
                    onClick={() => {
                      setAdjustPrescription([rowData]);
                      setShowAdjustDose(true);
                    }}
                    className="!p-0 !text-background-contrastText underline"
                    variant="text">
                    {rowData?.standardRepresentation}
                  </Button>
                ),
              },

              {
                title: (
                  <div className="flex gap-1">
                    Refills{' '}
                    <InfoTooltip
                      fullWidth
                      className=""
                      id={`${category}ActiveRefillCount`}
                      label="The number of refills remaining. The number of refills originally prescribed."
                    />
                  </div>
                ),
                render: (rowData) => (
                  <span className="pl-4">
                    {rowData.refills !== 0
                      ? `${rowData.consumedRefills}/${rowData.refills}`
                      : 0}
                  </span>
                ),
              },
            ]}
            rowsData={[...(getPatientPrescriptionQuery.data?.data ?? [])] ?? []}
            noDataMessage="No prescriptions found."
            loading={getPatientPrescriptionQuery?.isLoading}
          />
        )}
      </div>
      <FullHeightModal isOpen={showAdjustDose}>
        {showAdjustDose && (
          <div className="flex flex-col pb-40">
            {' '}
            <div className="flex items-center py-4">
              <Button
                onClick={() => setShowAdjustDose(false)}
                className="!px-0 !pl-4"
                variant="text">
                <ArrowLeftIcon className=" clh-6  w-6 text-background-contrastText " />
              </Button>
              <Typography className="pl-3" variant="h3">
                Go Back
              </Typography>
            </div>
            <div className="flex w-full  flex-col gap-10 ">
              {adjustPrescription?.map((i) => (
                <PrescriptionDetailsPage prescription={i} />
              ))}
            </div>
          </div>
        )}
      </FullHeightModal>
    </div>
  );
}
