import axios from 'axios';

import { getRefreshToken, setAccessToken } from 'lib/general/token';

export const useUpdateAccessToken = () => {
  const refreshAccessToken = async (): Promise<string> => {
    const refreshToken = getRefreshToken();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST_ORIGIN}/auth/refresh`,
        {
          refresh_token: refreshToken,
        }
      );

      const { access_token: newAccessToken } = response.data;

      setAccessToken(newAccessToken);

      return newAccessToken; // Ensure this is the access token to return
    } catch (err) {
      // removeAccessAndRefreshTokens();
      window.location.replace(process.env.REACT_APP_AUTH_APP_BASE_URL);

      // Optionally, you might want to throw an error or return a fallback value here
      // eslint-disable-next-line no-console
      console.log('Failed to refresh token');

      return '';
    }
  };

  return {
    refreshAccessToken,
  };
};
