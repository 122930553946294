/* eslint-disable no-console */
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { io, Socket } from 'socket.io-client';

// eslint-disable-next-line import/extensions
import { Typography } from 'components/common/Typography/Typography';
import { useUpdateAccessToken } from 'hooks/useUpdateAccessToken';

interface SocketContextType {
  socket: Socket | null;
  hasUnreadMessages: boolean;
  reconnectSocket: () => Promise<void>;
  reConnectionFailed: boolean;
}
type Interval = ReturnType<typeof setInterval>;

const SocketContext = createContext<SocketContextType | null | undefined>(null);

export function SocketProvider({ children }: { children: ReactNode }) {
  const [socket, setSocket] = useState<Socket | null>(null);
  const socketRef = useRef<Socket | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const location = useLocation();
  const { refreshAccessToken } = useUpdateAccessToken();
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const chatAllowedPathname = '/app/chat';
  const navigate = useNavigate();
  const intervalRef = useRef<Interval | null>(null);
  const routeNameRef = useRef<string | null>(null);
  const [reConnectionFailed, setReconnectionFailed] = useState(false);

  const routeName = location.pathname;

  // Update routeNameRef whenever routeName changes
  useEffect(() => {
    routeNameRef.current = routeName;
  }, [routeName]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const accessToken = await refreshAccessToken();
      if (accessToken) {
        setToken(accessToken);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const leaveAlreadyJoinedGroup = () => {
    const lastGroupIdBeforePageUnload = localStorage.getItem(
      'lastGroupIdBeforePageUnload'
    );

    if (lastGroupIdBeforePageUnload) {
      socket?.emit('leave_group', { group_id: lastGroupIdBeforePageUnload });
      localStorage.removeItem('lastGroupIdBeforePageUnload');
    }
  };

  const initializeSocket = useCallback(
    (newToken: string) => {
      if (socketRef.current) return;

      const socketInstance = io(process.env.REACT_APP_API_HOST_ORIGIN, {
        path: '/sockets/messaging/',
        transports: ['websocket'],
        auth: { token: newToken || '' },
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
      });

      socketInstance.on('connect', () => {
        setSocket(socketInstance);
        socketRef.current = socketInstance;
        startIntervalForSocketReconnection();
        setReconnectionFailed(false);
        if (routeName !== chatAllowedPathname) {
          leaveAlreadyJoinedGroup();
        }
      });

      socketInstance.on('disconnect', () => {
        setSocket(null);
      });

      socketInstance.on('connect_error', (error) => {
        // eslint-disable-next-line no-console
        console.error('Connection error:', error.message);
        setReconnectionFailed(true);
      });

      socketInstance.on('unread_messages_count', (data) => {
        if (data.count) {
          setHasUnreadMessages(true);
        } else {
          setHasUnreadMessages(false);
        }
      });

      socketInstance.on('message', (data) => {
        if (routeNameRef.current !== chatAllowedPathname) {
          if (data) {
            setHasUnreadMessages(true);
          } else {
            setHasUnreadMessages(false);
          }
          toast(
            () => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                className="p-2"
                onClick={() => {
                  navigate('/app/chat');
                }}>
                <Typography variant="subtitle2">
                  You&apos;ve received a new message from {data.sender_name}
                </Typography>
              </div>
            ),
            { className: '!bg-[#000000] cursor-pointer' }
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatAllowedPathname]
  );

  const disconnectSocket = (authToken: string): Promise<void> =>
    new Promise((resolve, reject) => {
      if (socketRef.current) {
        try {
          console.log('Disconnecting socket...');
          socketRef.current.auth = { token: authToken };
          socketRef.current.removeAllListeners(); // Remove all listeners to avoid memory leaks
          socketRef.current.disconnect(); // Disconnect the socket
          setSocket(null); // Reset socket state
          socketRef.current = null; // Reset socketRef
          console.log('Socket disconnected successfully');
          resolve(); // Resolve the promise
        } catch (error) {
          console.error('Error during socket disconnection:', error);
          reject(error); // Reject the promise on error
        }
      } else {
        console.error('Socket is not initialized');
        reject(); // Reject if socket is not initialized
      }
    });

  const reconnectSocket = useCallback(
    async (): Promise<void> =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {
        try {
          const newToken = await refreshAccessToken();
          if (!newToken) {
            // eslint-disable-next-line no-console
            console.log('New token not received for socket connection');

            return;
          }

          await disconnectSocket(newToken); // Disconnect current socket session
          // Initialize a new socket with the new token
          await initializeSocket(newToken);

          // Wait for the socket to successfully reconnect
          socketRef.current?.once('connect', () => {
            // eslint-disable-next-line no-console
            console.log('Socket reconnected');
            resolve(); // Resolve when socket is reconnected
          });

          // Handle connection errors
          socketRef.current?.once('connect_error', (error) => {
            console.error('Reconnection error:', error.message);
            reject(error); // Reject on connection error
          });
        } catch (error) {
          console.error('Error during reconnection:', error);
          reject(error); // Handle any errors during the reconnection process
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initializeSocket, refreshAccessToken]
  );

  useEffect(() => {
    if (token) {
      initializeSocket(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const startIntervalForSocketReconnection = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(async () => {
      // eslint-disable-next-line no-console
      console.log('Reconnecting socket after 8 minutes to refresh session...');
      reconnectSocket();
    }, 8 * 60 * 1000);
  };

  const handleVisibilityChange = async () => {
    const newToken = await refreshAccessToken();
    if (document.visibilityState === 'visible') {
      initializeSocket(newToken); // Reconnect socket and start the interval
    } else {
      leaveAlreadyJoinedGroup();
      disconnectSocket(newToken);
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      } // Cleanup when the component unmounts
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const values = useMemo(
    () => ({
      socket,
      hasUnreadMessages,
      reconnectSocket,
      reConnectionFailed,
    }),
    [hasUnreadMessages, reconnectSocket, socket, reConnectionFailed]
  );

  return (
    <SocketContext.Provider value={values || null}>
      {children}
    </SocketContext.Provider>
  );
}

export const useSocketProvider = () => {
  const socket = useContext(SocketContext);
  if (socket === undefined) {
    throw new Error('useSocket must be used within a SocketProvider');
  }

  return socket;
};
