import { useEffect } from 'react';

import { useProfile } from 'hooks/useProfile';
import { UserTypes } from 'types/common';
import { getLogoutUrl } from 'utils/common';

export function RedirectToAuth() {
  const { profile } = useProfile();
  useEffect(() => {
    window.location.replace(
      getLogoutUrl(profile?.role.name || UserTypes.Provider)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return null;
}
