import {
  QuestionnaireCategoryType,
  QuestionnaireResponseType,
  QuestionObject,
  QuestionTypes,
  SummaryQuestionStructureType,
  SummaryQuestionType,
  Tab,
} from 'types/questionnaire';
import { normalizeString } from 'utils/common';

export function getSectionWiseQuestions(
  questions: QuestionnaireResponseType,
  gender: string
): {
  sectionWiseQuestions: QuestionnaireResponseType;
  filteredTabs: Tab[];
  filteredSummaryQuestions: SummaryQuestionType;
} {
  const filteredSummaryQuestions: SummaryQuestionType = {};
  // eslint-disable-next-line
  let tabs: any = {};

  const sectionWiseQuestions = Object.values(questions)
    .flat(1)
    .reduce((acc: QuestionnaireResponseType, question: QuestionObject) => {
      if (typeof question === 'object' && question) {
        // eslint-disable-next-line
        for (let i in summaryQuestions[gender]) {
          let addIndex = 0;
          if (
            summaryQuestions[gender][i].some((q, index) => {
              addIndex = index;

              return q?.id === question?.question?.id;
            })
          ) {
            if (!filteredSummaryQuestions[i]) {
              filteredSummaryQuestions[i] = [];
            }
            filteredSummaryQuestions[i][addIndex] = question;
          }
        }
        if (!tabs[question?.section?.apiKey]) {
          tabs = {
            ...tabs,
            [question?.section?.apiKey]: {
              title: question?.section?.label,
              link: question?.section?.apiKey,
            },
          };
        }

        return {
          ...acc,
          [question?.section?.apiKey]: acc[question?.section?.apiKey]
            ? [...acc[question?.section?.apiKey], question]
            : [question],
        };
      }

      return { ...acc };
    }, {});

  return {
    sectionWiseQuestions,
    filteredTabs: Object.values(tabs),
    filteredSummaryQuestions,
  } as {
    sectionWiseQuestions: QuestionnaireResponseType;
    filteredTabs: Tab[];
    filteredSummaryQuestions: SummaryQuestionType;
  };
}

export function getCategoryWiseQuestion(questions: QuestionObject[]) {
  const updatedQuestion = questions.reduce(
    // eslint-disable-next-line
    (acc: any, question: QuestionObject) => {
      const existingQ =
        acc[normalizeString(question?.category?.label ?? '')]?.questions ?? [];

      return {
        ...acc,
        [normalizeString(question?.category?.label ?? '')]: {
          label: question?.category?.label ?? '',
          questions: [...existingQ, question],
        },
      };
    },
    {}
  );

  return Object.values(updatedQuestion) as QuestionnaireCategoryType[];
}

export const binaryAnswerToRating: { [x: string]: number } = {
  yes: 10,
  Yes: 10,
  no: 0,
  No: 0,
  NA: 0,
};

export function getAnswer(question: QuestionObject) {
  switch (question?.type) {
    case QuestionTypes.NUMBER_INPUT:
    case QuestionTypes.TEXT_BOX:
    case QuestionTypes.TEXT_INPUT:
      return question.answer?.text ?? 'NA';

    case QuestionTypes.RADIO_OPTIONS:
      if (question.question.id === '708100b4-c211-11ed-afa1-0242ac120002') {
        return binaryAnswerToRating?.[question?.answer?.selectedOption ?? 'NA'];
      }

      return question.answer?.selectedOption ?? 'NA';

    case QuestionTypes.SLIDER:
      return question.answer?.text || Number(question.answer?.text) === 0
        ? String(question.answer?.text)
        : '0';

    case QuestionTypes.MULTI_INPUTS:
      return question.options.map((option) => option.answer?.text).toString();
    default:
      return '';
  }
}

export const getAverage = (questions: QuestionObject[]) => {
  if (!questions) {
    return '';
  }
  const total = questions.reduce(
    (a, i) => Number(a) + Number(getAnswer(i) === 'NA' ? 0 : getAnswer(i)),
    0
  );
  const average = total / questions.length;

  return average.toFixed(1);
};

export const summaryQuestions: {
  [key: string]: SummaryQuestionStructureType;
} = {
  F: {
    header: [
      { label: 'Name', id: '1111' },
      { label: 'DOB', id: '2222' },
      { label: 'Current Age', id: '3333' },
      { label: 'State', id: '4444' },
    ],

    section1: [
      { label: 'ht tall', id: '7080336e-c211-11ed-afa1-0242ac120002' },
      { label: 'ht now', id: '7080344a-c211-11ed-afa1-0242ac120002' },
      { label: 'wgt 25', id: '' },
      { label: 'wgt now', id: '' },
      { label: '', id: '' },
      { label: '', id: '' },
    ],

    section2: [
      { label: 'G', id: '708054b6-c211-11ed-afa1-0242ac120002' },
      { label: 'P', id: '70805c0e-c211-11ed-afa1-0242ac120002' },
      { label: 'M', id: '7080504c-c211-11ed-afa1-0242ac120002' },
      { label: 'Ab', id: '70805128-c211-11ed-afa1-0242ac120002' },
      { label: 'LMP', id: '70803530-c211-11ed-afa1-0242ac120002' },
      { label: 'LRMP', id: '7080360c-c211-11ed-afa1-0242ac120002' },
    ],

    section3: [
      { label: 'Energy', id: '70800588-c211-11ed-afa1-0242ac120002' },
      { label: 'Mood', id: '7080066e-c211-11ed-afa1-0242ac120002' },
      { label: 'Sleep', id: '7080074a-c211-11ed-afa1-0242ac120002' },
      { label: 'Libido', id: '70800830-c211-11ed-afa1-0242ac120002' },
      { label: 'Cognition', id: '70800916-c211-11ed-afa1-0242ac120002' },
      { label: 'Pain free', id: '70800c5e-c211-11ed-afa1-0242ac120002' },
    ],

    section4: [
      { label: 'Comm relat', id: '70800f38-c211-11ed-afa1-0242ac120002' },
      { label: 'Children', id: '70800e52-c211-11ed-afa1-0242ac120002' },
      { label: 'BM/wk', id: '7080241e-c211-11ed-afa1-0242ac120002' },
      { label: 'Constip', id: '7080287e-c211-11ed-afa1-0242ac120002' },
      { label: 'Osteopenia', id: '7080ad30-c211-11ed-afa1-0242ac120002' },
      { label: 'Osteoporosis', id: '7080aefc-c211-11ed-afa1-0242ac120002' },
    ],

    section5: [
      { label: 'Hysterex', id: '7080a254-c211-11ed-afa1-0242ac120002' },
      { label: 'Oophorex', id: '7080a42a-c211-11ed-afa1-0242ac120002' },
      { label: 'BBx', id: '7080826a-c211-11ed-afa1-0242ac120002' },
      { label: 'BCa', id: '708083be-c211-11ed-afa1-0242ac120002' },
      { label: 'FH BCa', id: '7080ba3c-c211-11ed-afa1-0242ac120002' },
    ],

    section6: [
      { label: 'Symptom Summaries', id: '' },
      { label: 'Estrog def', id: '' },
      { label: 'Estrog xs', id: '' },
      { label: 'Prog def', id: '' },
      { label: 'Test def', id: '' },
      { label: 'R of Total', id: '' },
    ],
    mainSection: [
      { label: 'LFW', id: '707fce42-c211-11ed-afa1-0242ac120002' },
      { label: 'CC', id: '707fd342-c211-11ed-afa1-0242ac120002' },
      { label: 'PMH', id: '707fd482-c211-11ed-afa1-0242ac120002' },
      { label: 'Wants', id: '707fd5a4-c211-11ed-afa1-0242ac120002' },
      { label: 'Stress', id: '70802090-c211-11ed-afa1-0242ac120002' },
      { label: 'Meds', id: '707feb5c-c211-11ed-afa1-0242ac120002' },
      { label: 'PMH meds', id: '707fec6a-c211-11ed-afa1-0242ac120002' },
      { label: 'Med Aller', id: '707fed8c-c211-11ed-afa1-0242ac120002' },
    ],
    estrogenDeficiency: [
      { label: 'Hot Flash', id: '7080c7ac-c211-11ed-afa1-0242ac120002' },
      { label: 'Warm Rush', id: '7080c978-c211-11ed-afa1-0242ac120002' },
      { label: 'Night Sweat', id: '7080cb62-c211-11ed-afa1-0242ac120002' },
      { label: 'Weight Gain', id: '7080cce8-c211-11ed-afa1-0242ac120002' },
      { label: 'Depression', id: '7080d1de-c211-11ed-afa1-0242ac120002' },
      { label: 'Waking Up', id: '7080d328-c211-11ed-afa1-0242ac120002' },
      { label: 'Pain', id: '7080d436-c211-11ed-afa1-0242ac120002' },
      { label: 'Cover Kick', id: '7080d544-c211-11ed-afa1-0242ac120002' },
      { label: 'Dry Vagina', id: '7080d652-c211-11ed-afa1-0242ac120002' },
      { label: 'Palpitations', id: '7080d77e-c211-11ed-afa1-0242ac120002' },
      { label: 'Brain fog', id: '7080d90e-c211-11ed-afa1-0242ac120002' },
      { label: 'Chest Pain', id: '7080da4e-c211-11ed-afa1-0242ac120002' },
      { label: 'Racing Mind', id: '7080dd5a-c211-11ed-afa1-0242ac120002' },
      { label: 'HA/Migraine', id: '7080dea4-c211-11ed-afa1-0242ac120002' },
      { label: 'Bloat', id: '7080e00c-c211-11ed-afa1-0242ac120002' },
      { label: '< Sex, Sensual', id: '7080e30e-c211-11ed-afa1-0242ac120002' },
      { label: 'Hair Loss', id: '7080e49e-c211-11ed-afa1-0242ac120002' },
      { label: 'Dyspareunia', id: '7080e5ac-c211-11ed-afa1-0242ac120002' },
    ],
    estrogenExcess: [
      { label: 'Br Tenderness', id: '7080e6c4-c211-11ed-afa1-0242ac120002' },
      { label: 'Water Retention', id: '7080e7d2-c211-11ed-afa1-0242ac120002' },
      { label: 'Nipple Tender', id: '7080eb9c-c211-11ed-afa1-0242ac120002' },
      { label: 'Swelling', id: '7080ecbe-c211-11ed-afa1-0242ac120002' },
      { label: 'Breast Fullness', id: '7080ede0-c211-11ed-afa1-0242ac120002' },
      { label: 'Impatient', id: '7080eef8-c211-11ed-afa1-0242ac120002' },
      { label: 'Nausea', id: '7080f006-c211-11ed-afa1-0242ac120002' },
      { label: 'Breast Swelling', id: '7080f114-c211-11ed-afa1-0242ac120002' },
      { label: 'Cramps', id: '7080f22c-c211-11ed-afa1-0242ac120002' },
      { label: '', id: '' },
      { label: '', id: '' },
      { label: '', id: '' },
      { label: '', id: '' },
      { label: '', id: '' },
      { label: '', id: '' },
      { label: '', id: '' },
      { label: '', id: '' },
      { label: '', id: '' },
    ],

    progesteroneDeficiency: [
      { label: 'Difficulty Sleep', id: '7080f56a-c211-11ed-afa1-0242ac120002' },
      { label: 'Anxiety', id: '7080f6dc-c211-11ed-afa1-0242ac120002' },
      { label: 'Irreg Period', id: '7080f862-c211-11ed-afa1-0242ac120002' },
      { label: 'Spotting', id: '7080fa06-c211-11ed-afa1-0242ac120002' },
      { label: 'Water Retent', id: '7080fc4a-c211-11ed-afa1-0242ac120002' },
      { label: 'Nausea', id: '7080fdbc-c211-11ed-afa1-0242ac120002' },
      { label: 'Infreq Period', id: '7080ff4c-c211-11ed-afa1-0242ac120002' },
      { label: 'No Period', id: '708100b4-c211-11ed-afa1-0242ac120002' },
      { label: 'Heavy Period', id: '70810500-c211-11ed-afa1-0242ac120002' },
      { label: 'Painful Breasts', id: '708106b8-c211-11ed-afa1-0242ac120002' },
      { label: 'Fibrocystic Br', id: '7081082a-c211-11ed-afa1-0242ac120002' },
      { label: 'Ut. Fibroids', id: '70810988-c211-11ed-afa1-0242ac120002' },
      { label: 'Endometriosis', id: '70810af0-c211-11ed-afa1-0242ac120002' },
      { label: '< Sex Drive', id: '70810c6c-c211-11ed-afa1-0242ac120002' },
      { label: 'PMS', id: '70810e6a-c211-11ed-afa1-0242ac120002' },
      { label: '', id: '' },
      { label: '', id: '' },
      { label: '', id: '' },
    ],

    testoteroneDeficiency: [
      { label: '< Sex Drive', id: '7081136a-c211-11ed-afa1-0242ac120002' },
      { label: 'Insecurity', id: '7081177a-c211-11ed-afa1-0242ac120002' },
      { label: 'Indecisiveness', id: '70811950-c211-11ed-afa1-0242ac120002' },
      { label: '< Aggressiveness', id: '70811ae0-c211-11ed-afa1-0242ac120002' },
      { label: 'Musc Weakness', id: '70811c8e-c211-11ed-afa1-0242ac120002' },
      {
        label: '< Energy and Stamina',
        id: '70811e3c-c211-11ed-afa1-0242ac120002',
      },
      { label: 'Musc Flabbiness', id: '7081203a-c211-11ed-afa1-0242ac120002' },
      { label: '< Stand fr squat', id: '708121fc-c211-11ed-afa1-0242ac120002' },
      { label: 'Cough incontin', id: '70812346-c211-11ed-afa1-0242ac120002' },
      {
        label: '< Coordination/Bal',
        id: '708127ce-c211-11ed-afa1-0242ac120002',
      },
      { label: 'Hair Loss', id: '70812904-c211-11ed-afa1-0242ac120002' },
      {
        label: '< armpit/pubic hair',
        id: '70812a12-c211-11ed-afa1-0242ac120002',
      },
      {
        label: '< love body image',
        id: '70812b8e-c211-11ed-afa1-0242ac120002',
      },
      { label: '', id: '' },
      { label: '', id: '' },
      { label: '', id: '' },
      { label: '', id: '' },
      { label: '', id: '' },
    ],

    head: [
      {
        label: 'Headaches',
        id: '70812cf6-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Dizziness',
        id: '70812e4a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hair Thinning',
        id: '70812fc6-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fainting',
        id: '708133b8-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Convulsions',
        id: '70813552-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hair Loss',
        id: '708136c4-c211-11ed-afa1-0242ac120002',
      },
    ],

    eyes: [
      {
        label: 'Blurred Vision',
        id: '70813818-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Eye Pain',
        id: '7081398a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty in Vision',
        id: '70813af2-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Double Vision',
        id: '70813c64-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Itchy Eyes',
        id: '70813dcc-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Diminished Close-Up Vision',
        id: '708140ce-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Spots in Front of Eyes',
        id: '7081424a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Do you wear glasses?',
        id: '708143bc-c211-11ed-afa1-0242ac120002',
      },
    ],

    ears: [
      {
        label: 'Earaches',
        id: '7081451a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Ear Infections',
        id: '708146a0-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Ringing in Ears',
        id: '708147fe-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hearing Loss',
        id: '70814970-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Itchy Ears',
        id: '70814b64-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Change in Hearing',
        id: '70814f92-c211-11ed-afa1-0242ac120002',
      },
    ],

    nose: [
      {
        label: 'Stuffy Nose',
        id: '7081512c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Nasal Discharge',
        id: '708152b2-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Nosebleeds',
        id: '70815442-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sinus Problems',
        id: '7081564a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sinus Infections',
        id: '70815816-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Post Nasal Drip',
        id: '708159b0-c211-11ed-afa1-0242ac120002',
      },
    ],

    allergy: [
      {
        label: 'Pollen Allergy',
        id: '70816a22-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hay Fever',
        id: '70816b30-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Dust Allergy',
        id: '70816c3e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Asthma',
        id: '70816d4c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Frequent Sneezing',
        id: '70816e50-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Seasonal Sneezing',
        id: '70816f5e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Stuffy Nose After Eating',
        id: '70817346-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Trouble going into shopping malls',
        id: '7081749a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hypersensitivity to Medications',
        id: '708175b2-c211-11ed-afa1-0242ac120002',
      },
    ],

    mouthAndThroat: [
      {
        label: 'Canker Sores',
        id: '70815b0e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Tooth Pain',
        id: '70815ea6-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sore Gums',
        id: '70815fdc-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Tooth Sensitivity',
        id: '708160f4-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Bleeding Gums',
        id: '708161f8-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Coated Tongue',
        id: '70816306-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty Swallowing',
        id: '70816414-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Breath Odor',
        id: '7081652c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sore Throat',
        id: '70816630-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Do You Floss?',
        id: '70816914-c211-11ed-afa1-0242ac120002',
      },
    ],

    sleep: [
      {
        label: 'Difficulty in Sleeping',
        id: '708176de-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Awaken in Night',
        id: '70817850-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty Falling Asleep',
        id: '7081797c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty Falling Back Asleep',
        id: '70817a8a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sleep Less than 7 Hours',
        id: '70817b8e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Five hours or less of sleep per night',
        id: '70817e54-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Work night or afternoon shift',
        id: '70817f8a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Heavy snoring or gasping',
        id: '70818098-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Disturbing Dreams',
        id: '7081819c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Overall Sleep Quality',
        id: '708182d2-c211-11ed-afa1-0242ac120002',
      },
    ],

    immuneSystem: [
      {
        label: 'Cold Sores in the Mouth',
        id: '7081846c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Colds or other infections',
        id: '708185fc-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Known Allergies',
        id: '708187dc-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Swollen Glands',
        id: '70818c6e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty Healing',
        id: '70818dfe-c211-11ed-afa1-0242ac120002',
      },
    ],

    cardiovascular: [
      {
        label: 'Skipped Heartbeat',
        id: '70818f20-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Leg cramping on walking',
        id: '70819038-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Rapid or pounding heartbeat',
        id: '70819146-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Leg cramps at night',
        id: '70819254-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Palpitations',
        id: '70819362-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'High Blood Pressure',
        id: '7081947a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Chest Pain',
        id: '70819722-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Pain in legs when walking',
        id: '70819858-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Irregular Heart Beat',
        id: '70819966-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fluid Retention (Swelling)',
        id: '70819a7e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Anemia',
        id: '70819bfa-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Dizzy Upon Standing',
        id: '70819d9e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Varicose Veins',
        id: '70819f6a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Bruise Easily',
        id: '7081a10e-c211-11ed-afa1-0242ac120002',
      },
    ],

    lungs: [
      {
        label: 'Cough',
        id: '7081a4ce-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'History of Smoking',
        id: '7081a636-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Shortness of breath during the day',
        id: '7081a79e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Asthma',
        id: '7081a8fc-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Shortness of breath at night',
        id: '7081aa14-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Bronchitis',
        id: '7081ac94-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty Breathing',
        id: '7081adc0-c211-11ed-afa1-0242ac120002',
      },
    ],

    intestine: [
      {
        label: 'Nausea',
        id: '7081aece-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Discomfort in Abdomen',
        id: '7081b22a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Vomiting',
        id: '7081b342-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Foods you have trouble with',
        id: '7081b45a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Bloated Feeling',
        id: '7081b568-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fatigue or anxiety relieved by sweets',
        id: '7081b676-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Burning in Stomach',
        id: '7081b784-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Indigestion 1-2 hours after eating',
        id: '7081b89c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Heartburn',
        id: '7081b9aa-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fullness long after meals',
        id: '7081bc84-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Pain in Abdomen',
        id: '7081bd88-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sleeping After Meals',
        id: '7081bea0-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Diarrhea',
        id: '7081bfae-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Nails bend or break easily',
        id: '7081c0d0-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Constipation',
        id: '7081c256-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Blood in Stool',
        id: '7081c3f0-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Excessive Belching',
        id: '7081c58a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Black Stool',
        id: '7081cada-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Excessing passing of gas',
        id: '7081cc9c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Anal Itch',
        id: '7081ce54-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Indigestion',
        id: '7081cff8-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Pain on Defecation',
        id: '7081d1a6-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Craving Sweets',
        id: '7081d35e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hemorrhoids',
        id: '7081d534-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hepatitis',
        id: '7081d714-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Goosebumps on back of arms',
        id: '7081da52-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Gallstones',
        id: '7081dbce-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty with oily foods',
        id: '7081dd22-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Nausea Upon Eating',
        id: '7081de30-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty with Dairy',
        id: '7081df3e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Change in Appetite',
        id: '7081e04c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty with Wheat',
        id: '7081e15a-c211-11ed-afa1-0242ac120002',
      },
    ],

    urinaryTract: [
      {
        label: 'Burning or Pain on Urination',
        id: '7081e600-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Frequency of Urination',
        id: '7081e768-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Bladder Infections',
        id: '7081e880-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Urgency of Urination',
        id: '7081e98e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Kidney Infections',
        id: '7081ea9c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fluid Retention (Swelling)',
        id: '7081ebd2-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Up at night to urinate',
        id: '7081ede4-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Kidney Stones',
        id: '7081ef74-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Blood in Urine',
        id: '7081f2a8-c211-11ed-afa1-0242ac120002',
      },
    ],

    skin: [
      {
        label: 'Pimples or Acne',
        id: '7081f3f2-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Oily Skin',
        id: '7081f514-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Dry Skin',
        id: '7081f62c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hives',
        id: '7081f73a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Rashes',
        id: '7081f85c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Skin Itch',
        id: '7081f974-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sweating',
        id: '7081fa82-c211-11ed-afa1-0242ac120002',
      },
    ],

    weight: [
      {
        label: 'Compulsive or Binge Eating',
        id: '7081fd16-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Craving Certain Foods',
        id: '7081fe2e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sweet Craving',
        id: '7081ff3c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Weight Loss',
        id: '7082004a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Excessive Weight',
        id: '7082014e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Weight Gain',
        id: '7082025c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Underweight',
        id: '70820374-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Inability to Gain Weight',
        id: '70820478-c211-11ed-afa1-0242ac120002',
      },
    ],

    energy: [
      {
        label: 'Fatigue in General',
        id: '708208ce-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Awaken Energetic, Fatigue Easily',
        id: '708209f0-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hyperactivity',
        id: '70820b08-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Awaken sluggish, improve with day',
        id: '70820c0c-c211-11ed-afa1-0242ac120002',
      },
    ],

    hormonal: [
      {
        label: 'Mid-Life Weight Gain',
        id: '70820d10-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Eyes Sensitive to Bright Light',
        id: '70820e1e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Cold Intolerance',
        id: '70820f2c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Irritable When Hungry',
        id: '7082121a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Swelling Under Eyes',
        id: '70821382-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Feel Better After Exercise',
        id: '70821562-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sleep Disturbance',
        id: '70821698-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Cold Hands and Feet',
        id: '708217a6-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Loss of muscle mass or strength',
        id: '708218be-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Uncomfortable in Cold',
        id: '708219c2-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sweet Craving',
        id: '70821ad0-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Uncomfortable in Heat',
        id: '70821d6e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fatigue Easy',
        id: '70821e86-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Dizzy upon standing up quickly from lying or sitting',
        id: '70821f94-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fatigue or irritability relieved by eating sweets',
        id: '708220a2-c211-11ed-afa1-0242ac120002',
      },
    ],

    mindAndEmotions: [
      {
        label: 'Poor Memory',
        id: '708221a6-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fear',
        id: '708222be-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Poor Concentration',
        id: '708223d6-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sadness or Grief',
        id: '708224da-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty in making decisions',
        id: '70822926-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Anger',
        id: '70822a48-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Mood Swings',
        id: '70822b56-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Shame',
        id: '70822c78-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Anxiety',
        id: '70822d7c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Guilt',
        id: '70822e8a-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Nervousness',
        id: '70822f98-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Self-Pity',
        id: '7082309c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Depression',
        id: '708233f8-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Loneliness',
        id: '70823506-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Panic Attacks',
        id: '70823614-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Meaninglessness',
        id: '70823718-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Mid-Life Crisis',
        id: '7082381c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hopelessness',
        id: '70823920-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Irritability or Moodiness',
        id: '70823a24-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Emptiness',
        id: '70823b32-c211-11ed-afa1-0242ac120002',
      },
    ],

    dental: [
      {
        label: 'Tooth or Gum Pain',
        id: '70823e0c-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Gingivitis',
        id: '70823f24-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Root Canals',
        id: '70824032-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Dental Implants',
        id: '70824136-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Mercury Amalgam Fillings',
        id: '70824244-c211-11ed-afa1-0242ac120002',
      },
    ],

    liverAndToxicity: [
      {
        label: 'Hypersensitivity to Odors',
        id: '70824348-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Trouble when smelling perfumes',
        id: '70824460-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sleep disturbance with coffee consumption after 6pm',
        id: '70824564-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Trouble with odors in shopping mall',
        id: '70824a1e-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Known Toxic Exposures',
        id: '70824b22-c211-11ed-afa1-0242ac120002',
      },
    ],

    other: [
      {
        label: 'Present in Moment',
        id: '70824bfe-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Happiness',
        id: '70824cda-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Ability to Accept',
        id: '70824db6-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Love',
        id: '70824e92-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Non-Attachment',
        id: '70824f78-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fun',
        id: '70825068-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Laughter',
        id: '70825388-c211-11ed-afa1-0242ac120002',
      },
    ],
  },
  M: {
    header: [
      { label: 'Name', id: '1111' },
      { label: 'DOB', id: '2222' },
      { label: 'Current Age', id: '3333' },
      { label: 'State', id: '4444' },
    ],

    section1: [
      { label: 'ht tall', id: '9619d498-c29a-11ed-afa1-0242ac120002' },
      { label: 'ht now', id: '9619d736-c29a-11ed-afa1-0242ac120002' },
      { label: 'wgt 25', id: '9619d736-c29a-11ed-afa1-0242ac120002' },
      { label: 'wgt now', id: '9619d736-c29a-11ed-afa1-0242ac120002' },
      { label: 'Comm relat', id: '9619ec62-c29a-11ed-afa1-0242ac120002' },
      { label: 'Children', id: '9619e85c-c29a-11ed-afa1-0242ac120002' },
    ],

    section2: [
      { label: 'Energy', id: '9619ce94-c29a-11ed-afa1-0242ac120002' },
      { label: 'Mood', id: '9619cf8e-c29a-11ed-afa1-0242ac120002' },
      { label: 'Sleep', id: '9619d09c-c29a-11ed-afa1-0242ac120002' },
      { label: 'Libido', id: '9619d196-c29a-11ed-afa1-0242ac120002' },
      { label: 'Cognition', id: '9619d290-c29a-11ed-afa1-0242ac120002' },
      { label: 'Pain free', id: '9619d39e-c29a-11ed-afa1-0242ac120002' },
    ],

    section3: [
      { label: 'Sexual Function', id: '' },
      { label: 'Physical Changes', id: '' },
      { label: 'Urologic Changes', id: '' },
      { label: 'Mental/Emotional', id: '' },
      {
        label: 'General Physical Changes',
        id: '',
      },
      { label: 'Metabolic Changes', id: '' },
    ],

    section4: [
      {
        label: 'Erectile Function',
        id: '9619f202-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Need for erection meds',
        id: '9619f306-c29a-11ed-afa1-0242ac120002',
      },

      { label: 'Muscle Strength', id: '9619f8b0-c29a-11ed-afa1-0242ac120002' },
      { label: 'Bladder Function', id: '9619f70c-c29a-11ed-afa1-0242ac120002' },
      {
        label: 'Currently T Rx',
        id: '9619f40a-c29a-11ed-afa1-0242ac120002',
      },
    ],
    mainSection: [
      { label: 'LFW', id: '9619a9b4-c29a-11ed-afa1-0242ac120002' },
      { label: 'CC', id: '9619aafe-c29a-11ed-afa1-0242ac120002' },
      { label: 'PMH', id: '9619ac34-c29a-11ed-afa1-0242ac120002' },
      { label: 'Wants', id: '9619ad9c-c29a-11ed-afa1-0242ac120002' },
      { label: 'Stress', id: '961a0bee-c29a-11ed-afa1-0242ac120002' },
      { label: 'Meds', id: '9619c71e-c29a-11ed-afa1-0242ac120002' },
      { label: 'PMH Meds', id: '9619c818-c29a-11ed-afa1-0242ac120002' },
      { label: 'Med Aller', id: '9619c912-c29a-11ed-afa1-0242ac120002' },
    ],

    mainSectionFooter: [
      { label: 'ETOH', id: '9619da60-c29a-11ed-afa1-0242ac120002' },
      { label: 'BMs/wk', id: '961a1386-c29a-11ed-afa1-0242ac120002' },
      { label: 'Occupation', id: '9619e62c-c29a-11ed-afa1-0242ac120002' },
      { label: 'snore', id: '961a0012-c29a-11ed-afa1-0242ac120002' },
      { label: 'Generally Happy', id: '961a068e-c29a-11ed-afa1-0242ac120002' },
    ],

    sexualFunction: [
      {
        label: '< Early am erection',
        id: '961a379e-c29a-11ed-afa1-0242ac120002',
      },
      { label: '< Libido', id: '961a3898-c29a-11ed-afa1-0242ac120002' },
      {
        label: '< Achieve erection',
        id: '961a39a6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Erection Turgidity',
        id: '961a3aa0-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Sustain Erection',
        id: '961a3ba4-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Strength of Orgasm',
        id: '961a3cbc-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Ejaculate Volume',
        id: '961a419e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Penile Sensation',
        id: '961a42d4-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Premature Ejaculation',
        id: '961a43d8-c29a-11ed-afa1-0242ac120002',
      },
      { label: 'Responds to PDE5', id: '961a44dc-c29a-11ed-afa1-0242ac120002' },

      {
        label: 'Tools to attain Erection',
        id: '961a45e0-c29a-11ed-afa1-0242ac120002',
      },
    ],

    physicalChanges: [
      {
        label: 'Achy Muscle Joints',
        id: '961a46e4-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Neck or Back Pains',
        id: '961a47e8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Strength, Stamina',
        id: '961a48e2-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Muscle Size',
        id: '961a4bee-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Athletic Agility',
        id: '961a4cfc-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Flexibility, Mobility',
        id: '961a4e00-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Slow Recovery',
        id: '961a515c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Effects fr Workouts',
        id: '961a529c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '> Strained Muscles',
        id: '961a53aa-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'S.O.B < Exertion',
        id: '961a54ae-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Competitive Drive',
        id: '961a55b2-c29a-11ed-afa1-0242ac120002',
      },
    ],

    urologicChanges: [
      {
        label: 'Enlarged Prostate BPH',
        id: '961a5918-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Urinary freq. < Flow',
        id: '961a5a26-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Nocturia',
        id: '961a5b2a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Non Med Rx, Saw Pain',
        id: '961a5c2e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Med Rx, Flomax etc',
        id: '961a5d32-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Prostatitis',
        id: '961a5e36-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Increased PSA',
        id: '961a5f44-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Rx for BPH',
        id: '961a6052-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'PCa, Vasectomy',
        id: '961a639a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hydrocele, Varicocele',
        id: '961a64da-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Infertility',
        id: '961a65fc-c29a-11ed-afa1-0242ac120002',
      },
    ],
    mentalAndEmotional: [
      {
        label: 'Pervasive fatigue',
        id: '961a261e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Depressed or Negative',
        id: '961a2a88-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Stressed or Burned out',
        id: '961a2ba0-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Irritable or Angry',
        id: '961a2cc2-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Anxiety, Panic attacks',
        id: '961a2dd0-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Forgetful, Poor Memory',
        id: '961a2ed4-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Brain Fog',
        id: '961a2fec-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Mental Sharpness',
        id: '961a3104-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Assertiveness',
        id: '961a323a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Motivation',
        id: '961a3578-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Pleasure, Significance',
        id: '961a3690-c29a-11ed-afa1-0242ac120002',
      },
    ],

    generalPhysicalChanges: [
      {
        label: 'Weight > or < ',
        id: '961a6714-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '> Beer belly',
        id: '961a6822-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '> Breast Fat',
        id: '961a693a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'HA or Migraine',
        id: '961a6a52-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Leg cramps/swelling',
        id: '961a6b60-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sleep dist, apnea, hf, ns',
        id: '961a6ec6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Emphysema/Asthma',
        id: '961a6fe8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Chrnc infl , colitis, ra',
        id: '961a7100-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Varcs veins, hmorhds or vrcocele',
        id: '961a7222-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Arthritis',
        id: '961a733a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '< Hair growth',
        id: '961a7448-c29a-11ed-afa1-0242ac120002',
      },
    ],
    metabolicChanges: [
      {
        label: '< Chol, Trig or < HDL',
        id: '961a7556-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: '> HBS, DM 2',
        id: '961a7664-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'HBP',
        id: '961a7a92-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'S.O.B, D.O.E',
        id: '961a7bbe-c29a-11ed-afa1-0242ac120002',
      },

      {
        label: '> P, Extra beats, AF',
        id: '961a7ce0-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Chest Pain, CAD',
        id: '961a7e34-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Heart Attack, Stent',
        id: '961a7f6a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'PMH TIA or stroke',
        id: '961a8096-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Lightheaded, dizzy',
        id: '961a81b8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Thyroid Problems',
        id: '961a82c6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Adrenal Gland Problems',
        id: '961a85a0-c29a-11ed-afa1-0242ac120002',
      },
    ],
    head: [
      {
        label: 'Headaches',
        id: '961a86b8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Dizziness',
        id: '961a87d0-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hair Thinning',
        id: '961a88e8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fainting',
        id: '961a8a00-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Convulsions',
        id: '961a8b2c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hair Loss',
        id: '961a8c44-c29a-11ed-afa1-0242ac120002',
      },
    ],

    eyes: [
      {
        label: 'Blurred Vision',
        id: '961a8d48-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Eye Pain',
        id: '961a919e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty in Vision',
        id: '961a931a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Double Vision',
        id: '961a9450-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Itchy Eyes',
        id: '961a9568-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Diminished Close-Up Vision',
        id: '961a9676-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Spots in Front of Eyes',
        id: '961a9784-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Do you wear glasses?',
        id: '961a98a6-c29a-11ed-afa1-0242ac120002',
      },
    ],

    ears: [
      {
        label: 'Earaches',
        id: '961a9d4c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Ear Infections',
        id: '961a9e96-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Ringing in Ears',
        id: '961a9fae-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hearing Loss',
        id: '961aa0c6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Itchy Ears',
        id: '961aa1de-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Change in Hearing',
        id: '961aa2ec-c29a-11ed-afa1-0242ac120002',
      },
    ],

    nose: [
      {
        label: 'Stuffy Nose',
        id: '961aa404-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Nasal Discharge',
        id: '961aa512-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Nosebleeds',
        id: '961aa81e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sinus Problems',
        id: '961aa936-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sinus Infections',
        id: '961aaa44-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Post Nasal Drip',
        id: '961aab52-c29a-11ed-afa1-0242ac120002',
      },
    ],

    allergy: [
      {
        label: 'Pollen Allergy',
        id: '961ab8ea-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hay Fever',
        id: '961ab9f8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Dust Allergy',
        id: '961abd18-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Asthma',
        id: '961abe3a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Frequent Sneezing',
        id: '961ac100-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Seasonal Sneezing',
        id: '961ac236-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Stuffy Nose After Eating',
        id: '961ac344-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Trouble going into shopping malls',
        id: '961ac45c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hypersensitivity to Medications',
        id: '961ac574-c29a-11ed-afa1-0242ac120002',
      },
    ],

    mouthAndThroat: [
      {
        label: 'Canker Sores',
        id: '961aac6a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Tooth Pain',
        id: '961aad82-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sore Gums',
        id: '961aae86-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Tooth Sensitivity',
        id: '961aaf94-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Bleeding Gums',
        id: '961ab278-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Coated Tongue',
        id: '961ab39a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty Swallowing',
        id: '961ab4a8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Breath Odor',
        id: '961ab5b6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sore Throat',
        id: '961ab6c4-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Do You Floss?',
        id: '961ab7dc-c29a-11ed-afa1-0242ac120002',
      },
    ],

    sleep: [
      {
        label: 'Difficulty in Sleeping',
        id: '961ac68c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Awaken in Night',
        id: '961ace98-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty Falling Asleep',
        id: '961acfec-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty Falling Back Asleep',
        id: '961ad0fa-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sleep Less than 7 Hours',
        id: '961ad208-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Five hours or less of sleep per night',
        id: '961ad316-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Work night or afternoon shift',
        id: '961ad424-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Heavy snoring or gasping',
        id: '961ad532-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Disturbing Dreams',
        id: '961ad640-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Overall Sleep Quality',
        id: '961ad91a-c29a-11ed-afa1-0242ac120002',
      },
    ],

    immuneSystem: [
      {
        label: 'Cold Sores in the Mouth',
        id: '961ada28-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Colds or other infections',
        id: '961adb36-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Known Allergies',
        id: '961adc4e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Swollen Glands',
        id: '961add66-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty Healing',
        id: '961ade88-c29a-11ed-afa1-0242ac120002',
      },
    ],

    cardiovascular: [
      {
        label: 'Skipped Heartbeat',
        id: '961adfa0-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Leg cramping on walking',
        id: '961ae34c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Rapid or pounding heartbeat',
        id: '961ae496-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Leg cramps at night',
        id: '961ae5b8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Palpitations',
        id: '961ae6d0-c29a-11ed-afa1-0242ac120002',
      },

      {
        label: 'Chest Pain',
        id: '961ae8ec-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Pain in legs when walking',
        id: '961ae9fa-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Irregular Heart Beat',
        id: '961aed7e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fluid Retention (Swelling)',
        id: '961af094-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Anemia',
        id: '961af1d4-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Dizzy Upon Standing',
        id: '961af332-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Varicose Veins',
        id: '961af45e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Bruise Easily',
        id: '961af56c-c29a-11ed-afa1-0242ac120002',
      },
    ],

    lungs: [
      {
        label: 'Cough',
        id: '961af6ac-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'History of Smoking',
        id: '961af828-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Shortness of breath during the day',
        id: '961af954-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Asthma',
        id: '961afcb0-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Shortness of breath at night',
        id: '961afde6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Bronchitis',
        id: '961aff12-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty Breathing',
        id: '961b0020-c29a-11ed-afa1-0242ac120002',
      },
    ],

    intestine: [
      {
        label: 'Nausea',
        id: '961b012e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Discomfort in Abdomen',
        id: '961b023c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Vomiting',
        id: '961b035e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Foods you have trouble with',
        id: '961b0476-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Bloated Feeling',
        id: '961b0840-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fatigue or anxiety relieved by sweets',
        id: '961b0962-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Burning in Stomach',
        id: '961b0a7a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Indigestion 1-2 hours after eating',
        id: '961b0b7e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Heartburn',
        id: '961b0c96-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fullness long after meals',
        id: '961b0dae-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Pain in Abdomen',
        id: '961b0eb2-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sleeping After Meals',
        id: '961b0fd4-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Diarrhea',
        id: '7081bfae-c211-11ed-afa1-0242ac120002',
      },
      {
        label: 'Nails bend or break easily',
        id: '961b127c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Constipation',
        id: '961b1498-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Blood in Stool',
        id: '961b159c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Excessive Belching',
        id: '961b16a0-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Black Stool',
        id: '961b17c2-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Excessing passing of gas',
        id: '961b18d0-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Anal Itch',
        id: '961b19e8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Indigestion',
        id: '961b1d80-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Pain on Defecation',
        id: '961b1e98-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Craving Sweets',
        id: '961b1fa6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hemorrhoids',
        id: '961b20c8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hepatitis',
        id: '961b21cc-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Goosebumps on back of arms',
        id: '961b22e4-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Gallstones',
        id: '961b2406-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty with oily foods',
        id: '961b251e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Nausea Upon Eating',
        id: '961b2884-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty with Dairy',
        id: '961b29a6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Change in Appetite',
        id: '961b2ab4-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty with Wheat',
        id: '961b2bcc-c29a-11ed-afa1-0242ac120002',
      },
    ],

    urinaryTract: [
      {
        label: 'Burning or Pain on Urination',
        id: '961b2cd0-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Frequency of Urination',
        id: '961b2de8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Bladder Infections',
        id: '961b2ef6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Urgency of Urination',
        id: '961b300e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Kidney Infections',
        id: '961b32e8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fluid Retention (Swelling)',
        id: '961b33f6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Up at night to urinate',
        id: '961b34fa-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Kidney Stones',
        id: '961b3608-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Blood in Urine',
        id: '961b370c-c29a-11ed-afa1-0242ac120002',
      },
    ],

    skin: [
      {
        label: 'Pimples or Acne',
        id: '961b381a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Oily Skin',
        id: '961b3932-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Dry Skin',
        id: '961b3c8e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hives',
        id: '961b3dd8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Rashes',
        id: '961b3ee6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Skin Itch',
        id: '961b3ff4-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sweating',
        id: '961b410c-c29a-11ed-afa1-0242ac120002',
      },
    ],

    weight: [
      {
        label: 'Compulsive or Binge Eating',
        id: '961b421a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Craving Certain Foods',
        id: '961b4332-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sweet Craving',
        id: '961b465c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Weight Loss',
        id: '961b4774-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Excessive Weight',
        id: '961b4882-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Weight Gain',
        id: '961b49a4-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Underweight',
        id: '961b4aa8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Inability to Gain Weight',
        id: '961b4bc0-c29a-11ed-afa1-0242ac120002',
      },
    ],

    energy: [
      {
        label: 'Fatigue in General',
        id: '961b4cd8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Awaken Energetic, Fatigue Easily',
        id: '961b50de-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hyperactivity',
        id: '961b5250-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Awaken sluggish, improve with day',
        id: '961b5368-c29a-11ed-afa1-0242ac120002',
      },
    ],

    hormonal: [
      {
        label: 'Mid-Life Weight Gain',
        id: '961b548a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Eyes Sensitive to Bright Light',
        id: '961b5598-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Cold Intolerance',
        id: '961b56a6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Irritable When Hungry',
        id: '961b57aa-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Swelling Under Eyes',
        id: '961b5a70-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Feel Better After Exercise',
        id: '961b5b88-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sleep Disturbance',
        id: '961b5ca0-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Cold Hands and Feet',
        id: '961b5dae-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Loss of muscle mass or strength',
        id: '961b5ebc-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Uncomfortable in Cold',
        id: '961b5fca-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sweet Craving',
        id: '961b60e2-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Uncomfortable in Heat',
        id: '961b61f0-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fatigue Easy',
        id: '961b6592-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Dizzy upon standing up quickly from lying or sitting',
        id: '961b66aa-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fatigue or irritability relieved by eating sweets',
        id: '961b67c2-c29a-11ed-afa1-0242ac120002',
      },
    ],

    mindAndEmotions: [
      {
        label: 'Poor Memory',
        id: '961b68e4-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fear',
        id: '961b69f2-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Poor Concentration',
        id: '961b6b00-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sadness or Grief',
        id: '961b6c0e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Difficulty in making decisions',
        id: '961b6d1c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Anger',
        id: '961b706e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Mood Swings',
        id: '961b7186-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Shame',
        id: '961b729e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Anxiety',
        id: '961b73b6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Guilt',
        id: '961b74ce-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Nervousness',
        id: '961b75dc-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Self-Pity',
        id: '961b7866-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Depression',
        id: '961b7b18-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Loneliness',
        id: '961b7c30-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Panic Attacks',
        id: '961b7d3e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Meaninglessness',
        id: '961b7e42-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Mid-Life Crisis',
        id: '961b7f5a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Hopelessness',
        id: '961b805e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Irritability or Moodiness',
        id: '961b8162-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Emptiness',
        id: '961b848c-c29a-11ed-afa1-0242ac120002',
      },
    ],

    dental: [
      {
        label: 'Tooth or Gum Pain',
        id: '961b859a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Gingivitis',
        id: '961b86a8-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Root Canals',
        id: '961b89aa-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Dental Implants',
        id: '961b8ae0-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Mercury Amalgam Fillings',
        id: '961b8bee-c29a-11ed-afa1-0242ac120002',
      },
    ],

    liverAndToxicity: [
      {
        label: 'Hypersensitivity to Odors',
        id: '961b8d06-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Trouble when smelling perfumes',
        id: '961b906c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Sleep disturbance with coffee consumption after 6pm',
        id: '961b9184-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Trouble with odors in shopping mall',
        id: '961b929c-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Known Toxic Exposures',
        id: '961b9418-c29a-11ed-afa1-0242ac120002',
      },
    ],

    other: [
      {
        label: 'Present in Moment',
        id: '961b954e-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Happiness',
        id: '961b9666-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Ability to Accept',
        id: '961b9774-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Love',
        id: '961b9c6a-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Non-Attachment',
        id: '961b9de6-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Fun',
        id: '961b9f08-c29a-11ed-afa1-0242ac120002',
      },
      {
        label: 'Laughter',
        id: '961ba02a-c29a-11ed-afa1-0242ac120002',
      },
    ],
  },
};
