import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { FaMapLocation } from 'react-icons/fa6';
import { useOutletContext } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { CheckBox } from 'components/common/CheckBox/CheckBox';
import { Typography } from 'components/common/Typography/Typography';
import { AddLicenseDeaModal } from 'components/profile/AddLicenseDea/AddLicenseDeaModal';
import { useProfile } from 'hooks/useProfile';
import { AddressType } from 'types/onboard';
import { PatientDetailsContextType } from 'types/patient';

import { AddPatientShippingAddressModal } from '../AddPatientShippingAddressModal/AddPatientShippingAddressModal';

type PharmacyNotesProps = {
  shippingAddress?: AddressType & { id: string };

  patientId: string;

  showNoteUI: boolean;
  onNoteSelect: (n: string) => void;
  outOfStateCheckbox: boolean;
  setOutOfStateCheckbox: Dispatch<SetStateAction<boolean>>;
};

// const resultSize = 100;

export function ShippingAddressCard({
  shippingAddress,
  patientId,

  showNoteUI,
  onNoteSelect,
  outOfStateCheckbox,
  setOutOfStateCheckbox,
}: PharmacyNotesProps) {
  const { address } = useOutletContext<PatientDetailsContextType>();

  const { profile } = useProfile();
  const [patientShippingAddressModal, setPatientShippingAddressModal] =
    useState(false);

  const [showAddLicenseDEAModal, setShowAddLicenseDEAModal] = useState(false);

  // const getPharmacyMessagesQuery = usePaginatedQuery(
  //   QueryKeys.PharmacyMessages.listing({
  //     size: resultSize,
  //   }),
  //   ({ pageParam = 1 }) =>
  //     UserService.getPharmacyMessage({
  //       size: resultSize,
  //       page: pageParam,
  //       noteType: 'rx_order',
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //     retry: false,
  //   }
  // );

  // eslint-disable-next-line
  console.log(showNoteUI, onNoteSelect);

  // const notes = getPharmacyMessagesQuery.data?.pages.map((note) => ({
  //   title: note.message,
  //   value: note.message,
  // }));

  const sameStatePrescription = useMemo(
    () =>
      profile?.providerDetails?.providerLicence?.some(
        (p) => p.stateId === address?.state?.id
      ),
    [address?.state?.id, profile?.providerDetails?.providerLicence]
  );

  return (
    <div className=" flex w-full  grow flex-col gap-4 rounded bg-background-main  p-6 ">
      {' '}
      <Typography variant="h4">Shipping Address</Typography>
      <div
        className={classNames(
          'mt-4 flex w-full items-center gap-4 rounded bg-gray-600 py-3 px-4 !text-lg !font-normal ',
          {
            '!bg-error-main !bg-opacity-70': !shippingAddress?.id,
          }
        )}>
        {' '}
        {shippingAddress?.id ? (
          <FaMapLocation className="mr-1 h-7 w-7  font-extrabold text-background-contrastText " />
        ) : (
          <ExclamationCircleIcon className="h-8 w-8  font-extrabold text-background-contrastText " />
        )}
        <div className="flex flex-col gap-1">
          <Typography className="" variant="h4" color="white">
            {shippingAddress?.id
              ? `${`${shippingAddress?.addressLine1}, ${`${
                  shippingAddress?.addressLine2
                    ? `${shippingAddress?.addressLine2},`
                    : ''
                }`} ${shippingAddress?.city}, ${shippingAddress?.state?.name} ${
                  shippingAddress?.zipCode
                }, ${shippingAddress?.country}.`}`
              : 'Shipping address is not provided by the patient.'}
          </Typography>
          <div>
            <Button
              onClick={() => setPatientShippingAddressModal(true)}
              className="">
              {shippingAddress?.id ? 'Update' : 'Add'}
            </Button>
          </div>
        </div>
      </div>
      {!sameStatePrescription && (
        <div className="mt-2  flex items-center gap-4 rounded bg-error-main bg-opacity-50 px-4 py-3 text-background-contrastText">
          <CheckBox
            className="ml-1"
            checked={outOfStateCheckbox}
            id=""
            label=""
            onChange={(d) => {
              setOutOfStateCheckbox(d);
            }}
          />

          <Typography color="white" variant="subtitle2">
            I am aware that this prescription is shipping to a state where I am
            not licensed as a provider. I have seen this patient in accordance
            with all state and federal laws including seeing this patient in
            person as many times as is required.
          </Typography>
        </div>
      )}
      {/* <div className="  flex flex-col gap-4  ">
        {!matchDeaAndLicense && (
          <WarningText className="mb-2">
            {shippingAddress?.id ? (
              <div className="flex flex-col">
                Your patient has provided a shipping address in a state where
                you do not appear to hold a provider&apos;s license and/or a DEA
                registration certificate. Please provide the reasons for this
                and include any detail (such as date of return) that will
                substantiate why the pharmacy can ship to this location and
                abide by the laws governing out-of-state prescriptions.
                <div>
                  <Button
                    onClick={() => setShowAddLicenseDEAModal(true)}
                    className="mt-2  ">
                    Add DEA/License
                  </Button>
                </div>
              </div>
            ) : (
              'You won’t be able to place an order without shipping address. Please ask your patient to submit shipping address from the mobile application.'
            )}
          </WarningText>
        )}
      </div> */}
      {/* {showNoteUI && (
        <div className="-mt-4">
          <WarningText>
            {' '}
            The patient&apos;s driver&apos;s license state doesn&apos;t match
            the shipping state. Please choose the reason for the mismatch.
          </WarningText>
          <DropDown
            className="mt-2"
            placeholder="Please select reason"
            fullWidth
            options={notes}
            onChange={(n) => onNoteSelect(n.value as string)}
          />{' '}
        </div>
      )} */}
      <AddLicenseDeaModal
        showAddModal={showAddLicenseDEAModal}
        handleClose={() => {
          setShowAddLicenseDEAModal(false);
        }}
        patientStateId={shippingAddress?.state?.id}
      />
      <AddPatientShippingAddressModal
        patientId={patientId}
        updating={!!shippingAddress?.id}
        onModalClose={() => {
          setOutOfStateCheckbox(false);
          setPatientShippingAddressModal(false);
        }}
        open={patientShippingAddressModal}
        addressDetail={{
          addressLine1: shippingAddress?.addressLine1 ?? '',
          addressLine2: shippingAddress?.addressLine2 ?? '',
          city: shippingAddress?.city ?? '',
          state: shippingAddress?.state,
          zipCode: shippingAddress?.zipCode,
        }}
      />
    </div>
  );
}
