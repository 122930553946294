import React, { useEffect } from 'react';

import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { useChat } from 'context/ChatProvider';
import { useSocket } from 'hooks/useSocket';

import { ChatWindow } from '../ChatWindow/ChatWindow';
import { PatientsChatsList } from '../PatientsList/PatientsList';

export function ChatApp() {
  const socketMethods = useSocket();
  const { patientsList, setSocketMethods, selectedUser } = useChat();
  const navigate = useNavigate();

  const lastGroupIdBeforePageUnload = localStorage.getItem(
    'lastGroupIdBeforePageUnload'
  );

  useEffect(() => {
    if (socketMethods.connection) {
      socketMethods.getPatientsList({ size: 10 });
      if (socketMethods) {
        setSocketMethods(socketMethods);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketMethods.connection]);

  useEffect(() => {
    if (
      socketMethods.connection &&
      lastGroupIdBeforePageUnload &&
      selectedUser.groupId !== lastGroupIdBeforePageUnload
    ) {
      socketMethods.leaveGroup(lastGroupIdBeforePageUnload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketMethods.connection]);

  const handleGoBack = () => {
    socketMethods.leaveGroup(selectedUser.groupId || '');
    navigate('/app/patients');
  };

  return (
    <>
      <div className="my-4 flex items-center">
        <Button
          onClick={() => handleGoBack()}
          className="!px-0 !pl-1"
          variant="contained">
          <div className="flex items-center px-2">
            <ArrowLeftIcon className=" clh-6 mr-1  w-4 text-background-contrastText " />
            <Typography variant="subtitle2">Go Back</Typography>
          </div>
        </Button>
      </div>
      <div className="mt-1 flex h-[calc(100vh-10rem)] flex-col  rounded bg-background-main md:flex-row ">
        <div className="h-[calc(100vh-10rem)] w-full border-black md:w-1/3 md:border-r">
          <PatientsChatsList usersData={patientsList || []} />
        </div>
        <div className="w-full flex-1 md:w-3/4">
          <ChatWindow />
        </div>
      </div>
    </>
  );
}
