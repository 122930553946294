/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useEffect, useRef, useState } from 'react';

import { format, isToday } from 'date-fns';

import { SearchInput } from 'components/common/SearchInput/SearchInput';
import { Typography } from 'components/common/Typography/Typography';
import { Avatar } from 'components/general/Avatar/Avatar';
import { useChat } from 'context/ChatProvider';
import { useThrottle } from 'hooks/useThrottle';
import { ChatUsersListObject } from 'types/socket/types';
import { getUsersTimeFromUTC } from 'utils/common';

import { PatientListSkeleton } from '../PatientListSkeleton/PatientListskeleton';

export function PatientsChatsList({
  usersData,
}: {
  usersData: ChatUsersListObject[];
}) {
  const { selectedUser, setSelectedUser, resetChats, socketMethods } =
    useChat();
  const listRef = useRef<HTMLDivElement>(null);

  const [filteredUsers, setFilteredUsers] = useState(usersData);

  const fetchMoreData = useCallback(async () => {
    const size = usersData.length + 20;

    socketMethods?.getPatientsList({ size, silentFetch: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketMethods, usersData.length]);

  const throttledFetchMoreData = useThrottle(fetchMoreData, 300);
  const handleScroll = useCallback(() => {
    const list = listRef.current;
    if (list) {
      const { scrollTop, scrollHeight, clientHeight } = list;
      if (scrollHeight - scrollTop <= clientHeight + 50) {
        throttledFetchMoreData();
      }
    }
  }, [throttledFetchMoreData]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const list = listRef.current;
    if (list) {
      list.addEventListener('scroll', handleScroll);

      return () => {
        list.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    setFilteredUsers(usersData);
  }, [usersData]);

  const openChat = async (user: ChatUsersListObject) => {
    await socketMethods?.enterGroup(user.groupId || '');
    localStorage.setItem('lastGroupIdBeforePageUnload', user.groupId || '');
    await socketMethods?.openChatHistory({ groupId: user.groupId || '' });

    if (user.unreadMessagesCount > 0) {
      socketMethods?.getPatientsList({ size: 20, silentFetch: true });
    }
  };

  const handleUserSelection = async (user: ChatUsersListObject) => {
    await resetChats();
    if (selectedUser.groupId && user.groupId !== selectedUser.groupId) {
      await socketMethods?.leaveGroup(selectedUser.groupId);
    }

    setSelectedUser(user);

    openChat(user);
  };

  const handleSearch = (searchString: string) => {
    const filtered = usersData.filter((user) =>
      user.patientName.toLowerCase().includes(searchString.toLowerCase().trim())
    );
    if (filtered.length === 0) {
      socketMethods?.getPatientsList({ size: 10, search: searchString });
    } else if (searchString === '') {
      socketMethods?.getPatientsList({ size: 20 });
    }
    setFilteredUsers(filtered);
  };

  return (
    <div className="h-[calc(100vh-10rem)]">
      <div className="p-3">
        <SearchInput
          fullWidth
          onSearch={(text) => {
            handleSearch(text);
          }}
        />
      </div>
      <div
        ref={listRef}
        className="custom-scrollbar h-[calc(100vh-15rem)] overflow-y-auto">
        {!socketMethods?.isFetchingPatients && filteredUsers.length ? (
          filteredUsers.map((user) => (
            <>
              <div
                key={user.patientId}
                onClick={() => {
                  handleUserSelection(user);
                }}
                className={`cursor-pointer   p-6 hover:my-0 hover:bg-white hover:bg-opacity-10 hover:py-6 ${
                  selectedUser.patientId === user.patientId
                    ? 'my-0 rounded-none bg-white bg-opacity-10 py-6'
                    : ''
                } `}>
                <div className="flex w-full flex-row  items-center justify-between">
                  <div className="flex flex-row items-center">
                    <Avatar
                      imgUrl={user.profilePicture || ''}
                      userName={user.patientName}
                      sizeClass="w-8 h-8 mr-2"
                    />
                    <Typography
                      variant="subtitle2"
                      // color={
                      //   selectedUser.patientId === user.patientId
                      //     ? 'textSecondary'
                      //     : 'white'
                      // }
                      className="w-50 overflow-hidden text-ellipsis whitespace-nowrap">
                      {user.patientName}
                    </Typography>
                  </div>

                  <div className="flex items-center">
                    {user.unreadMessagesCount > 0 &&
                    user.groupId !== selectedUser.groupId ? (
                      <div className="flex  flex-col  items-center justify-end  p-2">
                        <div className=" mb-0 flex h-6 w-6 flex-row justify-center rounded-full bg-primary-main">
                          <Typography variant="subtitle3">
                            {user.unreadMessagesCount}
                          </Typography>
                        </div>
                      </div>
                    ) : null}
                    <Typography
                      variant="subtitle3"
                      className="flex justify-end">
                      {isToday(
                        new Date(
                          getUsersTimeFromUTC(user.lastMessage || '')
                            ?.localDateOnly || ''
                        )
                      )
                        ? getUsersTimeFromUTC(user.lastMessage || '')
                            ?.localTimeOnly
                        : format(
                            new Date(
                              getUsersTimeFromUTC(user.lastMessage || '')
                                ?.localDateOnly || ''
                            ),
                            'MM-dd-yyyy'
                          )}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="h-0.5 rounded-lg bg-black bg-opacity-40" />
            </>
          ))
        ) : usersData.length === 0 && !socketMethods?.isFetchingPatients ? (
          <div className="mt-2 flex justify-center">
            <Typography variant="subtitle2">No result found</Typography>
          </div>
        ) : (
          <div className="p-4">
            <PatientListSkeleton />
          </div>
        )}
      </div>
    </div>
  );
}
