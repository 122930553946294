// eslint-disable-next-line import/extensions
import message from 'assets/images/message.svg';
import { Typography } from 'components/common/Typography/Typography';

export function NoChatScreen() {
  return (
    <div className="align-center flex flex-col items-center justify-center">
      <img
        src={message}
        width={100}
        alt="Message icon"
        className="-mt-12 mb-5"
      />
      <Typography variant="subtitle2">
        Send a message to start the conversation...
      </Typography>
    </div>
  );
}
