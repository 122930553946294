/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line import/extensions

// eslint-disable-next-line
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line import/extensions
import ChatIcon from 'assets/images/chat-icon.svg';
import { AvatarDropdown } from 'components/general/AvatarDropdown/AvatarDropdown';
import { useSocketProvider } from 'context/SocketProvider/SocketProvider';
import { useProfile } from 'hooks/useProfile';

import briteLogo from '../../../assets/images/LOGO.png';

export function Header() {
  const { profile } = useProfile();
  const navigate = useNavigate();
  const socket = useSocketProvider();
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  useEffect(() => {
    setHasUnreadMessages(!!socket?.hasUnreadMessages);
  }, [socket?.hasUnreadMessages]);

  return (
    <div className="w-full  rounded bg-background-main  py-2">
      <div className="flex items-center justify-between px-8">
        <figure className="relative h-[2.5rem] w-max max-w-[8rem]  ">
          <img
            className="h-full object-contain"
            src={profile?.practice?.logoUrl || briteLogo}
            alt={profile?.practice?.name}
          />
        </figure>
        <div className="relative flex !cursor-pointer items-center space-x-4 ">
          {/* <SwitchDarkMode /> */}

          <div className="flex !cursor-pointer items-center space-x-4 rounded p-1 hover:bg-[#363636] ">
            {hasUnreadMessages ? (
              <div className="absolute left-9 top-1 h-2 w-2 rounded-full bg-[#FF0000]" />
            ) : null}
            <img
              src={ChatIcon}
              alt="Chat"
              onClick={() => navigate('/app/chat')}
              className=""
            />
          </div>

          <AvatarDropdown />
        </div>
      </div>
    </div>
  );
}
