import React, { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { Button } from 'components/common/Button/Button';
import { CheckBox } from 'components/common/CheckBox/CheckBox';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { invitePatientSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { useProfile } from 'hooks/useProfile';
import { PatientService } from 'services/patient';

type InvitePatientProps = {
  openModal: boolean;
  onModalClose: () => void;
  onInviteResendSuccess: () => void;
  resendInviteData?: {
    id: string;
    email: string;
  };
};
type InvitePatientFormType = {
  email: string;
  name: string;
  phone?: string;
};

export function InvitePatientModal({
  openModal,
  onInviteResendSuccess,
  onModalClose,
  resendInviteData,
}: InvitePatientProps) {
  const { profile } = useProfile();
  const [alreadyOnHormone, setAlreadyOnHormone] = useState(false);
  const [noQuestionnaire, setNoQuestionnaire] = useState(false);
  const [smsConsent, setSmsConsent] = useState(false);
  const queryClient = useQueryClient();
  const inviteMutation = useMutationWithToast(PatientService.Invite, {});
  const resendInviteMutation = useMutationWithToast(
    PatientService.resendInvite
  );
  const { handleSubmit, control, reset, setValue } =
    useFormWithErrors<InvitePatientFormType>({
      mutation: inviteMutation,
      schema: invitePatientSchema,
    });

  const isLoading = inviteMutation.isLoading || resendInviteMutation.isLoading;

  useEffect(() => {
    setValue('email', resendInviteData?.email as string);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendInviteData?.email]);

  function onSuccess() {
    reset();
    onInviteResendSuccess();
    queryClient.refetchQueries(QueryKeys.InvitedPatients.listings());
    onCloseClick();
  }

  const onSubmit = handleSubmit((data) => {
    if (resendInviteData) {
      resendInviteMutation.mutate(
        { name: data.name, id: resendInviteData?.id },
        {
          onSuccess: () => {
            onSuccess();
          },
        }
      );

      return;
    }

    inviteMutation.mutate(
      {
        email: data.email,
        name: data.name,
        ...(!!data?.phone && { phone: `+${data.phone}` }),
        ...(!!alreadyOnHormone && { alreadyOnHormone }),
        ...(!!noQuestionnaire && { noQuestionnaire }),
        optInSms: smsConsent,
        providerId: profile?.id as string,
        practiceId: profile?.practice.id ?? '',
        practiceAddressId: profile?.practiceAddress.id ?? '',
      },
      {
        onSuccess: () => {
          onSuccess();
        },
      }
    );
  });

  const onCloseClick = () => {
    if (isLoading) return;
    onModalClose();
    setAlreadyOnHormone(false);
    setNoQuestionnaire(false);
    setSmsConsent(false);
    reset();
  };

  return (
    <div>
      <Modal
        title={resendInviteData ? 'Resend Invitation' : 'Invite Patient'}
        open={openModal}
        className="w-2/5"
        closeModal={() =>
          isLoading
            ? null
            : () => {
                setAlreadyOnHormone(false);
                setNoQuestionnaire(false);
                onCloseClick();
              }
        }>
        <div className="mt-4">
          {!resendInviteData ? (
            <Typography variant="subtitle3" className="mb-4">
              Please provide the email of the patient you want to invite.
              Patient will receive the app as well as instructions to get them
              started.
            </Typography>
          ) : (
            <Typography variant="subtitle3" className="mb-4 ">
              <div>
                Please provide the name of the patient to resend the invite. An
                invite will be sent at
                <Typography color="primary">
                  {resendInviteData?.email}
                </Typography>
              </div>
            </Typography>
          )}

          <TextInput
            className="w-2/3 pl-2"
            type="text"
            name="name"
            label="Name"
            control={control}
            fullWidth
            placeholder="Name"
          />

          {!resendInviteData && (
            <TextInput
              className="w-2/3 pl-2"
              fullWidth
              type="text"
              label="Email"
              name="email"
              control={control}
              disabled={!!resendInviteData}
              helperText="Please use the patient’s personal email & avoid using the corporate emails for patient accounts."
              placeholder="Email"
            />
          )}
          {!resendInviteData && (
            <div className="mt-4 w-2/3 ">
              <TextInput
                control={control}
                name="phone"
                label="Phone"
                type="tel"
                placeholder="Mobile number"
                fullWidth
              />
            </div>
          )}

          {!resendInviteData && (
            <div className="pl-1">
              <CheckBox
                checked={smsConsent}
                onChange={(v) => {
                  setSmsConsent(v);
                }}
                id="smsConsent"
                label="Do you have the consent of your patient to send the invitation over SMS ?"
              />
              <CheckBox
                className="mt-1"
                checked={alreadyOnHormone}
                onChange={(v) => {
                  setAlreadyOnHormone(v);
                }}
                id="Is this patient already on hormones?"
                label="Is this patient already on hormones?"
              />
              <CheckBox
                className="mt-1"
                checked={noQuestionnaire}
                onChange={(v) => {
                  setNoQuestionnaire(v);
                }}
                id="Do not require initial questionnaire"
                label="Do not require initial questionnaire."
              />
            </div>
          )}

          {!resendInviteData ? (
            <Typography variant="subtitle3" className="mt-4">
              If patient does not receive the invitation, you may resend from
              your patient dashboard. Double-check address before resubmitting.
            </Typography>
          ) : null}
          <div>
            <div className="mt-6 flex justify-end">
              <Button
                color="secondary"
                variant="contained"
                disabled={isLoading}
                onClick={() => {
                  setAlreadyOnHormone(false);
                  setNoQuestionnaire(false);
                  onCloseClick();
                }}>
                Close
              </Button>{' '}
              <span className="mx-2" />
              <Button
                onClick={onSubmit}
                loading={
                  inviteMutation.isLoading || resendInviteMutation.isLoading
                }>
                {resendInviteData ? 'Resend Invite' : 'Send Invite'}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
