import { ChatApp } from 'components/chat/ChatApp/ChatApp';
import { ChatProvider } from 'context/ChatProvider';

export function ChatContainer() {
  return (
    <ChatProvider>
      <ChatApp />
    </ChatProvider>
  );
}
