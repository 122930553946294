import React from 'react';

import classNames from 'classnames';

import { Chip } from 'components/common/Chip/Chip';
import { InfoTooltip } from 'components/common/InfoTooltip/InfoTooltip';
import { Typography } from 'components/common/Typography/Typography';
import { DeliveryMethodsEnum } from 'constants/delivery-methods';
import {
  PatientPrescription,
  PrescriptionCheckType,
} from 'types/patient/prescription';
import { formatDeliveryUnit } from 'utils/common';

import HormoneImage from '../../../assets/images/britebox.png';
import CapsuleSvg from '../../../assets/images/capsule.svg';
import InjectionSvg from '../../../assets/images/injection.svg';

type PrescriptionDetailsValueProps = {
  prescription: PatientPrescription;
  isTopicalHormone: boolean;
  maxDoseCheckPrescriptionValue?: PrescriptionCheckType;
  minDoseCheckPrescriptionValue?: PrescriptionCheckType;
};

export function PrescriptionDetailsValue({
  prescription,
  isTopicalHormone,
  maxDoseCheckPrescriptionValue,
  minDoseCheckPrescriptionValue,
}: PrescriptionDetailsValueProps) {
  function getHormoneImage(deliveryMethod: DeliveryMethodsEnum) {
    switch (deliveryMethod.toLowerCase()) {
      case DeliveryMethodsEnum.OralSyringe.toLowerCase():
        return <img className="h-80" src={HormoneImage} alt="hormone bottle" />;

      case DeliveryMethodsEnum.Capsules.toLowerCase():
      case DeliveryMethodsEnum.Tablets.toLowerCase():
        return (
          <img
            style={{ background: '#FFFFFF' }}
            className="h-80 !w-96 p-20"
            src={CapsuleSvg}
            alt="capsule/tablet"
          />
        );

      case DeliveryMethodsEnum.Vial.toLowerCase():
      case DeliveryMethodsEnum.Syringe.toLowerCase():
        return (
          <img
            style={{ background: '#FFFFFF' }}
            className="h-80 !w-96 p-20"
            src={InjectionSvg}
            alt="capsule/tablet"
          />
        );

      default:
        return '';
    }
  }

  function getPatientDoseFormattedValue(
    doseValue?: string,
    prescriptionCheckValue?: string
  ) {
    return doseValue ? (
      <div className="flex flex-col">
        <span>
          {isTopicalHormone ? Number(doseValue).toFixed(2) : doseValue}{' '}
          {isTopicalHormone
            ? prescription.deliveryMethodAndSig.dose.unit
            : formatDeliveryUnit(
                Number(doseValue),
                prescription.deliveryMethodAndSig.dose.unit
              )}
        </span>{' '}
        {isTopicalHormone && (
          <span>
            {prescriptionCheckValue} {maxDoseCheckPrescriptionValue?.unit}
          </span>
        )}
      </div>
    ) : (
      '-'
    );
  }

  return (
    <div>
      {' '}
      <div className="mt-2">
        <Typography variant="h4">
          Prescription Id :{' '}
          <span className="px-1 text-primary-main">{prescription?.id}</span>
          {prescription?.titrationType ? (
            <Chip className="mx-1" label={prescription?.titrationType} />
          ) : (
            ''
          )}
        </Typography>
      </div>
      <div className={classNames('mt-2 flex  gap-20')}>
        {' '}
        <div className="w-96">
          {getHormoneImage(
            prescription.deliveryMethodAndSig
              .deliveryMethod as DeliveryMethodsEnum
          )}
        </div>
        <div className=" flex flex-col gap-4">
          <Typography color="textPrimary" className="" variant="h4">
            {prescription?.standardRepresentation}{' '}
          </Typography>

          <div>
            <Typography
              variant="subtitle2"
              color="textMuted"
              className="wrap  !flex !items-start">
              SIG{' '}
            </Typography>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className="wrap  !flex !items-start">
              {prescription?.deliveryMethodAndSig.sig}
            </Typography>
          </div>
          <div>
            <Typography
              variant="subtitle2"
              color="textMuted"
              className="wrap  !flex !items-start">
              DTD
              <InfoTooltip id="dtd" label="DTD is the dispense quantity" />
            </Typography>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className="wrap  !flex !items-start">
              {prescription?.dtd.value
                ? `${prescription?.dtd.value} ${prescription?.dtd.unit}`
                : 'NA'}{' '}
              {}
            </Typography>
          </div>
          <div className="flex gap-16">
            <div className="flex flex-col gap-6">
              {' '}
              <div className="min-h-[2rem]">
                <Typography
                  variant="subtitle2"
                  color="textMuted"
                  className="wrap  !flex !items-start">
                  Days of Supply (Most)
                  <InfoTooltip
                    id="MaxDaysOfSupply"
                    label="This is the maximum number of days this prescription should last. It is derived by dividing DTD by minimum dose."
                  />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className="wrap  !flex !items-start">
                  {minDoseCheckPrescriptionValue?.daysOfSupply
                    ? `${minDoseCheckPrescriptionValue?.daysOfSupply} Days`
                    : '-'}
                </Typography>
              </div>
              <div className="min-h-[2rem]">
                <Typography
                  variant="subtitle2"
                  color="textMuted"
                  className="wrap  !flex !items-start">
                  Min Dose
                  <InfoTooltip
                    id="MinimumDosage"
                    label="The minimum dose prescribed by the provider while creating this prescription."
                  />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className="wrap  !flex !items-start">
                  {getPatientDoseFormattedValue(
                    prescription.deliveryMethodAndSig?.minDailyDose?.value ??
                      prescription.deliveryMethodAndSig?.dose?.value,
                    minDoseCheckPrescriptionValue?.value
                  )}
                </Typography>
              </div>
              <div className="min-h-[2rem]">
                <Typography
                  variant="subtitle2"
                  color="textMuted"
                  className="wrap  !flex !items-start">
                  Prescribed Refills{' '}
                  <InfoTooltip
                    id="PrescribedRefills"
                    label="The number of refills prescribed while creating this prescription."
                  />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className="wrap  !flex !items-start">
                  {prescription.refills}
                </Typography>
              </div>
            </div>
            <span className=" h-full w-[2px] bg-background-contrastText " />
            <div className="flex flex-col gap-6">
              {' '}
              <div className="min-h-[2rem]">
                <Typography
                  variant="subtitle2"
                  color="textMuted"
                  className="wrap  !flex !items-start">
                  Days of Supply (Least)
                  <InfoTooltip
                    id="MinDaysOfSupply"
                    label="This is the min number of days this prescription should last. It is derived by dividing DTD by maximum dose."
                  />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className="wrap  !flex !items-start">
                  {maxDoseCheckPrescriptionValue?.daysOfSupply
                    ? `${maxDoseCheckPrescriptionValue?.daysOfSupply} Days`
                    : '-'}
                </Typography>
              </div>
              <div className="min-h-[2rem]">
                <Typography
                  variant="subtitle2"
                  color="textMuted"
                  className="wrap  !flex !items-start">
                  Max dose
                  <InfoTooltip
                    id="MaximumDosage"
                    label="The maximum dose prescribed by the provider while creating this prescription."
                  />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className="wrap  !flex !items-start">
                  {getPatientDoseFormattedValue(
                    prescription.deliveryMethodAndSig?.dose?.value,
                    maxDoseCheckPrescriptionValue?.value
                  )}
                </Typography>
              </div>
              <div className="min-h-[2rem]">
                <Typography
                  variant="subtitle2"
                  color="textMuted"
                  className="wrap  !flex !items-start">
                  Consumed Refills{' '}
                  <InfoTooltip
                    id="ConsumedRefills"
                    label="The number of refills the patient has already ordered and/or consumed."
                  />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className="wrap  !flex !items-start">
                  {prescription.consumedRefills}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
