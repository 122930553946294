/* eslint-disable @typescript-eslint/no-explicit-any */
export type ChatUsersListObject = {
  patientId: string;
  patientName: string;
  groupId: string | null;
  groupName: string;
  unreadMessagesCount: number;
  profilePicture: string | null;
  lastMessage?: string;
  isPatientActive: boolean;
};

export type SocketUserResponseObject = {
  patient_id: string;
  patient_name: string;
  profile_picture: string | null;
  group_id: string;
  group_name: string;
  unread_messages_count: number;
  is_patient_active: boolean;
};

export type SocketResponseMessageObject = {
  messages: MessageMetaData[];
  message_id: string;
  patient: string;
  provider: string;
  sent_by: string;
  status: string;
  timestamp: string;
  group_id?: string;
};

export type MessageHistoryType = {
  items: SocketResponseMessageObject[];
  count: number;
};

export type UsersType = { items: SocketUserResponseObject[] };
export type UsersEmitType = { size: number; page?: number; search?: string };

export type MessageEmitType = {
  group_id: string;
  message: string;
};

export interface MySocketEvents {
  'time-msg': (data: { time: number }) => void;
  connect: (data: any) => void;
  disconnect: (reason: string) => void;
  connect_error: (error: Error & { cause?: string }) => void;
  reconnect_attempt: () => void;
  reconnect_failed: () => void;
  reconnect: () => void;
  message: (data: SocketResponseMessageObject | MessageEmitType) => void;
  messages_history: (data: MessageHistoryType) => void;
  users: (data: UsersType | UsersEmitType) => void;
  enter_group: ({ group_id }: { group_id: string }) => void;
  leave_group: ({ group_id }: { group_id: string }) => void;
  unread_messages_count: ({ count }: { count: number }) => void;
  error: (data: any) => void;
}

export type MessageMetaData = {
  message: string;
  metadata: UploadedFiles;
};

export type UploadedFiles = {
  mediaUrl: string;
  mediaType: string;
  mediaName: string;
  signedUrl?: string;
};

export type MessageObject = {
  messages: MessageMetaData[];
  isOutgoing: boolean;
  isIncoming: boolean;
  messageDate?: string;
  mid: string;
  groupId: string;
  timeStamp: string;
};

type FetchChatHistoryType = {
  groupId: string;
  size?: number;
  silentFetch?: boolean;
};

export type SocketMethodsType = {
  connection: boolean;
  isFetchingPatients: boolean;
  sendMessage: (messages: MessageMetaData[], groupId: string) => void;
  openChatHistory: (reqData: FetchChatHistoryType) => void;
  enterGroup: (groupId: string) => void;
  leaveGroup: (groupId: string) => void;
  getPatientsList: (data: UsersEmitType & { silentFetch?: boolean }) => void;
  reConnectionFailed?: boolean;
};

export enum MessageDeliveryType {
  SUCCESS = 'success',
  QUEUED = 'queued',
}
