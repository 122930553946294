import React, { useMemo } from 'react';

import {
  QuestionMarkCircleIcon,
  MapPinIcon,
  IdentificationIcon,
  ClockIcon,
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { PatientService } from 'services/patient';

export function ProfileCompletion() {
  // const { profile } = useProfile();
  const { id: patientId } = useParams();

  const getPatientTodoDetailsQuery = useQuery(
    QueryKeys.PatientTodoDetails.item(patientId || ''),
    () => PatientService.getPatientTodos(patientId ?? ''),
    {
      refetchOnWindowFocus: false,
    }
  );

  const showLegalsUI = useMemo(
    () => getPatientTodoDetailsQuery.data?.data?.legalsAccepted !== null,
    [getPatientTodoDetailsQuery.data?.data?.legalsAccepted]
  );

  return (
    <div
      className={classNames(
        'mt-1 flex h-[25rem] w-full items-center rounded bg-background-main p-8   ',
        {
          '!px-2': showLegalsUI,
        }
      )}>
      {showLegalsUI && (
        <>
          <div className="mx-2 mt-2 flex flex-col items-center gap-2  ">
            <MapPinIcon
              className={classNames(
                'h-8 w-8 font-extrabold text-primary-main ',
                {
                  '!text-error-main':
                    !getPatientTodoDetailsQuery.data?.data?.legalsAccepted,
                }
              )}
            />
            <Typography className="!w-28  text-center">
              Consents and/or Agreements
            </Typography>
          </div>
          <div
            className={classNames('mt-[4.3rem] flex flex-col  items-end', {
              'mt-[4.7rem]': showLegalsUI,
            })}>
            <div className="my-6 h-[2px] w-full grow bg-background-light" />
            <Typography
              className={classNames('w-60', {
                '!w-40': showLegalsUI,
              })}
              color="textMuted"
              variant="subtitle3">
              Patients must agree to the latest consent and/or agreements to
              access Brite services.
            </Typography>
          </div>
        </>
      )}

      <div
        className={classNames('mx-2 mt-0 flex flex-col items-center gap-2', {
          '!mt-3': showLegalsUI,
        })}>
        <QuestionMarkCircleIcon
          className={classNames('h-8 w-8 font-extrabold text-primary-main ', {
            '!text-error-main':
              !getPatientTodoDetailsQuery.data?.data?.questionnaire,
          })}
        />
        <Typography
          className={classNames('whitespace-nowrap', {
            '!w-28 whitespace-pre-wrap text-center': showLegalsUI,
          })}>
          Initial Questionnaire
        </Typography>
      </div>
      <div
        className={classNames(' flex flex-col items-start ', {
          '-mt-4': showLegalsUI,
        })}>
        <Typography
          className={classNames('w-60', {
            '!w-40': showLegalsUI,
          })}
          color="textMuted"
          variant="subtitle3">
          To save time during your consultation, we ask all patients to complete
          questionnaire beforehand.
        </Typography>
        <div className="my-6 mb-20 h-[2px] w-full  grow bg-background-light" />
      </div>
      <div className="mx-2 mt-4 flex flex-col items-center gap-2  ">
        <MapPinIcon
          className={classNames('h-8 w-8 font-extrabold text-primary-main ', {
            '!text-error-main':
              !getPatientTodoDetailsQuery.data?.data?.shippingAddress ||
              !getPatientTodoDetailsQuery.data?.data?.billingAddress,
          })}
        />
        <Typography className="!w-28  text-center">
          Shipping & Billing Addresses
        </Typography>
      </div>
      <div
        className={classNames('mt-[4.3rem] flex flex-col  items-end', {
          'mt-[7rem]': showLegalsUI,
        })}>
        <div className="my-6 h-[2px] w-full grow bg-background-light" />
        <Typography
          className={classNames('w-60', {
            '!w-40': showLegalsUI,
          })}
          color="textMuted"
          variant="subtitle3">
          Collecting shipping and billing info before consultation streamlines
          order creation and reduces post-visit admin tasks.
        </Typography>
      </div>
      <div className="mx-2 mt-6 flex flex-col items-center gap-2 ">
        <IdentificationIcon
          className={classNames('h-8 w-8 font-extrabold text-primary-main ', {
            '!text-error-main':
              !getPatientTodoDetailsQuery.data?.data?.driverLicenceOrSsn,
          })}
        />
        <Typography className="!w-24  text-center">
          Photo Identity Proof
        </Typography>
      </div>
      <div
        className={classNames(' mb-[4.7rem] flex flex-col items-start ', {
          '-mt-3': showLegalsUI,
        })}>
        <Typography
          className={classNames('w-60', {
            '!w-40': showLegalsUI,
          })}
          color="textMuted"
          variant="subtitle3">
          Patients upload a photo of their driver&apos;s license or SSN for
          verifying name, DOB, and residency before shipping Rx orders.
        </Typography>
        <div className="my-6 h-[2px] w-full grow  bg-background-light " />
      </div>
      <div className="mx-2 mt-6 flex flex-col items-center gap-2">
        <ClockIcon className="h-8 w-8 font-extrabold text-primary-main " />
        <Typography className="!w-24  text-center">
          Awaiting Consultation
        </Typography>
      </div>
    </div>
  );
}
