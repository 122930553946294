import { isBefore, subYears } from 'date-fns';
import {
  format as formatFNS,
  utcToZonedTime,
  zonedTimeToUtc,
} from 'date-fns-tz';
import toast from 'react-hot-toast';
import { createSearchParams } from 'react-router-dom';

import { removeAccessAndRefreshTokens } from 'lib/general/token';
import { LicenseNumber } from 'types/common';
import { Gender } from 'types/patient';

type NoUndefinedField<T> = {
  [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>>;
};

export const removeUndefined = <T>(obj: T): NoUndefinedField<T> =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.keys(obj).reduce((acc: never, key: never) => {
    if (obj[key] !== undefined) {
      acc[key] = obj[key];
    }

    return acc;
  }, {}) as unknown as NoUndefinedField<T>;

export const getQueryParamFromURL = ({
  name: _name,
  url = '',
}: {
  name: string;
  url: string;
}) => {
  const name = _name.replace(/[\\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export function getReadableDate(
  isoDate: string,
  timeRequired = false,
  format = 'short'
) {
  /**
   * This will check the format as given below:
   * YYYY/MM/DD
   */
  const isOnlyDateFormat = /^\d{4}-\d{2}-\d{2}$/.test(isoDate);

  const date = new Date(isOnlyDateFormat ? `${isoDate}T00:00:00.000` : isoDate);
  const pad = (n: number, s = 2) => `${new Array(s).fill(0)}${n}`.slice(-s);
  const time = `, ${
    date.getHours() > 12
      ? Number(pad(date.getHours())) - 12
      : pad(date.getHours())
  }:${pad(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;

  return `${date.toLocaleString('en-us', {
    day: 'numeric',
    month: format === 'numeric' ? 'numeric' : 'short',
    year: 'numeric',
  })}${timeRequired ? ` ${time}` : ''}`;
}
export function normalizeString(s: string) {
  return s ? s.replace(/\s+/g, '-').toLowerCase() : '';
}
export function formatPhoneNumber(phoneNumberString?: string) {
  if (!phoneNumberString) {
    return '';
  }
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|91)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';

    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
}

export const getLogoutUrl = (role: string) =>
  `${process.env.REACT_APP_AUTH_APP_BASE_URL}/logout?${createSearchParams({
    role,
  })}`;

export const handleLogout = (role: string) => {
  removeAccessAndRefreshTokens();
  window.location.replace(getLogoutUrl(role));
};

export const capitalizedString = (string?: string) => {
  if (!string) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};
export function getAge(year: string, month: string, date: string) {
  const birthDate = new Date(Number(year), Number(month), Number(date));

  // get difference from current date;
  const difference = Date.now() - birthDate.getTime();

  const ageDate = new Date(difference);
  const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);

  return calculatedAge;
}
export const getTitleCaseFromSnakeCase = (str: string) =>
  str?.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ` ${d.toUpperCase()}`
  );

export const estTimestampCalculator = (date: string) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const utcDate = zonedTimeToUtc(date, timeZone);
  const estTime = utcToZonedTime(utcDate, 'America/New_York');

  return estTime;
};

export function dateToYYYYMMDD(isoDate: string) {
  const isOnlyDateFormat = /^\d{4}-\d{2}-\d{2}$/.test(isoDate);

  const date = new Date(isOnlyDateFormat ? `${isoDate}T00:00:00.000` : isoDate);
  const dateUSTimezoneArray = date.toLocaleDateString('en-US').split('/');

  return `${dateUSTimezoneArray[2]}${
    dateUSTimezoneArray[0].length === 1
      ? `0${dateUSTimezoneArray[0]}`
      : dateUSTimezoneArray[0]
  }${
    dateUSTimezoneArray[1].length === 1
      ? `0${dateUSTimezoneArray[1]}`
      : dateUSTimezoneArray[1]
  }`;
}

export const getLicenseText = (licenseData?: LicenseNumber, message = '') => {
  if (!licenseData?.licenseNumber) return message;

  const readableExpDate = getReadableDate(licenseData.expDate);

  return `${licenseData.licenseNumber} | ${readableExpDate} | ${licenseData.state.name}`;
};

export function checkJSON(text: string) {
  if (typeof text !== 'string') {
    return false;
  }
  try {
    const json = JSON.parse(text);

    return typeof json === 'object';
  } catch (error) {
    return false;
  }
}
export const copyToClipboard = (text: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => toast.success('Text copied'))
    .catch(() => toast.error('Something went wrong'));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const gender: Record<Gender | 'blank', any> = {
  M: 'Male',
  F: 'Female',
  blank: 'Not Available',
};

export function isOlderThan18Years(d: Date) {
  return isBefore(d, subYears(new Date(), 18));
}

export function getPriceInTwoDecimals(price: string | number) {
  return Number(+price / 100).toFixed(2);
}

export const getUsersTimeFromUTC = (date: string) => {
  if (date) {
    const parsedUtcTime = date.includes('Z') ? date : `${date.split('.')[0]}Z`;

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = utcToZonedTime(parsedUtcTime, userTimeZone);
    const localDateOnly = formatFNS(zonedDate, 'yyyy-MM-dd', {
      timeZone: userTimeZone,
    });
    const localTimeOnly = formatFNS(zonedDate, 'HH:mm', {
      timeZone: userTimeZone,
    });

    return { localDateOnly, localTimeOnly };
  }

  return null;
};
export function formatDeliveryUnit(value: number, pluralUnit: string) {
  // eslint-disable-next-line prefer-const
  let [unit, byValue] = pluralUnit.split('/');
  unit = unit.endsWith('s') ? unit.substring(0, unit.length - 1) : unit;

  return value === 1 ? `${unit}/${byValue}` : `${pluralUnit}`;
}
