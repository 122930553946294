import React, { PropsWithChildren } from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import { Typography } from '../Typography/Typography';

type WarningTextProps = {
  className?: string;
};

export function WarningText({
  children,
  className,
}: PropsWithChildren<WarningTextProps>) {
  return (
    <div
      className={classNames(
        ' flex items-center gap-4 rounded bg-error-main bg-opacity-50 px-4 py-3 text-background-contrastText',
        className
      )}>
      <ExclamationCircleIcon className="h-8 w-8  font-extrabold text-white " />
      <Typography color="white" className="flex w-11/12" variant="subtitle2">
        <dfn className="not-italic">{children}</dfn>
      </Typography>
    </div>
  );
}
