import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { PatientPrescriptionService } from 'services/prescription';
import { MedicationComplianceType } from 'types/patient/prescription';

type MedicationComplianceProp = {
  medicationId: string;
  onSuccess: (complianceResponse: MedicationComplianceType) => void;
};

export function useMedicationCompliance({
  medicationId,
  onSuccess,
}: MedicationComplianceProp) {
  const { id: patientId } = useParams();

  const checkMedicationComplianceQuery = useQuery(
    [],
    () =>
      PatientPrescriptionService.getMedicationCompliance({
        patientId: patientId ?? '',
        medicationId,
      }),
    {
      enabled: !!patientId && !!medicationId,
      onSuccess: (data) => {
        onSuccess(data?.data);
      },
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
    }
  );

  return { checkMedicationComplianceQuery };
}
