import { useContext, useState } from 'react';

import { EPrescribeUtilContext } from 'components/patient/e-prescribeV2/HormoneListing';
import { femaleHormoneRxTypes } from 'constants/hormone-details-new';
import { useDTD } from 'hooks/hormones/useDTD';
import { useSIG } from 'hooks/hormones/useSIG';
import { CurrentDoseType } from 'types/patient/prescription';
import { LetterOfExplanations } from 'types/prescriptionTypes';

import { useMaximumDoseHandler } from '../useMaximumDoseHandler';
import { useSigGenerator } from '../useSigGenerator';

export const useTestosteroneWomenController = () => {
  const TestosteroneWomenDetails = femaleHormoneRxTypes?.testosterone;
  const [strength, setStrength] = useState(8);

  const [dose, setDose] = useState(0);
  const [refills, setRefills] = useState(0);
  const { dtd, setDTD, DTDValues } = useDTD(18);
  const { sig, setSig } = useSIG('');
  const [note, setNote] = useState('');
  const [currentDose, setCurrentDose] = useState<CurrentDoseType>();
  const { renderModal } = useContext(EPrescribeUtilContext);
  const [loe, setLoe] = useState<LetterOfExplanations>();
  const [loeDetails, setloeDetails] = useState({
    loeRequired: false,
    agreedToSelectedLoe: false,
  });

  const strengthOptions = [8, 24];
  // the maximum daily dose slider should automatically be 0.2 higher than the dose slider
  const marginValue = 4;

  const {
    maximumDose,
    setMaximumDose,
    maxDoseOptions,
    setMaxDosePreValue,
    maxDosePreValue,
  } = useMaximumDoseHandler({
    dose,
    doses: TestosteroneWomenDetails.dose,
    incrementalValue: marginValue,
  });

  const { generatedSIG, setGeneratedSIG } = useSigGenerator({
    dose,
    maximumDose,
    doseOptions: TestosteroneWomenDetails?.dose,
    marginValue,
  });

  return {
    TestosteroneWomenDetails,

    sig,
    setSig,
    setDTD,
    DTDValues,

    dtd,
    strength,
    setStrength,

    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
    currentDose,
    setCurrentDose,
    renderModal,
    generatedSIG,
    setGeneratedSIG,
    strengthOptions,
    marginValue,
    maxDoseOptions,
    setMaxDosePreValue,
    loe,
    setLoe,
    loeDetails,
    setloeDetails,
    maxDosePreValue,
  };
};
