import React, { useEffect, useMemo } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useOutletContext } from 'react-router-dom';

import { useBiEstController } from 'hooks/e-prescribe/hormone-v2-hooks/useBiEstController';
import { useCalculateDifference } from 'hooks/e-prescribe/useCalculateDifference';
import { useCheckPrescription } from 'hooks/e-prescribe/useCheckPrescription';
import { useCurrentActivePrescription } from 'hooks/e-prescribe/useCurrentPrescription';
import { useHormoneStandardizedName } from 'hooks/e-prescribe/useHormoneStandardizedName';
import { PatientPrescriptionService } from 'services/prescription';
import {
  HormoneSelectionProps,
  PatientDetailsContextType,
} from 'types/patient';
import {
  HormoneNames,
  HormoneValidations,
  PrescribedObjectType,
  PrescriptionCheckValuesForReview,
  PrescriptionDifferenceType,
  PrescriptionDisplayTypes,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';
import { capitalizedString } from 'utils/common';

import { colorObjForCheckPrescription, colorObjForSupply } from '../common';
import { HormoneDetailsUIV2 } from '../HormoneDetailsUIV2';

export function BiEst({
  addNewPrescription,
  RxDetails,
  onBack,
}: HormoneSelectionProps) {
  const { hasDeaForShippingState } =
    useOutletContext<PatientDetailsContextType>();

  const {
    BiEstDetails,

    dtd,

    ratio,
    setRatio,
    dose,
    setDose,
    refills,
    marginValue,
    setRefills,
    strength,
    setStrength,
    currentDose,
    setCurrentDose,
    renderModal,
    setDTD,
    generatedSIG,
    setGeneratedSIG,
    maximumDose,
    setMaximumDose,
    strengthOptions,
    setStrengthOptions,
    setMaxDosePreValue,
    maxDoseOptions,
    maxDosePreValue,
  } = useBiEstController();
  // const { id: patientId } = useParams();

  const prescriptionObject: PrescribedObjectType = useMemo(
    () => ({
      id: RxDetails?.id,
      displayName: BiEstDetails.displayName,
      name: HormoneNames.BI_EST,
      strength: `${strength}`,
      strengthUnit: BiEstDetails.strengthUnit,
      ratio: BiEstDetails.ratio[ratio],
      ratioUnit: BiEstDetails.ratioUnit,
      dose: BiEstDetails.dose[dose + maximumDose + marginValue],
      minimumDose: BiEstDetails.dose[dose],
      doseUnit: BiEstDetails.doseUnit,
      refills,
      dtd,
      sig: generatedSIG,
      deliveryMethod: BiEstDetails.deliveryMethod,
      family: BiEstDetails.family,
      coveredUnderSubscription:
        RxDetails?.coveredUnderSubscription?.toString() ?? 'false',
      dtdUnit: BiEstDetails.dtdUnit,
      currentDose,
    }),
    [
      BiEstDetails,
      RxDetails,
      dose,
      dtd,
      generatedSIG,
      maximumDose,
      ratio,
      refills,
      strength,
      currentDose,
      marginValue,
    ]
  );

  const getCurrentPrescriptionCheckMutation = useMutation(
    (id: string) => PatientPrescriptionService.getPrescriptionCheckValue(id),
    {
      onSuccess() {
        // console.log(data, '******');
      },
    }
  );

  const {
    currentActivePrescriptions,
    isLoading: currentPrescriptionFetchLoading,
  } = useCurrentActivePrescription({
    medicationId: RxDetails?.id ?? '',
    onSuccess(prescription) {
      getCurrentPrescriptionCheckMutation.mutateAsync(prescription?.id ?? '');
    },
  });

  const { calculatePercentageDifference, getDifferenceUI } =
    useCalculateDifference({
      hormoneName: HormoneNames.BI_EST,
    });

  const { checkPrescriptionValueQuery: maxDosePrescriptionValues } =
    useCheckPrescription({
      prescription: {
        ...prescriptionObject,
        dose: BiEstDetails.dose[dose + maximumDose + marginValue],
      },
      onSuccess() {
        //
      },
    });

  const { checkPrescriptionValueQuery: minDosePrescriptionValues } =
    useCheckPrescription({
      prescription: { ...prescriptionObject, dose: BiEstDetails.dose[dose] },
      onSuccess() {
        // console.log(data);
      },
    });

  const { getStandardizedNameQuery } = useHormoneStandardizedName({
    prescription: { ...prescriptionObject, dose: BiEstDetails.dose[dose] },
  });

  const prescriptionCheckValues: PrescriptionCheckValuesForReview = useMemo(
    () => ({
      checkValues: {
        withMaxDose: maxDosePrescriptionValues.data?.data.value,
        withMinDose: minDosePrescriptionValues.data?.data.value,
        unit: maxDosePrescriptionValues.data?.data.unit ?? '',
      },
      daysOfSupply: {
        withMaxDose:
          maxDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
        withMinDose:
          minDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
      },
      isLowPotencyRx:
        Number(maxDosePrescriptionValues.data?.data.value) <= 0.61,
      isHighPotencyRx:
        Number(maxDosePrescriptionValues.data?.data.value) >= 5.49,
      standardizedName:
        getStandardizedNameQuery.data?.data.standardRepresentation,
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      maxDosePrescriptionValues,
      minDosePrescriptionValues,
      getStandardizedNameQuery.data?.data.standardRepresentation,
    ]
  );

  function getComparisonValueForMinimumDose() {
    if (
      currentActivePrescriptions?.id &&
      !!getCurrentPrescriptionCheckMutation.data?.data?.minDoseQuality?.value
    ) {
      const comparableValue =
        getCurrentPrescriptionCheckMutation.data?.data?.currentDoseQuality
          ?.value ??
        getCurrentPrescriptionCheckMutation.data?.data?.minDoseQuality?.value;

      return getDifferenceUI(
        calculatePercentageDifference(
          comparableValue,
          minDosePrescriptionValues.data?.data.value
        ),
        `${comparableValue} ${getCurrentPrescriptionCheckMutation.data?.data?.unit}`
      );
    }

    return '';
  }

  const maxDoseDiff = useMemo(
    () =>
      currentActivePrescriptions?.id &&
      !!getCurrentPrescriptionCheckMutation.data?.data?.value
        ? calculatePercentageDifference(
            getCurrentPrescriptionCheckMutation.data?.data?.value,
            maxDosePrescriptionValues.data?.data.value
          )
        : 0,
    [
      getCurrentPrescriptionCheckMutation.data?.data,
      maxDosePrescriptionValues.data?.data,
      calculatePercentageDifference,
      currentActivePrescriptions?.id,
    ]
  );

  const prescriptionDifferenceValues: PrescriptionDifferenceType = useMemo(
    () => ({
      maxDoseDiffValue: maxDoseDiff,
    }),
    [maxDoseDiff]
  );

  useEffect(() => {
    if (BiEstDetails.dose[maximumDose + dose + marginValue] > 0.15) {
      setDTD(30);
    } else {
      setDTD(15);
    }
  }, [dose, maximumDose, BiEstDetails.dose, setDTD, marginValue]);

  useEffect(() => {
    if (currentActivePrescriptions?.id) {
      getCurrentPrescriptionCheckMutation.mutateAsync(
        currentActivePrescriptions?.id ?? ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePrescriptions?.id]);

  useEffect(() => {
    if (
      currentActivePrescriptions?.id &&
      !currentActivePrescriptions?.titrationType
    ) {
      if (
        Number(
          currentActivePrescriptions?.deliveryMethodAndSig?.minDailyDose?.value
        ) <= 0.35
      ) {
        setDose(
          BiEstDetails.dose.findIndex(
            (d: number) =>
              d ===
              Number(
                currentActivePrescriptions?.deliveryMethodAndSig?.minDailyDose
                  ?.value
              )
          )
        );
      }

      setMaxDosePreValue(
        Number(currentActivePrescriptions?.deliveryMethodAndSig.dose.value)
      );

      setRatio(
        BiEstDetails.ratio.findIndex(
          (r: string) =>
            r ===
            `${currentActivePrescriptions?.ratio?.e3}:${currentActivePrescriptions?.ratio?.e2}`
        )
      );

      setStrength(Number(currentActivePrescriptions?.strength?.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePrescriptions]);

  useEffect(() => {
    if (
      !currentActivePrescriptions?.id &&
      !!currentActivePrescriptions?.titrationType
    ) {
      return;
    }

    if (
      Number(
        currentActivePrescriptions?.deliveryMethodAndSig?.minDailyDose?.value
      ) !==
        Number(currentActivePrescriptions?.deliveryMethodAndSig?.dose?.value) &&
      maxDosePreValue ===
        Number(currentActivePrescriptions?.deliveryMethodAndSig?.dose?.value)
    ) {
      const tempMaxDoseIndex = maxDoseOptions.findIndex(
        (d: number) =>
          d ===
          Number(currentActivePrescriptions?.deliveryMethodAndSig.dose.value)
      );
      setMaximumDose(tempMaxDoseIndex === 0 ? 0 : tempMaxDoseIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePrescriptions, maxDosePreValue]);

  return (
    <HormoneDetailsUIV2
      onAdd={() =>
        addNewPrescription({
          ...prescriptionObject,
          ...prescriptionCheckValues,
        })
      }
      validations={{
        [HormoneValidations.Loading]: {
          isFailed:
            maxDosePrescriptionValues.isLoading ||
            minDosePrescriptionValues.isLoading ||
            getStandardizedNameQuery.isLoading,
        },
        [HormoneValidations.MissingDEA]: {
          isFailed:
            !hasDeaForShippingState && !!RxDetails?.isControlledSubstance,
        },
        [HormoneValidations.DaysOfSupply]: {
          isFailed: !maxDosePrescriptionValues.data?.data.validDaysOfSupply,
        },
        [HormoneValidations.SIGExceed140Chars]: {
          isFailed: Number(generatedSIG.length) > 140,
        },
      }}
      prescriptionObject={{
        ...prescriptionObject,
        ...prescriptionCheckValues,
        ...prescriptionDifferenceValues,
      }}
      onBack={onBack}
      title={capitalizedString(RxDetails?.name)}
      hormoneInputs={[
        {
          containerClassName: 'flex justify-between',
          title: '',
          children: [
            {
              type: PrescriptionInputTypes.SelectorCheckbox,
              options: strengthOptions,
              onChange(s) {
                setStrength(s as number);
                setDose(0);
                setMaximumDose(0);
                renderModal?.({
                  oldValue: strength.toString(),
                  newValue: s.toString(),
                  checkPrescriptionUnit: 'mgeeq/day',
                  changeValueUnit: BiEstDetails.strengthUnit,
                });
              },
              label: 'Strength',
              value: strength,
              unit: BiEstDetails.strengthUnit,
              wrapperClassName: '!min-w-[215px]',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: `${dtd.toString()} ml`,
              label: 'DTD',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: BiEstDetails.deliveryMethod,
              label: 'Delivery Method',
            },
            {
              label: 'Days of Supply',
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!maxDosePrescriptionValues.isLoading ||
                !!minDosePrescriptionValues.isLoading,
              value:
                maxDosePrescriptionValues.data?.data.daysOfSupply ===
                minDosePrescriptionValues.data?.data?.daysOfSupply
                  ? `${maxDosePrescriptionValues.data?.data.daysOfSupply} Days`
                  : `${maxDosePrescriptionValues.data?.data.daysOfSupply}-${minDosePrescriptionValues.data?.data?.daysOfSupply} Days`,

              colorClass: `${
                maxDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForSupply[
                      maxDosePrescriptionValues.data?.data.validDaysOfSupply.toString()
                    ]
                  : ''
              }`,
            },
          ],
        },
        {
          title: 'Ratio',
          containerClassName:
            'grid grid-cols-12 p-10 pb-16 pt-20 bg-background-main rounded items-center gap-x-16',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              // eslint-disable-next-line react/no-unstable-nested-components
              getTooltip: (val) => (
                <span className="uppercase">
                  {`${BiEstDetails.ratioUnit}(${BiEstDetails.ratio[val]})`}
                </span>
              ),
              onChange: (d) => {
                setRatio(d);

                if (BiEstDetails.ratio[d] !== '80:20') {
                  setStrengthOptions([30]);
                } else {
                  setStrengthOptions([30, 60]);
                }

                if (BiEstDetails.ratio[d] !== '80:20' && strength !== 30) {
                  renderModal?.({
                    oldValue: strength.toString(),
                    newValue: '30',
                    checkPrescriptionUnit: 'mgeeq/day',
                    changeValueUnit: BiEstDetails.strengthUnit,
                    customMessage: (
                      <>
                        Please note that this ratio is not allowed with{' '}
                        <span className="font-bold text-primary-main ">
                          60 {BiEstDetails.strengthUnit}
                        </span>
                        .{' '}
                        <span className="font-bold text-background-contrastText ">
                          {BiEstDetails.strengthUnit}
                        </span>{' '}
                        has changed to{' '}
                        <span className="font-bold text-primary-main ">30</span>
                        , and the dose sliders have been reset to their initial
                        state. Please adjust the dose again to ensure the
                        appropriate{' '}
                        <span className="font-bold text-background-contrastText ">
                          mgeeq/day
                        </span>
                        .{' '}
                      </>
                    ),
                  });
                  setStrength(30);
                }
              },
              options: BiEstDetails.ratio,
              value: ratio,
              wrapperClassName: 'col-span-9 ',
            },
          ],
        },
        {
          title: 'Dose',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-6 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${`${BiEstDetails.dose[val]?.toFixed(2)} ${
                  BiEstDetails.doseUnit
                }`}`,
              onChange: (d) => {
                setDose(d);
              },
              options: [
                ...BiEstDetails.dose.slice(
                  0,
                  BiEstDetails.dose.length - marginValue
                ),
              ],
              value: dose,
              wrapperClassName: 'col-span-9',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!minDosePrescriptionValues.isLoading ||
                getCurrentPrescriptionCheckMutation.isLoading ||
                currentPrescriptionFetchLoading,
              label: `Minimum ${minDosePrescriptionValues?.data?.data.unit}`,
              value: minDosePrescriptionValues?.data?.data.value
                ? `${minDosePrescriptionValues?.data?.data.value} ${minDosePrescriptionValues?.data?.data.unit}`
                : '',
              wrapperClassName: 'col-span-3 !h-[7rem] mt-6  ',
              colorClass: `${
                minDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForCheckPrescription[
                      minDosePrescriptionValues.data?.data.rxQuality
                    ]
                  : ''
              }`,
              helperText: getComparisonValueForMinimumDose(),
            },
            {
              type: PrescriptionInputTypes.DoseSplitter,
              onChange: (cd) => {
                setCurrentDose({
                  morning: {
                    value: cd.morning,
                    unit: BiEstDetails.doseUnit,
                  },
                  evening: {
                    value: cd.evening,
                    unit: BiEstDetails.doseUnit,
                  },
                });
              },
              hormoneName: HormoneNames.BI_EST,
              value: BiEstDetails.dose[dose],
              wrapperClassName: 'col-span-9 pl-4 mt-16 ',
            },
          ],
        },
        {
          title: 'Maximum Dose',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-6 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${`${maxDoseOptions[val]?.toFixed(2)} ${
                  BiEstDetails.doseUnit
                }`}`,
              onChange: (d) => {
                setMaxDosePreValue(maxDoseOptions[d]);
                setMaximumDose(d);
              },
              options: maxDoseOptions,
              value: maximumDose,
              wrapperClassName: 'col-span-9',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!maxDosePrescriptionValues.isLoading ||
                getCurrentPrescriptionCheckMutation.isLoading ||
                currentPrescriptionFetchLoading,
              label: `Maximum ${maxDosePrescriptionValues?.data?.data.unit}`,
              value: maxDosePrescriptionValues?.data?.data.value
                ? `${maxDosePrescriptionValues?.data?.data.value} ${maxDosePrescriptionValues?.data?.data.unit}`
                : '',
              wrapperClassName: 'col-span-3 !h-[7rem] mt-6  ',
              colorClass: `${
                maxDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForCheckPrescription[
                      maxDosePrescriptionValues.data?.data.rxQuality
                    ]
                  : ''
              }`,
              helperText:
                currentActivePrescriptions?.id &&
                !!getCurrentPrescriptionCheckMutation.data?.data?.value
                  ? getDifferenceUI(
                      calculatePercentageDifference(
                        getCurrentPrescriptionCheckMutation.data?.data?.value,
                        maxDosePrescriptionValues.data?.data.value
                      ),
                      `${getCurrentPrescriptionCheckMutation.data?.data?.value} ${getCurrentPrescriptionCheckMutation.data?.data?.unit}`
                    )
                  : '',
            },
          ],
        },
        {
          title: 'Refills',
          containerClassName:
            'grid grid-cols-12 p-10 pb-16 pt-20 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) => `${BiEstDetails.refills[val]}  refills`,
              onChange: (d) => {
                setRefills(d);
              },
              options: BiEstDetails.refills,
              value: refills,
              wrapperClassName: 'col-span-9',
            },
          ],
        },
        {
          containerClassName: '',
          title: 'SIG',
          children: [
            {
              type: PrescriptionInputTypes.SIG,
              wrapperClassName: 'col-span-9',
              onChange: (s) => {
                setGeneratedSIG(s);
              },
              value: generatedSIG,
            },
          ],
        },
      ]}
    />
  );
}
