import React from 'react';

import { InfoTooltip } from 'components/common/InfoTooltip/InfoTooltip';
import { Typography } from 'components/common/Typography/Typography';
import { GMTDate } from 'lib/general/GMT-date';
import { PatientPrescription } from 'types/patient/prescription';

type PrescriptionsDatesType = {
  prescription: PatientPrescription;
};

export function PrescriptionsDates({ prescription }: PrescriptionsDatesType) {
  return (
    <div className="mt-4 flex justify-between gap-4 border-y p-4 ">
      <div>
        <Typography
          variant="subtitle2"
          color="textMuted"
          className="wrap  !flex !items-start">
          Application Start Date{' '}
          <InfoTooltip
            id="ApplicationStartDate"
            label="The date when the patient started applying the prescription."
          />
        </Typography>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          className="wrap  !flex !items-start">
          {prescription?.startDate
            ? new GMTDate(prescription?.startDate).getReadableDateInGMT()
            : '-'}
        </Typography>
      </div>{' '}
      <div>
        <Typography
          variant="subtitle2"
          color="textMuted"
          className="wrap  !flex !items-start">
          Application End Date{' '}
          <InfoTooltip
            id="ApplicationEndDate"
            label="The date when the prescription was moved to Previous Rx(s) from Active Rx(s)."
          />
        </Typography>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          className="wrap  !flex !items-start">
          {prescription?.endDate
            ? new GMTDate(prescription?.endDate).getReadableDateInGMT()
            : '-'}
        </Typography>
      </div>{' '}
      <div>
        <Typography
          variant="subtitle2"
          color="textMuted"
          className="wrap  !flex !items-start">
          Prescribed Date{' '}
          <InfoTooltip
            id="PrescribedDate"
            label="The date when this prescription was issued by the provider."
          />
        </Typography>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          className="wrap  !flex !items-start">
          {prescription?.createdAt
            ? new GMTDate(prescription?.createdAt).getReadableDateInGMT()
            : '-'}
        </Typography>
      </div>{' '}
      <div>
        <Typography
          variant="subtitle2"
          color="textMuted"
          className="wrap  !flex !items-start">
          Prescription Expiration Date{' '}
          <InfoTooltip
            id="PrescriptionExpirationDate"
            label="The controlled substance prescriptions will expire in 120 days and the non controlled prescription will expire in 365 days from the prescribed date. Once the prescription has expired the patient won't be able to order remaining refills."
          />
        </Typography>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          className="wrap  !flex !items-start">
          {prescription?.expirationDate
            ? new GMTDate(prescription.expirationDate).getReadableDateInGMT()
            : '-'}
        </Typography>
      </div>{' '}
      <div>
        <Typography
          variant="subtitle2"
          color="textMuted"
          className="wrap  !flex !items-start">
          Last Delivered Date{' '}
          <InfoTooltip
            id="LastDeliveredDate"
            label="The date when the prescription or refill was last delivered to the patient."
          />
        </Typography>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          className="wrap  !flex !items-start">
          {prescription?.lastDeliveredDate
            ? new GMTDate(
                prescription?.lastDeliveredDate
              ).getReadableDateInGMT()
            : '-'}
        </Typography>
      </div>{' '}
      <div>
        <Typography
          variant="subtitle2"
          color="textMuted"
          className="wrap  !flex !items-start">
          Next Order Date{' '}
          <InfoTooltip
            id="NextOrderDate"
            label="The date from which the patient will be able to order a refill for this prescription. It is derived as -
Date = [ last delivered date + (days of supply - 7 days) ]"
          />
        </Typography>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          className="wrap  !flex !items-start">
          {prescription?.nextOrderDate
            ? new GMTDate(prescription.nextOrderDate).getReadableDateInGMT()
            : '-'}
        </Typography>
      </div>
    </div>
  );
}
