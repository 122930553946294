import { ReactNode } from 'react';

import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

import { LoadingSpinner } from '../Loading/LoadingSpinner';
import { Typography } from '../Typography/Typography';

interface RadioButtonProps {
  id: string;
  label: ReactNode;
  checked: boolean;
  onChange: (value: boolean | string) => void;
  className?: string;
  square?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
}

export function RadioButton({
  id,
  onChange,
  className,
  label,
  checked,
  square,
  fullWidth,
  loading,
}: RadioButtonProps) {
  function getRadioButton() {
    return square ? (
      // eslint-disable-next-line
      <div
        id={id}
        onClick={() => onChange(id)}
        className={classNames(
          ' flex w-full cursor-pointer items-center rounded border border-zinc-600 bg-background-light   py-3 px-2 ',
          {
            '!border-primary-main': checked,
            '!w-full': fullWidth,
          }
        )}>
        <div
          className={classNames(
            '  flex h-4 w-4  items-center justify-center rounded bg-background-contrastText',
            {
              '!bg-primary-main': checked,
              '!w-full': fullWidth,
            }
          )}>
          <CheckIcon
            className={classNames(
              'invisible h-8  w-8 font-extrabold text-primary-contrastText ',
              {
                '!visible': checked,
              }
            )}
          />{' '}
        </div>
        <span className="mr-2" />
        <Typography className="select-none " variant="subtitle2">
          {label}
        </Typography>
      </div>
    ) : (
      // eslint-disable-next-line
      <div
        onClick={() => onChange(id)}
        className={classNames(
          ' flex w-60   cursor-pointer items-center rounded  ',
          {
            '!border-primary-main': checked,
            '!w-full': fullWidth,
          }
        )}>
        {loading ? (
          <LoadingSpinner className=" h-6 w-6 text-primary-main" />
        ) : (
          <div
            id={id}
            className={classNames(
              '  mr-2 mb-2 flex h-4 w-4  items-center justify-center rounded bg-zinc-200',
              {
                '!bg-primary-main': checked,
                'mb-0': !loading,
              }
            )}>
            <CheckIcon
              className={classNames(
                'invisible h-8  w-8 font-extrabold text-primary-contrastText ',
                {
                  '!visible': checked,
                }
              )}
            />
          </div>
        )}

        <span className="mr-2" />

        <Typography
          className={classNames('select-none ', {
            '!w-full': fullWidth,
          })}
          variant="subtitle2">
          {label}
        </Typography>
      </div>
    );
  }

  return (
    // eslint-disable-next-line
    <div
      onClick={() => onChange(id)}
      className={classNames('flex  items-center text-md   ', className)}>
      {getRadioButton()}
    </div>
  );
}
