/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import { useState } from 'react';

// import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import Zoom from 'react-medium-image-zoom';

import documentIcon from 'assets/images/document.svg';
import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import { Typography } from 'components/common/Typography/Typography';
import { UploadedFiles } from 'types/socket/types';

import 'react-medium-image-zoom/dist/styles.css';

export function RenderFileViewer({ fileData }: { fileData: UploadedFiles }) {
  const isImage = fileData.mediaType.includes('image');
  const [openModal, setOpenModal] = useState(false);
  const supportedViewerTypes = [
    '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt',
  ];

  const handleDownload = () => {
    const fileUrl = fileData.signedUrl || '';
    const fileName = fileData.mediaName;

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const thumbnailRenderer = () => {
    if (isImage) {
      return (
        <img
          className="cursor-pointer rounded"
          src={fileData.signedUrl}
          height={140}
          width={180}
          alt={fileData.mediaName}
        />
      );
    }

    return (
      <div className=" flex  cursor-pointer justify-center rounded bg-black p-3">
        <img
          src={documentIcon}
          height={20}
          width={20}
          alt="document"
          className="mr-2"
        />
        <Typography variant="subtitle2">{fileData.mediaName}</Typography>
        {/* <img
          src={downloadIcon}
          height={20}
          width={20}
          alt="document"
          className="ml-2"
        /> */}
      </div>
    );
  };

  const fileTypes = supportedViewerTypes[0]
    .split(',')
    .map((type) => type.trim());

  const isSupportedFileType = () =>
    fileTypes.some((type) => fileData.mediaName.includes(type));

  return (
    <div
      onClick={() =>
        isImage || isSupportedFileType() ? setOpenModal(true) : handleDownload()
      }>
      {thumbnailRenderer()}
      <Modal
        open={openModal}
        className={` h-[80%] w-[85%] ${
          isImage ? 'flex justify-center' : ''
        } overflow-visible`}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        closeModal={() => {}}>
        <div>
          {
            isImage ? (
              // eslint-disable-next-line react/no-children-prop, react/no-unstable-nested-components
              <Zoom zoomMargin={5}>
                <img src={fileData.signedUrl} alt={fileData.mediaName} />
              </Zoom>
            ) : null
            //   (
            //     <DocViewer
            //       className="!h-96"
            //       pluginRenderers={DocViewerRenderers}
            //       documents={[{ uri: fileData.signedUrl || '', fileType: 'docx' }]}
            //     />
            //   )
          }

          <div className="mt-4 -mb-2 flex justify-center">
            <Button onClick={handleDownload} color="primary" className="mr-2">
              Download
            </Button>
            <Button color="secondary" onClick={() => setOpenModal(false)}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
