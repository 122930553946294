import { useState } from 'react';

import { useChat } from 'context/ChatProvider';
import { UploadedFiles } from 'types/socket/types';

import { ChatInput } from '../ChatInput/ChatInput';

export function ChatFooter() {
  const { selectedUser, socketMethods } = useChat();

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles[]>([]);

  const handleSendMessage = (message: string) => {
    if (!message?.trim() && !uploadedFiles.length) return;

    const messages = [];
    if (uploadedFiles.length) {
      // eslint-disable-next-line array-callback-return
      uploadedFiles.map((item) => {
        messages.push({ message: '', metadata: item });
      });
    }
    if (message) {
      messages.push({
        message,
        metadata: {
          mediaUrl: '',
          mediaType: 'text',
          mediaName: '',
        },
      });
    }
    socketMethods?.sendMessage(messages, selectedUser.groupId || '');
  };

  const onFileUpload = (data: UploadedFiles) => {
    setUploadedFiles((prevUploaded) => [data, ...prevUploaded]);
  };

  return (
    <ChatInput
      selectedUser={selectedUser}
      setInputText={handleSendMessage}
      isMessagingAllowed={selectedUser.isPatientActive}
      onFileUpload={onFileUpload}
    />
  );
}
