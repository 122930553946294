import React, {
  FocusEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Controller, Control, FieldValues } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';

import { ControllerOnChangeType } from 'types/inputTypes';

import { Typography } from '../Typography/Typography';
// type ColorType = 'primary' | 'secondary' | 'error' | 'info' | 'success';
type InputType = 'text' | 'email' | 'number' | 'password' | 'tel';
type MultilineProps =
  | {
      multiline?: true;
      rows: number;
    }
  | {
      multiline?: false;
      rows?: never;
    };

type ControlProps =
  | {
      // eslint-disable-next-line
      control?: Control<any, any>;
      name: string;
    }
  | {
      control?: false | undefined;
      name?: never;
    };

type InputProps = {
  label?: string;
  className?: string;
  helperText?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  type?: InputType;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  disableHelperText?: boolean;
  defaultValue?: string;
  id?: string;
  borderOnFocus?: boolean;
  onFocusCapture?: FocusEventHandler<HTMLInputElement>;
  onBlurCapture?: FocusEventHandler<HTMLInputElement>;
  required?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
} & MultilineProps &
  ControlProps;

export function TextInput({
  type = 'text',
  className,
  helperText,
  error,
  placeholder,
  value,
  onChange,
  disabled,
  fullWidth,
  multiline,
  rows = 6,
  control,
  name,
  label,
  borderOnFocus = true,
  disableHelperText,
  defaultValue,
  required,
  id,
  onKeyDown,
  onBlurCapture,
  onFocusCapture,
}: InputProps) {
  const [showPassword, setShowPassword] = useState<string>();
  useEffect(() => {
    if (type) {
      setShowPassword(type);
    }
  }, [type]);

  const getError = useCallback(() => {
    if (control) {
      if (control._formState.errors) {
        if (name.includes('.')) {
          const splittedArray = name.split('.');

          return splittedArray.reduce(
            // eslint-disable-next-line
            (acc: any, i) => acc?.[i],
            control._formState.errors
          );
        }

        return control._formState.errors?.[name];
      }
    }

    return undefined;
  }, [control, name]);

  const fieldError = getError();

  const classes = useMemo(
    () =>
      classNames(
        'px-2 py-3  rounded shadow-sm focus:outline-none placeholder:text-zinc-400 placeholder:text-md text-md bg-background-light w-full text-background-contrastText border border-transparent  ',
        className,
        {
          'opacity-60 cursor-not-allowed': disabled,
        },
        {
          'border !border-error-main': !!fieldError?.message,
        },
        {
          'focus:border focus:border-primary-main': !!borderOnFocus,
        }
      ),

    [className, disabled, fieldError, borderOnFocus]
  );

  const getInput = useCallback(
    (
      controlChange?: ControllerOnChangeType,
      formValue?: FieldValues['value']
    ) =>
      multiline ? (
        <textarea
          id={id}
          className={classes}
          placeholder={placeholder}
          value={control ? formValue : value}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onKeyDown={onKeyDown}
          onChange={
            control
              ? controlChange
              : (e) => {
                  onChange?.(e.target.value);
                }
          }
          disabled={disabled}
          rows={rows}
          defaultValue={defaultValue}
        />
      ) : type === 'tel' ? (
        <PhoneInput
          inputProps={{
            name,
            className: classNames(classes, 'pl-12 !z-5'),
            id,
            disabled,
          }}
          value={control ? formValue || defaultValue : value || defaultValue}
          onChange={
            control
              ? (phone: string) =>
                  controlChange?.({ target: { value: phone, name } })
              : (phone: string) => {
                  onChange?.(phone);
                }
          }
          placeholder={placeholder}
          disabled={disabled}
          containerClass="!rounded-2xl !bg-background-light "
          searchClass="[&>input]:!border-none !py-2 !pl-2 !bg-background-light !text-background-contrastText !outline-none"
          dropdownClass="[&>li]:!border-b [&>li]:!border-zinc-500 [&>li[role]]:!border-none [&>li[role]]:!p-3 [&>li[role]]:!px-4 [&>li[role]]:!text-background-contrastText [&>li[role]:hover]:!bg-primary-main [&>li[role]:hover]:!text-primary-contrastText [&>li[aria-selected]]:!bg-primary-main !bg-background-light !text-background-contrastText !ml-0 !mt-0 !shadow-none"
          buttonClass={classNames(
            "!rounded-md !z-0 overflow-hidden [&>div[role='button']]:!bg-background-light !bg-background-light  !outline-none !m-1 !text-background-contrastText !border-none"
          )}
          searchPlaceholder="Search"
          disableDropdown
          countryCodeEditable={false}
          country="us"
          disableCountryGuess
        />
      ) : (
        <div className="relative">
          <input
            id={id}
            name={name ?? ''}
            type={showPassword ?? type}
            className={classes}
            placeholder={placeholder}
            value={control ? formValue : value}
            onChange={
              control
                ? controlChange
                : (e) => {
                    onChange?.(e.target.value);
                  }
            }
            disabled={disabled}
            defaultValue={defaultValue}
            onFocusCapture={onFocusCapture}
            onBlurCapture={onBlurCapture}
          />
          {type === 'password' &&
            (showPassword === 'password' ? (
              <EyeSlashIcon
                onClick={() => setShowPassword('text')}
                className="absolute top-3 right-4 h-[20px] cursor-pointer text-zinc-400"
              />
            ) : (
              <EyeIcon
                onClick={() => setShowPassword('password')}
                className="absolute top-3 right-4 h-[20px] cursor-pointer text-zinc-400"
              />
            ))}
        </div>
      ),
    [
      multiline,
      id,
      classes,
      placeholder,
      control,
      value,
      disabled,
      rows,
      defaultValue,
      type,
      name,
      showPassword,
      onKeyDown,
      onFocusCapture,
      onBlurCapture,
      onChange,
    ]
  );

  return (
    <div className={fullWidth ? 'w-full' : 'w-60'}>
      {label && (
        <label htmlFor={name}>
          <Typography className="mb-1 opacity-80" variant="h5">
            {label}{' '}
            {required && <span className="mx-1 text-error-main">*</span>}
          </Typography>
        </label>
      )}
      {control ? (
        <Controller
          name={name as string}
          control={control as Control}
          render={({
            field: { onChange: controlChange, value: fieldValues },
          }) => getInput(controlChange, fieldValues)}
        />
      ) : (
        getInput()
      )}
      {!disableHelperText && (
        <p
          style={{ minHeight: '1rem' }}
          className={classNames(
            'm-1 text-left text-sm text-background-contrastText ',
            {
              '!text-error-main': !!fieldError?.message || error,
            }
          )}>
          {(fieldError?.message as string) || helperText}
        </p>
      )}
    </div>
  );
}
