import { ErrorBoundary } from '@sentry/react';
import { Navigate, NavLink, Outlet, useLocation } from 'react-router-dom';

import { Typography } from 'components/common/Typography/Typography';
import { RequireAuth } from 'components/functional/RequireAuth/RequireAuth';
import { DEALicenseExpirationReminderTile } from 'components/general/DEALicenseExpirationReminderTile/DEALicenseExpirationReminderTile';
import { ErrorFallbackComponent } from 'components/general/ErrorFallbackComponent/ErrorFallbackComponent';
import { Header } from 'components/general/Header/Header';
import { SwitchLocationButtonWithModal } from 'components/patient/SwitchLocationButtonWithModal/SwitchLocationButtonWithModal';
import { SocketProvider } from 'context/SocketProvider/SocketProvider';

export function AppLayout() {
  const location = useLocation();

  const tabs = [
    // { title: 'Dashboard', link: '/app/dashboard' },
    { title: 'Patients', link: '/app/patients' },
  ];

  if (location?.pathname === '/app') {
    return <Navigate to="./patients" />;
  }

  const isChatScreenOpen = location.pathname === '/app/chat';

  return (
    <ErrorBoundary fallback={ErrorFallbackComponent}>
      <SocketProvider>
        <RequireAuth>
          <div className=" min-h-screen bg-background-dark">
            <div className="container bg-background-dark">
              <div className="py-4">
                <Header />
                {isChatScreenOpen ? null : (
                  <>
                    <DEALicenseExpirationReminderTile />
                    <SwitchLocationButtonWithModal />
                  </>
                )}
                <div
                  className={`mt-3 ml-1 mb-3 flex w-full border-b-2 border-background-light text-background-contrastText ${
                    isChatScreenOpen ? 'hidden' : ''
                  }`}>
                  {tabs.map((tab) => (
                    <NavLink
                      to={tab.link}
                      className={({ isActive }) =>
                        `mr-5 ml-4 inline-block px-4 py-2 text-lg font-bold  ${
                          isActive ? 'text-primary-main ' : 'text-zinc-400 '
                        }`
                      }>
                      <div key={tab.title} className="">
                        <Typography style={{ color: 'inherit' }} variant="h3">
                          {tab.title}
                        </Typography>
                      </div>
                    </NavLink>
                  ))}
                </div>
                <Outlet />
              </div>
            </div>
          </div>
        </RequireAuth>
      </SocketProvider>
    </ErrorBoundary>
  );
}
