/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import { useState } from 'react';

// eslint-disable-next-line import/extensions

import { LegacyRef, useCallback, useEffect, useRef, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';

// eslint-disable-next-line import/extensions
import { TextInput } from 'components/common/Input/TextInput';
import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { Typography } from 'components/common/Typography/Typography';
import { WarningText } from 'components/common/WarningText/WarningText';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { MessagingService } from 'services/messaging';
import { ChatUsersListObject, UploadedFiles } from 'types/socket/types';
// import { useChat } from 'context/ChatProvider';

type ChatInputProps = {
  setInputText: (message: string) => void;
  isMessagingAllowed: boolean;
  selectedUser: ChatUsersListObject;
  onFileUpload: (data: UploadedFiles) => void;
};

export function ChatInput({
  setInputText,
  isMessagingAllowed,
  onFileUpload,
  selectedUser,
}: ChatInputProps) {
  const { control, reset, handleSubmit, setValue, getValues } = useForm();
  const uploadFileMutation = useMutationWithToast(MessagingService.uploadFile);

  const handleSendMessage = handleSubmit(async (data) => {
    setInputText(data.messageInput);
    reset({ messageInput: '' });
    setUploadedFiles([]);
  });

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hiddenFileInput = useRef<HTMLInputElement>();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles[]>([]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];

    if (file) {
      uploadFileMutation.mutate(
        {
          media: file,
          groupId: selectedUser.groupId || '',
        },
        {
          onSuccess: (data) => {
            const fileData = {
              mediaName: file.name,
              mediaType: file.type,
              mediaUrl: data.data.url,
              signedUrl: data.data.signedUrl,
            };
            setUploadedFiles((prevUploaded) => [fileData, ...prevUploaded]);

            onFileUpload(fileData);
          },
        }
      );
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        event.preventDefault();
        setValue('messageInput', `${getValues('messageInput')}\n`);
      } else {
        event.preventDefault();
        handleSendMessage();
        setValue('messageInput', '');
      }
    }
  };

  useEffect(() => {
    reset({ messageInput: '' });
  }, [reset, selectedUser.groupId]);

  const loadingFileUI = useCallback(
    () => (
      <div className="align-center mr-4 mb-3 flex h-10 !w-24 justify-center rounded bg-white bg-opacity-10 pt-2">
        <LoadingSpinner className="text-primary-main" />
      </div>
    ),
    []
  );

  const handleFileDelete = useCallback(
    (index: number) => {
      const tempFiles = [...uploadedFiles];
      tempFiles.splice(index, 1);
      setUploadedFiles(tempFiles);
    },
    [uploadedFiles]
  );

  const renderUploadedFiles = useCallback(
    () =>
      uploadedFiles.length > 0 && (
        <div className="direction-row mb-2 flex items-center">
          {uploadedFiles.map((item, index) => {
            const isImage = item.mediaType.includes('image');

            return (
              <div
                key={item.mediaUrl}
                className={`relative mr-4 cursor-pointer rounded bg-white bg-opacity-10 ${
                  !isImage ? 'p-3' : 'p-0'
                }`}>
                <div
                  className="align-center h-19 w-19 absolute -right-2 -top-2 flex justify-center rounded-full bg-[#000000] p-1"
                  onClick={() => {
                    handleFileDelete(index);
                  }}>
                  <XMarkIcon height={12} width={12} stroke="#ffffff" />
                </div>
                {isImage ? (
                  <img
                    src={item.signedUrl}
                    height={60}
                    className="rounded"
                    width={80}
                    alt={item.mediaName}
                  />
                ) : (
                  <div>
                    <Typography>{item.mediaName}</Typography>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ),
    [handleFileDelete, uploadedFiles]
  );

  return (
    <div className=" border-t border-black p-4 pt-6">
      {!isMessagingAllowed ? (
        <WarningText>
          <div>
            You won’t be able to send messages to this patient because your
            patient is archived.
          </div>
        </WarningText>
      ) : (
        <>
          <div className="ml-8 flex">
            {uploadFileMutation.isLoading ? loadingFileUI() : null}
            {renderUploadedFiles()}
          </div>
          <div className="flex items-center ">
            {/* <div
              className={`mr-3 mb-4 ${
                uploadFileMutation.isLoading
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              }`}>
              <PlusIcon
                stroke="#ffffff"
                height={20}
                width={20}
                onClick={() => hiddenFileInput?.current?.click()}
              />
            </div> */}
            <TextInput
              fullWidth
              onKeyDown={handleKeyDown}
              disabled={!isMessagingAllowed}
              name="messageInput"
              type="text"
              multiline
              rows={2}
              control={control}
              placeholder="Type your message..."
              className="custom-scrollbar -mb-4 w-full resize-none rounded-lg border border-gray-300"
            />
            {/* <Button
              disabled={uploadFileMutation.isLoading}
              onClick={handleSendMessage}
              className="ml-4 mb-3">
              Send
            </Button> */}
          </div>
          <Typography variant="subtitle3" className="-mt-1 text-zinc-400">
            Press Enter to send the message, and Shift + Enter to insert a new
            line.
          </Typography>

          <input
            type="file"
            ref={hiddenFileInput as LegacyRef<HTMLInputElement>}
            onChange={handleFileUpload}
            className="hidden"
            accept="image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt"
          />
        </>
      )}
    </div>
  );
}
