import * as Sentry from '@sentry/react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { ErrorBoundary } from 'components/common/ErrorBoundary/ErrorBoundary';
import { Page } from 'components/common/page/Page';
import { RequireAuth } from 'components/functional/RequireAuth/RequireAuth';
import { RedirectToAuth } from 'components/general/RedirectToAuth/RedirectToAuth';
import { HormoneListing } from 'components/patient/e-prescribeV2/HormoneListing';
import { FollowUpQuestionnaire } from 'components/patient/questionnaire/FollowUpQuestionnaire/FollowUpQuestionnaire';
import { InitialQuestionnaire } from 'components/patient/questionnaire/InitialQuestionnaire';
import { AppLayout } from 'pages/app/AppLayout';
import { ChatContainer } from 'pages/app/chat/ChatContainer/ChatContainer';
import { Dashboard } from 'pages/app/dashboard/Dashboard';
import { Page404 } from 'pages/app/misc/Page404';
import { Checkout } from 'pages/app/patients/[id]/checkout/Checkout';
import { PatientNotes } from 'pages/app/patients/[id]/notes/PatientNotes';
import { Orders } from 'pages/app/patients/[id]/orders/Orders';
import { PatientOverview } from 'pages/app/patients/[id]/overview/Overview';
import { PatientLayout } from 'pages/app/patients/[id]/PatientLayout';
import { CommonStartingOption } from 'pages/app/patients/[id]/prescriptions/prescribe/common-starting-option/CommonStartingOption';
import { EPrescribeNew } from 'pages/app/patients/[id]/prescriptions/prescribe/e-prescribe-new/EPrescribeNew';
import { EPrescribe } from 'pages/app/patients/[id]/prescriptions/prescribe/e-prescribe/EPrescribe';
import { EnhancedStartingOption } from 'pages/app/patients/[id]/prescriptions/prescribe/enhanced-starting-option/EnhancedStartingOption';
import { LighterStartingOption } from 'pages/app/patients/[id]/prescriptions/prescribe/lighter-starting-option/LighterStartingOption';
import { PrescribeLayout } from 'pages/app/patients/[id]/prescriptions/prescribe/PrescribeLayout';
import { ProfileCompletion } from 'pages/app/patients/[id]/ProfileCompletion/ProfileCompletion';
import { Questionnaire } from 'pages/app/patients/[id]/questionnaire/Questionnaire';
import { ActivePatients } from 'pages/app/patients/ActivePatients/ActivePatients';
import { InProgressPatients } from 'pages/app/patients/InProgressPatients/InProgressPatients';
import { InvitedPatients } from 'pages/app/patients/InvitedPatients/InvitedPatients';
import { PatientsListingLayout } from 'pages/app/patients/PatientListingLayout';
import { UserProfile } from 'pages/app/user-profile/UserProfile/UserProfile';
import { UserProfileLayout } from 'pages/app/user-profile/UserProfileLayout';
import { UserSetting } from 'pages/app/user-profile/UserSetting/UserSetting';
import { AuthLayout } from 'pages/auth/AuthLayout';
import { ForgotPassword } from 'pages/auth/forgot-password/ForgotPassword';
import { ProviderOnboard } from 'pages/auth/onboard/ProviderOnboard';
import { ResetPassword } from 'pages/auth/reset-password/ResetPassword';
import { VerifyEmail } from 'pages/auth/verify-email/VerifyEmail';
import { Redirect } from 'pages/redirect/Redirect';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const routes = sentryCreateBrowserRouter([
  {
    path: '/redirect',
    element: (
      <Page title="Login">
        <Redirect />
      </Page>
    ),
  },
  {
    path: '',
    element: (
      <RequireAuth>
        <Navigate to="/app" />
      </RequireAuth>
    ),
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    errorElement: <Page404 />,
    children: [
      {
        path: 'login',
        element: (
          <Page title="Login">
            <RedirectToAuth />
          </Page>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <Page title="Forgot password">
            <ForgotPassword />
          </Page>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <ErrorBoundary>
            <Page title="Reset password">
              <ResetPassword />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'verify-email',
        element: (
          <ErrorBoundary>
            <Page title="Verify email">
              <VerifyEmail />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'onboard',
        element: (
          <ErrorBoundary>
            <Page title="Onboard">
              <ProviderOnboard />
            </Page>
          </ErrorBoundary>
        ),
      },
    ],
  },
  {
    path: '/app',
    element: <AppLayout />,
    errorElement: <Page404 />,
    children: [
      {
        path: 'dashboard',
        element: (
          <ErrorBoundary>
            <Page title="Dashboard">
              <Dashboard />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'chat',
        element: <ChatContainer />,
        errorElement: <Page404 />,
      },

      {
        path: 'patients',
        element: (
          <ErrorBoundary>
            <Page title="Patients">
              <PatientsListingLayout />
            </Page>
          </ErrorBoundary>
        ),
        children: [
          {
            path: 'active',
            element: (
              <ErrorBoundary>
                <Page title="Active Patients">
                  <ActivePatients />
                </Page>
              </ErrorBoundary>
            ),
          },
          {
            path: 'inprogress',
            element: (
              <ErrorBoundary>
                <Page title="In progress Patients">
                  <InProgressPatients />
                </Page>
              </ErrorBoundary>
            ),
          },
          {
            path: 'invited',
            element: (
              <ErrorBoundary>
                <Page title="Invited Patients">
                  <InvitedPatients />
                </Page>
              </ErrorBoundary>
            ),
          },
          {
            path: 'archived',
            element: (
              <ErrorBoundary>
                <Page title="Archived Patients">
                  <ActivePatients showArchivedPatients />
                </Page>
              </ErrorBoundary>
            ),
          },
        ],
      },

      {
        path: 'user',
        element: (
          <ErrorBoundary>
            <UserProfileLayout />
          </ErrorBoundary>
        ),
        children: [
          {
            path: 'profile',
            element: (
              <ErrorBoundary>
                <Page title="Profile">
                  <UserProfile />
                </Page>
              </ErrorBoundary>
            ),
          },
          {
            path: 'settings',
            element: (
              <ErrorBoundary>
                <Page title="Settings">
                  <UserSetting />
                </Page>
              </ErrorBoundary>
            ),
          },
        ],
      },
      {
        path: 'patients/:id',
        element: (
          <ErrorBoundary>
            <PatientLayout />
          </ErrorBoundary>
        ),
        children: [
          {
            path: 'overview',
            element: (
              <ErrorBoundary>
                <Page title="Overview">
                  <PatientOverview />
                </Page>
              </ErrorBoundary>
            ),
          },
          {
            path: 'profile-completion',
            element: (
              <ErrorBoundary>
                <Page title="Profile Completion">
                  <ProfileCompletion />
                </Page>
              </ErrorBoundary>
            ),
          },
          {
            path: 'orders',
            element: (
              <ErrorBoundary>
                <Page title="Orders">
                  <Orders />
                </Page>
              </ErrorBoundary>
            ),
          },
          {
            path: 'prescription',
            children: [
              {
                path: 'prescribe',
                element: (
                  <ErrorBoundary>
                    <Page title="Prescribe">
                      <PrescribeLayout />
                    </Page>
                  </ErrorBoundary>
                ),
                children: [
                  {
                    path: 'e-prescribe-old',
                    element: (
                      <ErrorBoundary>
                        <Page title="E-Prescribe">
                          <EPrescribe />
                        </Page>
                      </ErrorBoundary>
                    ),
                  },
                  {
                    path: 'lighter',
                    element: (
                      <ErrorBoundary>
                        <Page title="Lighter staring Rx">
                          <LighterStartingOption />
                        </Page>
                      </ErrorBoundary>
                    ),
                  },
                  {
                    path: 'common',
                    element: (
                      <ErrorBoundary>
                        {' '}
                        <Page title="Common staring Rx">
                          <CommonStartingOption />
                        </Page>
                      </ErrorBoundary>
                    ),
                  },
                  {
                    path: 'enhanced',
                    element: (
                      <ErrorBoundary>
                        <Page title="Enhanced staring Rx">
                          <EnhancedStartingOption />
                        </Page>
                      </ErrorBoundary>
                    ),
                  },
                  {
                    path: 'e-prescribe',
                    element: (
                      <ErrorBoundary>
                        <Page title="E-Prescribe">
                          <EPrescribeNew />
                        </Page>
                      </ErrorBoundary>
                    ),
                  },
                  {
                    path: 'e-prescribeV2',
                    element: (
                      <ErrorBoundary>
                        <Page title="E-PrescribeV2">
                          <HormoneListing />
                        </Page>
                      </ErrorBoundary>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: 'notes',
            element: (
              <ErrorBoundary>
                <Page title="Notes">
                  <PatientNotes />
                </Page>
              </ErrorBoundary>
            ),
          },
          {
            path: 'checkout',
            element: (
              <ErrorBoundary>
                <Page title="Checkout">
                  <Checkout />
                </Page>
              </ErrorBoundary>
            ),
          },
          // {
          //   path: 'prescription-details',
          //   element: (
          //     <ErrorBoundary>
          //       <Page title="Prescription Details">
          //         <PrescriptionDetailsPage />
          //       </Page>
          //     </ErrorBoundary>
          //   ),
          // },
          {
            path: 'questionnaire',
            element: (
              <ErrorBoundary>
                <Page title="Questionnaire">
                  <Questionnaire />
                </Page>
              </ErrorBoundary>
            ),
            children: [
              {
                path: 'initial-questionnaire',
                element: (
                  <ErrorBoundary>
                    <Page title="Initial Questionnaire">
                      <InitialQuestionnaire />
                    </Page>
                  </ErrorBoundary>
                ),
              },

              {
                path: 'followup-questionnaire',
                element: (
                  <ErrorBoundary>
                    <Page title="Follow up questionnaire">
                      <FollowUpQuestionnaire />
                    </Page>
                  </ErrorBoundary>
                ),
              },
              {
                path: 'annual-assessment',
                element: (
                  <ErrorBoundary>
                    {' '}
                    <Page title="Annual Assessment">
                      <InitialQuestionnaire />
                    </Page>
                  </ErrorBoundary>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
]);
