import { useContext, useState } from 'react';

import { EPrescribeUtilContext } from 'components/patient/e-prescribeV2/HormoneListing';
import { femaleHormoneRxTypes } from 'constants/hormone-details-new';
import { useDTD } from 'hooks/hormones/useDTD';
import { useSIG } from 'hooks/hormones/useSIG';
import { CurrentDoseType } from 'types/patient/prescription';

import { useMaximumDoseHandler } from '../useMaximumDoseHandler';
import { useSigGenerator } from '../useSigGenerator';

export const useBiEstController = () => {
  const BiEstDetails = femaleHormoneRxTypes['bi-est'];
  const [strength, setStrength] = useState(30);
  const [ratio, setRatio] = useState(0);
  const [dose, setDose] = useState(0);
  const [refills, setRefills] = useState(0);
  const { dtd, setDTD, DTDValues } = useDTD(15);

  const { sig, setSig } = useSIG('');
  const [note, setNote] = useState('');
  const [currentDose, setCurrentDose] = useState<CurrentDoseType>();
  const { renderModal } = useContext(EPrescribeUtilContext);

  const [strengthOptions, setStrengthOptions] = useState([30, 60]);

  // the maximum daily dose slider should automatically be 0.15 higher than the dose slider
  const marginValue = 3;

  const {
    maximumDose,
    setMaximumDose,
    setMaxDosePreValue,
    maxDoseOptions,
    maxDosePreValue,
  } = useMaximumDoseHandler({
    dose,
    incrementalValue: marginValue,
    doses: BiEstDetails.dose,
  });
  const { generatedSIG, setGeneratedSIG } = useSigGenerator({
    dose,
    maximumDose,
    doseOptions: BiEstDetails?.dose,
    marginValue,
  });

  return {
    BiEstDetails,

    sig,
    setSig,
    setDTD,
    DTDValues,

    dtd,
    strength,
    setStrength,
    ratio,
    setRatio,
    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
    currentDose,
    setCurrentDose,
    renderModal,
    generatedSIG,
    setGeneratedSIG,
    strengthOptions,
    setStrengthOptions,
    marginValue,
    setMaxDosePreValue,
    maxDoseOptions,
    maxDosePreValue,
  };
};
