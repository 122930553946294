import React, { useCallback, useState } from 'react';

import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import { Button } from 'components/common/Button/Button';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { WarningText } from 'components/common/WarningText/WarningText';
import { DTDSelector } from 'components/prescriptions/DTDSelector';
import { InfoDisplayBox } from 'components/prescriptions/InfoDisplay';
import { LOESelector } from 'components/prescriptions/LOESelector';
import { PrescriptionSlider } from 'components/prescriptions/PrescriptionSlider';
import { RadioSelector } from 'components/prescriptions/RadioSelector';
import { SelectorCheckbox } from 'components/prescriptions/SelectorCheckbox';
import { SigGenerator } from 'components/prescriptions/SigGenerator';
import { SIGTextInput } from 'components/prescriptions/SIGTextInput';
import { useProfile } from 'hooks/useProfile';
import {
  HormoneDetailsUIContainerType,
  HormoneValidations,
  HormoneValidationType,
  PrescribedObjectType,
  PrescriptionDisplayTypes,
  PrescriptionInputObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { DoseSplitter } from './DoseSplitter';
import { PrescriptionReviewModal } from './PrescriptionReviewModal';

type HormoneDetailsUIProps = {
  className?: string;
  title: string;
  onAdd: () => void;
  onBack: () => void;
  hormoneInputs: HormoneDetailsUIContainerType[];

  prescriptionObject?: PrescribedObjectType;
  validations: HormoneValidationType;
};

export function HormoneDetailsUIV2({
  hormoneInputs,
  title,
  className,
  onAdd,
  onBack,

  prescriptionObject,
  validations,
}: HormoneDetailsUIProps) {
  // const { address } = useOutletContext<PatientDetailsContextType>();
  const { profile } = useProfile();
  const [prescriptionReviewModal, setPrescriptionReviewModal] = useState(false);

  const getAddToCartButtonTooltip = useCallback(() => {
    if (validations[HormoneValidations.Loading]?.isFailed) {
      return 'Loading';
    }

    if (profile?.providerDetails?.providerLicence?.length === 0) {
      return 'No license found. Please add a license to your profile.';
    }

    if (validations[HormoneValidations.EarlyRefillAlreadyOrdered]?.isFailed) {
      return 'You have already ordered this prescription.';
    }

    if (validations[HormoneValidations.LOENotSelected]?.isFailed) {
      return 'Please select the appropriate letter of explanation';
    }

    // if (validations[HormoneValidations.MissingDEA]?.isFailed) {
    //   return `The DEA number is missing for the state of ${address?.state?.name}. Prescribing controlled substances without a DEA number is not permitted.`;
    // }

    if (validations[HormoneValidations.DaysOfSupply]?.isFailed) {
      return 'The prescription is outside the acceptable range for days of supply.';
    }
    if (validations[HormoneValidations.SIGExceed140Chars]?.isFailed) {
      return 'The entered SIG must be 140 characters or fewer.';
    }

    return '';
  }, [validations, profile?.providerDetails?.providerLicence?.length]);

  //
  const getInput = useCallback(
    (item: PrescriptionInputObjectType, index: number) => {
      switch (item.type) {
        case PrescriptionInputTypes.Slider:
          return (
            <div
              className={classNames(
                'pl-4',
                {
                  '!mt-0': index === 0,
                },
                item.wrapperClassName
              )}>
              {item.options?.length <= 1 ? (
                <div className="col-span-9 -mt-4 grid grid-cols-9">
                  {' '}
                  <div className="col-span-7 xl:col-span-8">
                    <Typography
                      variant="h5"
                      color="white"
                      className="  max-w-min whitespace-nowrap rounded bg-primary-dark  p-2">
                      {item.getTooltip(0)}
                    </Typography>
                  </div>
                </div>
              ) : (
                <PrescriptionSlider
                  label={item.label}
                  value={item.value}
                  onChange={item.onChange}
                  getTooltip={item.getTooltip}
                  options={item.options}
                  disable={item.disable}
                />
              )}
            </div>
          );

        case PrescriptionInputTypes.SIG:
          return (
            <div
              className={classNames(
                '',
                {
                  '!mt-0': index === 0,
                },
                item.wrapperClassName
              )}>
              <SIGTextInput
                setError={() => {
                  //
                }}
                multiline
                showLabel={false}
                error={
                  validations[HormoneValidations.SIGExceed140Chars]?.isFailed
                }
                value={item.value}
                onChange={item.onChange}
                helperText="SIG must be under 140 characters."

                // doseOptions={item.doseOptions}
                // doseValue={item.doseValue}
                // maximumDoseValue={item.maximumDoseValue}
                // sigUnit={item?.sigUnit}
                // unit={item?.unit}
              />
            </div>
          );

        case PrescriptionInputTypes.DTD:
          return (
            <div
              className={classNames(
                '',
                {
                  '!mt-0': index === 0,
                },
                item.wrapperClassName
              )}>
              <DTDSelector
                label={item.label}
                value={item.value}
                DTDValues={item.DTDValues}
                onChange={item.onChange}
                unit={item.unit}
                showLabel={false}
              />
            </div>
          );

        case PrescriptionInputTypes.SelectorCheckbox:
          return (
            <div
              className={classNames(
                '',
                {
                  '!mt-0 ': index === 0,
                },
                item.wrapperClassName
              )}>
              <SelectorCheckbox
                label={item.label}
                value={item.value}
                options={item.options}
                onChange={item.onChange}
                unit={item.unit}
              />
            </div>
          );

        case PrescriptionInputTypes.Radio:
          return (
            <div
              className={classNames(
                '',
                {
                  '!mt-0': index === 0,
                },
                item.wrapperClassName
              )}>
              <RadioSelector
                label={item.label}
                value={item.value}
                options={item.options}
                onChange={item.onChange}
              />
            </div>
          );

        case PrescriptionDisplayTypes.InfoBox:
          return (
            <div
              className={classNames(
                '',
                {
                  '!mt-0': index === 0,
                },
                item.wrapperClassName
              )}>
              <InfoDisplayBox
                className={item.className}
                value={item.value}
                isLoading={item.isLoading}
                label={item.label}
                colorClass={item.colorClass}
                helperText={item.helperText}
              />
            </div>
          );

        case PrescriptionDisplayTypes.WarningMessage:
          return (
            <div
              className={classNames(
                '',
                {
                  '!mt-0': index === 0,
                  '!hidden': item.hide,
                },
                item.wrapperClassName
              )}>
              <WarningText className={item.className}>{item.text}</WarningText>
            </div>
          );

        case PrescriptionInputTypes.DoseSplitter:
          return (
            <div
              className={classNames(
                '',
                {
                  '!mt-0': index === 0,
                },
                item.wrapperClassName
              )}>
              <DoseSplitter
                hormoneName={item.hormoneName}
                onChange={item.onChange}
                value={item.value}
              />
            </div>
          );

        case PrescriptionInputTypes.LOESelector:
          return (
            <div
              className={classNames(
                '',
                {
                  '!mt-0': index === 0,
                  '!hidden': item.hide,
                },
                item.wrapperClassName
              )}>
              <LOESelector
                loading={item.loading}
                onLOEselect={item.onLOEselect}
                onCheck={item.onCheck}
                value={item.value}
                hide={item.hide}
              />
            </div>
          );

        case PrescriptionDisplayTypes.ElementContainer:
          return (
            <div
              className={classNames(
                '',
                {
                  '!mt-0': index === 0,
                },
                item.wrapperClassName
              )}>
              <div className={item.containerClassName}>
                {item.children.map((inputItem, i) => getInput(inputItem, i))}
              </div>
            </div>
          );

        case PrescriptionInputTypes.SigGenerator:
          return (
            <div
              className={classNames(
                '',
                {
                  '!mt-0': index === 0,
                },
                item.wrapperClassName
              )}>
              <SigGenerator
                error={false}
                setError={() => {
                  //
                }}
                value={item.value}
                onChange={item.onChange}
                doseOptions={[
                  ...item.doseOptions.slice(
                    item.doseValue,
                    item.doseOptions.length
                  ),
                ]}
                sigUnit={item.sigUnit}
                unit={item.unit}
                doseValue={item.doseValue}
                maximumDoseValue={item.maximumDoseValue}
                maximumDoseOnChange={item.maximumDoseOnChange}
              />
            </div>
          );

        default:
          return '';
      }
    },
    [validations]
  );

  const getContainerUI = useCallback(
    (item: HormoneDetailsUIContainerType) => (
      <div className="flex flex-col gap-2">
        {item?.title && <Typography variant="h4">{item.title}</Typography>}
        <div className={item.containerClassName}>
          {item.children.map((inputItem, index) => getInput(inputItem, index))}
        </div>
      </div>
    ),
    [getInput]
  );

  return (
    <div className="relative flex h-full w-full  flex-col  pt-6 pl-10 pr-6">
      <div className=" flex items-center gap-6 ">
        <div className="mt-1 flex items-center">
          <Button onClick={onBack} className="!px-0 !pl-4" variant="text">
            <ArrowLeftIcon className=" clh-6  w-6 text-background-contrastText " />
          </Button>
        </div>
        <div>
          <Typography variant="h2" color="textPrimary">
            You are currently prescribing
            <Typography
              variant="h2"
              color="white"
              className="mx-2 rounded-sm bg-primary-main px-2">
              {prescriptionObject?.standardizedName ?? title}
            </Typography>
          </Typography>
        </div>
      </div>
      <div className={classNames('mt-12 flex flex-col gap-6 pb-4', className)}>
        {hormoneInputs?.map((item) => getContainerUI(item))}
      </div>

      <div className="flex gap-10 pb-20">
        <Button color="secondary" className="!px-12" onClick={() => onBack()}>
          Go Back
        </Button>
        <Button
          data-tooltip-id="addToCartButton"
          className="!px-12"
          disabled={!!getAddToCartButtonTooltip()}
          onClick={() => setPrescriptionReviewModal(true)}>
          Add to cart
        </Button>
        {!!getAddToCartButtonTooltip() && (
          <Tooltip className="" id="addToCartButton">
            {getAddToCartButtonTooltip()}
          </Tooltip>
        )}
      </div>
      {/* <ConfirmationModal
        isModalOpen={showChangeModal.state}
        closeModal={() => {
          setshowChangeModal({ ...showChangeModal, state: false });
        }}>
        <div className="py-4">{showChangeModal.text}</div>
      </ConfirmationModal> */}
      <PrescriptionReviewModal
        item={prescriptionObject}
        isOpen={prescriptionReviewModal}
        onCancel={() => {
          setPrescriptionReviewModal(false);
        }}
        onConfirm={() => {
          setPrescriptionReviewModal(false);
          onAdd();
        }}
      />
    </div>
  );
}
