import React, { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { HarsStats } from 'components/patient/HarsStats/HarsStats';
import { CurrentHormonalStateChart } from 'components/patient/overview/CurrentHormonalStateChart/CurrentHormonalStateChart';
import { FollowUpScheduler } from 'components/patient/overview/FollowUpSchedular';
import { HormonalEffectDataCharts } from 'components/patient/overview/HormonalDataCharts/HormonalEffectDataCharts';
import { OverviewTable } from 'components/patient/overview/OverviewTable';
import { QueryKeys } from 'constants/query-keys';
import { PatientService } from 'services/patient';

export function PatientOverview() {
  const { id } = useParams();

  const getPatientDetailsQuery = useQuery(
    QueryKeys.PatientDetails.item(id as string),
    () => PatientService.getPatientDetails(id || ''),
    {
      enabled: !!id,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const showQuestionnaireRelatedUI = useMemo(
    () =>
      getPatientDetailsQuery.data?.data.patientDetails.isQuestionaireRequired &&
      getPatientDetailsQuery.data?.data.patientDetails.isQuestionaireCompleted,
    [getPatientDetailsQuery.data?.data.patientDetails]
  );

  return (
    <div className="scrollbar-hide mt-2 -mb-10 h-full overflow-auto rounded-b-md p-2 pb-32">
      {' '}
      <div className="mt-8" />
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-6">
          <OverviewTable title="Active Rx(s)" category="current" />{' '}
        </div>
        <div className="col-span-6">
          <OverviewTable title="Previous Rx(s)" category="past" />{' '}
        </div>
      </div>
      <div className="mt-8" />
      <div className="flex gap-2">
        <FollowUpScheduler showErrorMessage={!showQuestionnaireRelatedUI} />

        <HarsStats />
      </div>
      <div className="mt-8" />
      {showQuestionnaireRelatedUI && (
        <>
          <div className="flex w-full gap-2">
            <CurrentHormonalStateChart />
            <HormonalEffectDataCharts />
          </div>
          <div className="mt-8" />
        </>
      )}
      <div className="mt-8" />
    </div>
  );
}
