import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { ThemeName } from 'theme/types';

export function PatientListSkeleton() {
  const dummyPatientArrayLength = 10;

  const skeletonArray = new Array(dummyPatientArrayLength).fill(null);

  return (
    <div>
      {skeletonArray.map((_, index) => (
        <SkeletonContent
          // eslint-disable-next-line react/no-array-index-key
          key={index} // Use index as key
          theme={ThemeName.Dark}
          containerClassName="w-full"
          isLoading
          layout={[
            {
              className: 'w-full h-10 my-3.5 gap-5 my-4',
            },
          ]}
        />
      ))}
    </div>
  );
}
