import { useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Typography } from 'components/common/Typography/Typography';
import { PatientInfo } from 'components/patient/PatientInfo/PatientInfo';
import { QueryKeys } from 'constants/query-keys';
import { useProfile } from 'hooks/useProfile';
import { PatientService } from 'services/patient';
import { Gender, PatientDetailsContextType } from 'types/patient';
import { checkJSON, formatPhoneNumber, gender } from 'utils/common';

export function PatientLayout() {
  // const queryClient = useQueryClient();
  const { id } = useParams();
  const { profile } = useProfile();
  const location = useLocation();
  const navigate = useNavigate();
  const [redirectionDone, setRedirectionDone] = useState(false);

  const getPatientDiagnosisCodesQuery = useQuery(
    QueryKeys.PatientDiagnosisCode.listing({ id }),
    () => PatientService.getPatientDiagnosisCodes(id as string),
    {
      enabled: !!id,
      refetchOnMount: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const getPatientAllergiesMedicationQuery = useQuery(
    QueryKeys.PatientAllergiesMedication.item(id as string),
    () => PatientService.getPatientAllergiesMedication(id as string),
    {
      enabled: !!id,
      refetchOnMount: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const getPatientDetailsQuery = useQuery(
    QueryKeys.PatientDetails.item(id as string),
    () => PatientService.getPatientDetails(id || ''),
    {
      enabled: !!id,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const getPatientTodoDetailsQuery = useQuery(
    QueryKeys.PatientTodoDetails.item(id || ''),
    () => PatientService.getPatientTodos(id ?? ''),
    {
      refetchOnWindowFocus: false,
    }
  );

  const hasDeaForShippingState = useMemo(
    () =>
      !!profile?.providerDetails.providerDea?.find(
        (d) =>
          d.stateId ===
          getPatientDetailsQuery.data?.data?.patientShippingAddress?.state?.id
      )?.dea,
    [
      getPatientDetailsQuery.data?.data?.patientShippingAddress?.state?.id,
      profile?.providerDetails?.providerDea,
    ]
  );

  // eslint-disable-next-line

  function showTitrationOptions() {
    if (
      getPatientDetailsQuery?.data?.data?.patientDetails?.gender ===
        Gender.Female &&
      (getPatientDetailsQuery.data?.data?.patientCompletedVisits === null ||
        getPatientDetailsQuery.data?.data?.patientCompletedVisits === 0)
    ) {
      return true;
    }

    return false;
  }

  const patientInfo: PatientDetailsContextType = useMemo(
    () => ({
      id: getPatientDetailsQuery?.data?.data?.id || 'NA',

      firstName: getPatientDetailsQuery?.data?.data?.firstName ?? '',
      lastName: getPatientDetailsQuery?.data?.data?.lastName ?? '',
      middleName: getPatientDetailsQuery?.data?.data?.middleName ?? '',

      email: getPatientDetailsQuery?.data?.data?.email || 'NA',
      phone:
        formatPhoneNumber(getPatientDetailsQuery?.data?.data?.phone) || 'NA',
      gender:
        gender[
          getPatientDetailsQuery?.data?.data?.patientDetails?.gender || 'blank'
        ],
      dob: getPatientDetailsQuery?.data?.data?.patientDetails?.dob || 'NA',
      completedVisits:
        getPatientDetailsQuery?.data?.data?.patientCompletedVisits || 0,
      allergies: 'NA',
      picture: getPatientDetailsQuery?.data?.data?.pictureUrl || undefined,
      patientSsnNumber:
        getPatientDetailsQuery?.data?.data?.patientSsn?.ssnNumber || undefined,
      patientDriversLicense:
        getPatientDetailsQuery?.data?.data?.patientDriversLicense || undefined,
      diagnosisHormone: getPatientDiagnosisCodesQuery.data?.data.find(
        (i) => i.diagnosisCode.type === 'hormone'
      ),
      diagnosisThyroid: getPatientDiagnosisCodesQuery.data?.data.find(
        (i) => i.diagnosisCode.type === 'thyroid'
      ),
      patientKnownAllergiesByPatient:
        getPatientAllergiesMedicationQuery?.data?.data?.patientKnownAllergies ||
        'NA',
      patientOtherMedicationsByPatient:
        getPatientAllergiesMedicationQuery?.data?.data
          ?.patientOtherMedications || 'NA',

      patientKnownAllergiesByProvider: checkJSON(
        getPatientAllergiesMedicationQuery?.data?.data
          ?.patientKnownAllergiesNc || ''
      )
        ? JSON.parse(
            getPatientAllergiesMedicationQuery?.data?.data
              ?.patientKnownAllergiesNc || ''
          )
        : undefined,

      patientOtherMedicationsByProvider: checkJSON(
        getPatientAllergiesMedicationQuery?.data?.data
          ?.patientOtherMedicationsNc || ''
      )
        ? JSON.parse(
            getPatientAllergiesMedicationQuery?.data?.data
              ?.patientOtherMedicationsNc || ''
          )
        : undefined,

      address: getPatientDetailsQuery.data?.data.patientShippingAddress,
      isArchivedPatient: !getPatientDetailsQuery.data?.data.isActive,
      ...(!!getPatientDetailsQuery.data?.data?.invitedBy?.name &&
        getPatientDetailsQuery.data?.data?.invitedBy?.role?.name ===
          'Staff' && {
          invitedBy: { ...getPatientDetailsQuery.data?.data?.invitedBy },
        }),
    }),
    [
      getPatientDetailsQuery,
      getPatientDiagnosisCodesQuery,
      getPatientAllergiesMedicationQuery,
    ]
  );
  const tabs = (isTodoCompleted: boolean) => [
    {
      title: 'Overview',
      link: 'overview',
      showToArchivedPatients: true,
      visible: !!isTodoCompleted,
    },
    {
      title: 'Profile Completion',
      link: 'profile-completion',
      visible: !isTodoCompleted,
      showToArchivedPatients: true,
    },
    {
      title: 'Orders',
      link: 'orders',
      showToArchivedPatients: true,
      visible: !!isTodoCompleted,
    },
    {
      title: 'E-Prescribe',
      link: showTitrationOptions()
        ? 'prescription/prescribe'
        : 'prescription/prescribe/e-prescribev2',
      showToArchivedPatients: false,
      visible: !!isTodoCompleted,
    },
    // {
    //   title: 'E-Prescribe-v2',
    //   link: 'prescription/prescribe/e-prescribev2',
    //   showToArchivedPatients: false,
    //   visible: !!isTodoCompleted,
    // },
    {
      title: 'Notes',
      link: 'notes',
      showToArchivedPatients: true,
      visible: !!isTodoCompleted,
    },
    {
      title: 'Questionnaire(s)',
      link: 'questionnaire',
      showToArchivedPatients: true,
      visible:
        !!getPatientDetailsQuery.data?.data?.patientDetails
          ?.isQuestionaireRequired,
    },
  ];

  const isTodoCompleted = useMemo(
    () =>
      getPatientTodoDetailsQuery.data?.data.billingAddress &&
      getPatientTodoDetailsQuery.data?.data.driverLicenceOrSsn &&
      getPatientTodoDetailsQuery.data?.data.questionnaire &&
      getPatientTodoDetailsQuery.data?.data.shippingAddress &&
      (!!getPatientTodoDetailsQuery.data?.data?.legalsAccepted ||
        getPatientTodoDetailsQuery.data?.data?.legalsAccepted === null),
    [getPatientTodoDetailsQuery]
  );

  useEffect(() => {
    if (
      location?.pathname.includes('/checkout') ||
      !getPatientTodoDetailsQuery.isFetched ||
      redirectionDone ||
      location?.pathname.includes('e-prescribev2')
    ) {
      return;
    }
    setRedirectionDone(true);
    navigate(isTodoCompleted ? 'overview' : 'profile-completion');
  }, [
    isTodoCompleted,
    getPatientTodoDetailsQuery.isFetched,
    location?.pathname,
    navigate,
    redirectionDone,
  ]);

  if (location?.pathname.includes('/checkout')) {
    return (
      <Outlet
        context={{
          ...patientInfo,
          gender: getPatientDetailsQuery?.data?.data?.patientDetails?.gender,
        }}
      />
    );
  }

  if (location?.pathname.includes('/prescription-details')) {
    return (
      <Outlet
        context={{
          ...patientInfo,
          gender: getPatientDetailsQuery?.data?.data?.patientDetails?.gender,
        }}
      />
    );
  }

  return (
    <div className="pb-6">
      <SkeletonContent
        isLoading={
          getPatientDetailsQuery?.isLoading ||
          getPatientDiagnosisCodesQuery.isLoading ||
          getPatientAllergiesMedicationQuery.isLoading ||
          getPatientTodoDetailsQuery.isLoading
        }
        layout={[
          {
            className: 'grid gap-4 ',
            children: [
              {
                className:
                  'grid grid-cols-[12] items-center gap-x-10 gap-4 rounded-md bg-background-main p-6',
                children: [
                  {
                    className: 'flex gap-6',
                    children: [
                      { className: 'w-32 h-32 !rounded-full' },
                      {
                        className: ' flex gap-6 items-center  ',
                        children: [
                          {
                            className: 'grid gap-y-4',
                            children: new Array(3)
                              .fill(0)
                              .map(() => ({ className: 'h-4 w-[250px] ' })),
                          },
                          { className: 'w-1 h-32 ' },
                          {
                            className: 'grid gap-y-4',
                            children: new Array(3)
                              .fill(0)
                              .map(() => ({ className: 'h-4 w-[250px] ' })),
                          },
                          { className: 'w-1 h-32 ' },
                          {
                            className: 'grid gap-y-4',
                            children: new Array(3)
                              .fill(0)
                              .map(() => ({ className: 'h-4 w-[250px] ' })),
                          },
                        ],
                      },
                    ],
                  },

                  {
                    className: 'mt-4 grid row-start-2 gap-6',
                    children: new Array(2).fill(0).map(() => ({
                      className: 'grid gap-5',
                      children: [
                        {
                          className: 'mt-2 h-4 w-[150px]',
                        },
                        {
                          className: 'grid gap-2',
                          children: new Array(2)
                            .fill(0)
                            .map(() => ({ className: 'h-4 w-[600px]' })),
                        },
                      ],
                    })),
                  },

                  {
                    className: 'mt-4 grid  row-start-4 gap-4 col-span-1 w-full',
                    children: new Array(1)
                      .fill(0)
                      .map(() => ({ className: 'h-20 w-full ' })),
                  },
                ],
              },
            ],
          },
          {
            className: 'mt-1 flex h-12 bg-background-light rounded-none',
          },
        ]}>
        {() => (
          <>
            <PatientInfo
              details={{
                ...patientInfo,

                patientLicense:
                  getPatientDetailsQuery?.data?.data.patientDriversLicense,
                patientSsn: getPatientDetailsQuery?.data?.data?.patientSsn,
                isActive: getPatientDetailsQuery.data?.data.isActive || false,
                patientSubscriptionMeta:
                  !!getPatientDetailsQuery.data?.data?.patientSubscriptionMeta,
                providerDetails: {
                  firstName:
                    getPatientDetailsQuery.data?.data?.patientsProvider
                      ?.firstName ?? '',
                  middleName:
                    getPatientDetailsQuery.data?.data?.patientsProvider
                      ?.middleName ?? '',
                  lastName:
                    getPatientDetailsQuery.data?.data?.patientsProvider
                      ?.lastName ?? '',
                  providerSuffix:
                    getPatientDetailsQuery.data?.data?.patientsProvider
                      ?.providerDetails?.providerSuffix,
                },
                practiceDetails: {
                  name: getPatientDetailsQuery.data?.data?.practice?.name ?? '',
                  practiceAddress:
                    getPatientDetailsQuery.data?.data?.practiceAddress,
                },
              }}
              onRefetch={() => {
                getPatientDetailsQuery.refetch();
              }}
            />
            <div className=" mt-[2px] flex w-full bg-background-light shadow-md ">
              {tabs(!!isTodoCompleted).map((tab) => {
                if (
                  (!tab.showToArchivedPatients &&
                    !getPatientDetailsQuery.data?.data.isActive) ||
                  !tab.visible
                ) {
                  return null;
                }

                return (
                  <NavLink
                    style={{ display: tab.visible ? 'block' : 'none' }}
                    to={{ pathname: tab.link }}
                    className={({ isActive }) =>
                      classNames(
                        `inline-block  whitespace-nowrap px-4 py-3  text-md font-semibold  ${
                          isActive ? 'text-primary-main ' : 'text-zinc-400 '
                        }`,
                        {
                          'pointer-events-none':
                            getPatientDetailsQuery.isLoading,
                        }
                      )
                    }>
                    <div
                      key={tab.title}
                      className={`inline-block pr-20 text-lg font-bold   ${
                        tab.title === 'Overview' && 'ml-4'
                      }`}>
                      <Typography style={{ color: 'inherit' }} variant="h3">
                        {tab.title}
                      </Typography>
                    </div>
                  </NavLink>
                );
              })}
            </div>

            <Outlet
              context={{
                ...patientInfo,
                gender:
                  getPatientDetailsQuery?.data?.data?.patientDetails?.gender,
                hasDeaForShippingState,
              }}
            />
          </>
        )}
      </SkeletonContent>
    </div>
  );
}
