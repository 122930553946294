import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { ThemeName } from 'theme/types';

const dummyChatArray = [
  {
    mid: '1',
    message: '',
    isOutgoing: true,
    width: 4,
    height: 1,
  },
  {
    mid: '2',
    message: '',
    isOutgoing: true,
    width: 3,
    height: 2,
  },
  {
    mid: '3',
    message: '',
    isOutgoing: true,
    width: 4,
    height: 4,
  },
  {
    mid: '4',
    message: '',
    isOutgoing: false,
    width: 4,
    height: 3,
  },
  {
    mid: '5',
    message: '',
    isOutgoing: true,
    width: 4,
    height: 1,
  },
  {
    mid: '6',
    message: '',
    isOutgoing: true,
    width: 4,
    height: 3,
  },
  {
    mid: '7',
    message: '',
    isOutgoing: false,
    width: 7,
    height: 2,
  },
  {
    mid: '8',
    message: '',
    isOutgoing: false,
    width: 8,
    height: 2,
  },
];

export function MessagesContainerSkeleton() {
  return (
    <div className=" flex flex-grow flex-col justify-end p-4">
      {dummyChatArray.map((msg) => (
        <div
          key={msg.mid}
          className={`mb-4 flex w-full  flex-col justify-end ${
            msg.isOutgoing ? 'items-end' : 'items-start'
          }`}>
          <div
            className={`flex items-end   p-1 ${
              !msg.isOutgoing ? 'flex-row' : 'flex-row-reverse'
            }`}>
            <SkeletonContent
              // eslint-disable-next-line react/no-array-index-key
              key={msg.mid} // Use index as key
              theme={ThemeName.Dark}
              isLoading
              layout={[
                {
                  className: `rounded-t-2xl w-${msg.width * 8} h-${
                    msg.height * 8
                  } ${msg.isOutgoing ? 'rounded-bl-2xl' : 'rounded-br-2xl'} ${
                    msg.isOutgoing ? 'bg-[#8E8E8E]' : 'bg-[#494949]'
                  } bg-primary-main p-3`,
                },
              ]}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
