import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { XCircleIcon } from '@heroicons/react/24/solid';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Controller, useWatch } from 'react-hook-form';

import { Button } from 'components/common/Button/Button';
import { DayPickerWithInput } from 'components/common/DatePicker/DatePicker';
import { DropDown } from 'components/common/DropDown/DropDown';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { WarningText } from 'components/common/WarningText/WarningText';
import { QueryKeys } from 'constants/query-keys';
import { updateManagerDetailsSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { UserService } from 'services/user';
import { ThemeName } from 'theme/types';
import { Option } from 'types/inputTypes';
import { Gender } from 'types/patient';
import { gender, isOlderThan18Years } from 'utils/common';

type UpdateEmailProps = {
  type: string;
  modal: boolean;
  closeModal: (state: boolean) => void;
  preValue?: string;
  onSuccess: () => void;
  defaultProviderTitle?: string;
};
type EmailFormType = {
  email?: string;
  name?: string;
  dob?: string;
  gender?: string;
  link?: string;
};

type ModalData = {
  title: string;
  field?: ReactNode;
};
export function UpdateManagerDetailsModal({
  type,
  modal,
  closeModal,
  preValue,
  onSuccess,
  defaultProviderTitle,
}: UpdateEmailProps) {
  const [dobDatePicker, setDobDatePicker] = useState(false);

  const [providerTitle, setProviderTitle] = useState<Option>({
    title: defaultProviderTitle ?? '',
    value: defaultProviderTitle ?? '',
  });

  const updateManagerDetailsMutation = useMutationWithToast(
    UserService.updateProfile
  );

  const { handleSubmit, control, reset, setError, clearErrors, errors } =
    useFormWithErrors<EmailFormType>({
      mutation: updateManagerDetailsMutation,
      schema: updateManagerDetailsSchema(type),
    });

  const calendarLink = useWatch({
    control,
    name: 'link',
  });

  const getProviderSuffixesQuery = useQuery(
    [QueryKeys.ProviderSuffixes],
    () => UserService.getProviderSuffixes(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const onSubmit = handleSubmit((data) => {
    updateManagerDetailsMutation.mutate(
      {
        ...(type === 'Email' && { officeManagerEmail: data?.email || '' }),
        ...(type === 'CalendarLink' && {
          calendarLink: data?.link || '',
        }),
        ...(type === 'CalendarLink' &&
          !data?.link && {
            deleteCalendarLink: true,
          }),
        ...(type === 'Name' && { officeManagerName: data?.name || '' }),
        ...(type === 'Title' && { providerSuffix: providerTitle?.title || '' }),
        ...(type === 'DOB' && {
          dob: format(new Date(data.dob || ''), 'yyyy-MM-dd'),
        }),
        ...(type === 'Gender' && { gender: data?.gender || '' }),
      },
      {
        onSuccess: () => {
          onSuccess();
          reset();
          closeModal(false);
        },
      }
    );
  });
  const updateProviderTitle = () => {
    updateManagerDetailsMutation.mutate(
      {
        providerSuffix: providerTitle?.title || '',
      },
      {
        onSuccess: () => {
          onSuccess();
          reset();
          closeModal(false);
        },
      }
    );
  };

  const modalData: { [x: string]: ModalData } = useMemo(
    () => ({
      Name: {
        title: 'Office Manager Name',
        field: (
          <TextInput
            className="pl-2 "
            type="text"
            name="name"
            control={control}
            placeholder="Enter name"
            defaultValue={preValue}
          />
        ),
      },
      Email: {
        title: 'Office Manager Email',
        field: (
          <TextInput
            className="pl-2 "
            type="text"
            name="email"
            control={control}
            placeholder="Enter email"
            defaultValue={preValue}
          />
        ),
      },
      Title: {
        title: 'Provider Title',
        field: (
          <DropDown
            theme={ThemeName.Light}
            options={getProviderSuffixesQuery.data?.data.suffixes.map((s) => ({
              title: s,
              value: s,
            }))}
            defaultValue={providerTitle}
            onChange={(d) => setProviderTitle(d)}
          />
        ),
      },
      CalendarLink: {
        title: 'Telehealth/Appointment booking link',
        field: (
          <div className="relative flex flex-col ">
            {' '}
            <TextInput
              fullWidth
              className=" pl-2 "
              type="text"
              name="link"
              control={control}
              placeholder="Enter calendar link"
              defaultValue={preValue}
            />
            {!!calendarLink && (
              <XCircleIcon
                onClick={() => {
                  reset({ link: '' });
                }}
                className="absolute right-1 top-1 h-10 w-10 cursor-pointer  bg-background-light p-2 text-[#515151]"
              />
            )}
            <WarningText className="mb-4 -mt-1 !gap-2 !bg-opacity-80 !py-1">
              Please verify the calendar link you entered.
            </WarningText>
          </div>
        ),
      },
      Gender: {
        title: 'Gender',
        field: (
          <DropDown
            theme={ThemeName.Light}
            className="w-full "
            control={control}
            name="gender"
            fullWidth
            placeholder="Select gender"
            defaultValue={{
              title: gender[(preValue as Gender) ?? 'blank'],
              value: preValue ?? '',
            }}
            options={[
              { title: 'Male', value: 'M' },
              { title: 'Female', value: 'F' },
            ]}
          />
        ),
      },
      DOB: {
        title: 'DOB',
        field: (
          <Controller
            defaultValue={preValue}
            control={control}
            name="dob"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DayPickerWithInput
                selectedDate={value as unknown as Date}
                open={dobDatePicker}
                placeholder="Select DOB"
                toggleDayPicker={(s) => {
                  setDobDatePicker(s);
                }}
                label=""
                withInput
                theme={ThemeName.Dark}
                helperText={error?.message}
                error={!!error?.message}
                onDateSelect={(date) => {
                  if (isOlderThan18Years(date as Date)) {
                    onChange({
                      target: { value: date },
                    });
                    clearErrors('dob');
                  } else {
                    onChange({
                      target: { value: date },
                    });
                    setError('dob', {
                      message: 'Age must be between 18 and 100 years',
                    });
                  }
                  setDobDatePicker(false);
                }}
              />
            )}
          />
        ),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preValue, calendarLink, errors]
  );

  useEffect(() => {
    clearErrors();
  }, [clearErrors, type]);

  useEffect(() => {
    if (preValue) {
      reset({ link: preValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preValue, reset, modal]);

  return (
    <div>
      <Modal
        title={modalData?.[type]?.title}
        open={modal}
        className="w-2/5 overflow-visible"
        closeModal={closeModal}>
        <div className="mt-4">
          {modalData?.[type]?.field}
          <div>
            <div className={classNames('mt-2 mt-6 flex justify-end')}>
              {/* <Button
                loading={deleteButtonLoading}
                className={classNames('invisible', {
                  '!visible': type === 'CalendarLink' && preValue,
                })}
                color="error"
                variant="contained"
                onClick={() => {
                  setDeleteButtonLoading(true);
                  updateManagerDetailsMutation.mutate(
                    {
                      deleteCalendarLink: true,
                    },
                    {
                      onSuccess: () => {
                        setDeleteButtonLoading(false);
                        onSuccess();
                        reset({ link: '' });
                        closeModal(false);
                      },
                      onError: () => {
                        setDeleteButtonLoading(false);
                      },
                    }
                  );
                }}>
                Delete
              </Button> */}
              <div className="flex ">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => closeModal(false)}>
                  Close
                </Button>
                <span className="mx-2" />
                <Button
                  type="submit"
                  loading={updateManagerDetailsMutation.isLoading}
                  onClick={() => {
                    if (type === 'Title') {
                      updateProviderTitle();
                    } else {
                      onSubmit();
                    }
                  }}>
                  {type === 'CalendarLink' ? 'Add/Update' : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
