import { APIService } from 'services/api-service';

import { MessagingServiceNS } from './types';

export class MessagingService extends APIService {
  static UPLOAD_FILE = `${this.API_PREFIX_URL}/messaging/group`;

  static uploadFile = ({ media, groupId }: MessagingServiceNS.UploadFile) => {
    const formData = new FormData();
    formData.append('media', media);

    return this.post<MessagingServiceNS.UploadFileResponse>(
      `${MessagingService.UPLOAD_FILE}/${groupId}/media-draft`,
      formData,
      {
        withAuthToken: true,
        formData: true,
      }
    );
  };
}
