export const adjustDoseSkeleton = [
  {
    className: 'grid gap-4 ',
    children: [
      {
        className:
          'grid grid-cols-[12] items-center gap-x-10 gap-4 rounded-md bg-background-main p-6',
        children: [
          {
            className: 'flex flex-col gap-6',
            children: [
              { className: 'w-32 h-6 mt-2' },
              {
                className: ' flex  gap-20',
                children: [
                  { className: 'w-96 !h-88 ' },
                  {
                    className: 'flex flex-col gap-8',
                    children: [
                      { className: 'w-96 h-6 mt-6' },

                      {
                        className: ' flex gap-6 items-center  ',
                        children: [
                          {
                            className: 'grid gap-y-8',
                            children: new Array(3).fill(0).map(() => ({
                              className: 'h-4 w-[250px] ',
                            })),
                          },
                          { className: 'w-1 h-32 ' },
                          {
                            className: 'grid gap-y-8',
                            children: new Array(3).fill(0).map(() => ({
                              className: 'h-4 w-[250px] ',
                            })),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            className: 'mt-4 grid row-start-2 gap-6',
            children: [
              {
                className: 'flex-col gap-4',
                children: [{ className: 'h-1 w-full' }],
              },
              {
                className: 'flex justify-between',
                children: new Array(6).fill(0).map(() => ({
                  className: 'flex flex-col gap-2 ',
                  children: [
                    { className: 'h-4 w-36' },
                    { className: 'h-4 w-36' },
                  ],
                })),
              },
              {
                className: 'flex-col gap-4',
                children: [{ className: 'h-1 w-full' }],
              },
            ],
          },

          {
            className: 'mt-4 grid  row-start-4 gap-4 col-span-1 w-full',
            children: new Array(1)
              .fill(0)
              .map(() => ({ className: 'h-96 w-full ' })),
          },
        ],
      },
    ],
  },
];
